import React from 'react';
import { useIntl } from 'react-intl';

import { Content } from './styled';
import {
  IssueChangeEvent,
  IssueChangeEventField,
} from '@domain/Issues/models/IssueEvent';
import { Badge } from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvent/IssueDetailsEventData/IssueDetailsOpenedEvent/styled';

import PriorityLabel from '@components/shared/PriorityLabel/PriorityLabel';
import ZonedDateTime from '@components/shared/ZonedDateTime';

interface IProps {
  event: IssueChangeEvent;
}

const IssueDetailsChangedEvent: React.FC<React.PropsWithChildren<IProps>> = ({
  event,
}) => {
  const { formatMessage } = useIntl();

  let content = null;

  switch (event.data.fieldType) {
    case IssueChangeEventField.Assignees:
      content = (
        <>
          <strong>{formatMessage({ id: 'AssignedTo' })}:</strong>{' '}
          {event.data.value.assignedUsers.length > 0
            ? event.data.value.assignedUsers.map(({ name }) => name).join(', ')
            : formatMessage({ id: 'Unassigned' })}
        </>
      );
      break;
    case IssueChangeEventField.Priority:
      content = (
        <>
          <strong>{formatMessage({ id: 'Priority' })}:</strong>{' '}
          <PriorityLabel priority={event.data.value.priority} />
        </>
      );
      break;
    case IssueChangeEventField.DueDate:
      content = (
        <>
          <strong>{formatMessage({ id: 'DueDate' })}:</strong>{' '}
          <ZonedDateTime dateTime={event.data.value.dueDate} />
        </>
      );
      break;
  }

  if (!content) {
    return null;
  }

  return (
    <>
      <Badge>{formatMessage({ id: 'IssueHasBeenChanged' })}</Badge>
      <Content>{content}</Content>
    </>
  );
};

export default IssueDetailsChangedEvent;
