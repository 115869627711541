import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { routes } from '@config';
import { config, colors } from '@repo/shared/config';
import {
  Detail,
  Status,
  Subtitle,
  CompanyDetails,
  Card,
  Button,
} from './styled';
import { accountSelectors } from '@store';
import { date } from '@utils';
import { SubscriptionStatus } from '@repo/shared/enums';

import TwoColsWithImage from '../TwoColsWithImage/TwoColsWithImage';

const AutoSubscriptionInfo: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { data: subscription } = useSelector(accountSelectors.getSubscription);
  const { couponDiscount } = useSelector(accountSelectors.getTotalCost);
  const company = useSelector(accountSelectors.getCompany);

  if (!subscription?.automatedSubscriptionData) {
    return null;
  }

  let {
    nextPaymentDate,
    usersQuantity,
    usersPrice,
    observersPrice,
    observersQuantity,
    coupon,
    creditCard,
    customer,
  } = subscription.automatedSubscriptionData;

  const isBlocked = company?.subscriptionStatus === SubscriptionStatus.Blocked;

  return (
    <TwoColsWithImage
      title={
        <>
          {formatMessage({ id: 'ProfessionalPlanIs' })}{' '}
          <Status style={{ color: isBlocked ? colors.red : colors.green }}>
            {formatMessage({
              id: isBlocked ? 'expired' : 'active',
            })}
          </Status>
        </>
      }
      imageColor={isBlocked ? colors.red : colors.green}
      leftColContent={
        <>
          {!isBlocked && (
            <Detail>
              <span>{formatMessage({ id: 'YourNextPaymentIsOn' })}</span>
              <strong>
                {date(nextPaymentDate).format(config.dateFormat)} (
                {formatMessage(
                  { id: 'InXDays' },
                  {
                    count: date(nextPaymentDate).diff(date(), 'days'),
                  }
                )}
                )
              </strong>
            </Detail>
          )}
          <Detail>
            <div>
              {formatMessage({ id: 'Professional' })}
              {observersQuantity === 0 ? (
                <>
                  ,{' '}
                  {formatMessage(
                    { id: 'XActiveUsers' },
                    { count: usersQuantity }
                  )}
                </>
              ) : (
                <>
                  ,{' '}
                  <span style={{ fontStyle: 'italic' }}>
                    {formatMessage({ id: 'XUsers' }, { count: usersQuantity })}{' '}
                    ($
                    {usersPrice}) +{' '}
                    {formatMessage(
                      { id: 'XObservers' },
                      { count: observersQuantity }
                    )}{' '}
                    ($
                    {observersPrice})
                  </span>
                </>
              )}
            </div>
            <strong>${usersPrice + observersPrice}</strong>
          </Detail>
          {coupon && (
            <Detail>
              <span>{formatMessage({ id: 'PromoCode' })}</span>
              <strong>
                {coupon.code} (-${couponDiscount})
              </strong>
            </Detail>
          )}
          <Subtitle>
            {formatMessage({ id: 'CompanyAndPaymentMethod' })}
          </Subtitle>
          <CompanyDetails>
            <Card>•••• •••• •••• {creditCard.lastFourDigits}</Card>
            <div>{customer.organization}</div>
            <div>{customer.email}</div>
            <div>
              {customer.zip}, {customer.country}, {customer.state},{' '}
              {customer.city}, {customer.address}
            </div>
          </CompanyDetails>
          {isBlocked && (
            <Button
              type="link"
              htmlType="button"
              onClick={() => navigate(routes.payment)}
            >
              {formatMessage({ id: 'EditSubscriptionAndBillingInfo' })}
            </Button>
          )}
        </>
      }
    />
  );
};

export default AutoSubscriptionInfo;
