import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '@domain/Actions/models/Action';
import { ActionStatus, Permission } from '@repo/shared/enums';
import { date, getColumnSortProperties } from '@utils';
import { actionsActions, actionsSelectors, generalActions } from '@store';
import { usePermission, useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';

import DueDateTableCell from '@components/shared/DateTableCellWithDueLabel/DueDateTableCell';
import CompleteDateTableCell from '@components/shared/DateTableCellWithDueLabel/CompleteDateTableCell';
import Table from '@components/shared/ant/Table/Table';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import ActionsThreeDotMenu from './ActionsThreeDotMenu';
import ActionStatusName from '@src/presentation/Actions/ActionStatusName';
import ConciseListStr from '@components/shared/ConciseListStr/ConciseListStr';
import SampleBadgedName from '@components/shared/SampleBadgedName';
import ExpiredDateWithBadge from '@presentation/Audits/ExpiredDateWithBadge/ExpiredDateWithBadge';
import ZonedDateTime from '@components/shared/ZonedDateTime';

interface IProps {
  page: ActionsPage;
}

const ActionsTable: React.FC<React.PropsWithChildren<IProps>> = ({ page }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, meta, data, error } = useSelector(
    actionsSelectors.getActions
  );
  const filters = useSelector(actionsSelectors.getFilters);
  const [canManageCorrectiveActions] = usePermission([
    Permission.CanManageCorrectiveActions,
  ]);

  return (
    <Table<Action>
      onPageChange={(update) => {
        dispatch(actionsActions.getActions(update));
      }}
      onUnmount={() => {
        dispatch(actionsActions.resetActionsData());
      }}
      canSelectRow={canManageCorrectiveActions}
      onSelectRow={() => {
        dispatch(actionsActions.toggleFiltersModal(false));
      }}
      e2eDataAttr={e2eTestElements.actions.table}
      loading={loading}
      meta={meta}
      error={error}
      columns={[
        {
          title: formatMessage({ id: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
          render: (_: unknown, action) => {
            return (
              <SampleBadgedName
                text={
                  action.number
                    ? `${action.number} - ${action.name}`
                    : action.name
                }
                isSample={action.isSample}
              />
            );
          },
          className: 'cell-text-link',
        },
        {
          title: formatMessage({ id: 'Audit' }),
          dataIndex: 'auditName',
          key: 'auditName',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'auditName'
          ),
          render(_: unknown, { audit, auditObject }) {
            const auditNameParts = [];

            if (audit?.number) {
              auditNameParts.push(audit.number);
            }

            if (auditObject?.name) {
              auditNameParts.push(auditObject.name);
            }

            if (audit?.name) {
              auditNameParts.push(audit.name);
            }

            return auditNameParts.join(' - ');
          },
        },
        {
          title: formatMessage({ id: 'AssignedTo' }),
          dataIndex: 'assignedTo',
          key: 'assignedTo',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'assignedTo'
          ),
          render(_, action) {
            return (
              <ConciseListStr
                items={action.assignedUsers}
                showExclamationPlaceholder
              />
            );
          },
        },
        ...(page === ActionsPage.Completed
          ? [
              {
                title: formatMessage({ id: 'ApprovedBy' }),
                dataIndex: 'approvedBy',
                key: 'approvedBy',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'approvedBy'
                ),
                render(_: unknown, action: Action) {
                  return action.approvedBy?.name || '';
                },
              },
            ]
          : []),
        {
          title: formatMessage({ id: 'CreatedBy' }),
          dataIndex: 'createdBy',
          key: 'createdBy',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'createdBy'
          ),
          render(_, action) {
            return action.createdBy.name;
          },
        },
        ...(page === ActionsPage.Pending
          ? [
              {
                title: formatMessage({ id: 'Status' }),
                dataIndex: 'status',
                key: 'status',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'status'
                ),
                render(_: unknown, action: Action) {
                  return <ActionStatusName status={action.status} />;
                },
              },
              {
                title: formatMessage({ id: 'DueDate' }),
                dataIndex: 'dueDate',
                key: 'dueDate',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'dueDate'
                ),
                render(_: unknown, action: Action) {
                  if (!action.dueDateInformation) {
                    return null;
                  }

                  return (
                    <DueDateTableCell
                      dueDate={action.dueDateInformation.localTime}
                      timeZoneAbbreviation={
                        action.dueDateInformation.timeZoneAbbreviation
                      }
                      showDueLabel={action.status !== ActionStatus.Approved}
                      dueInDays={action.dueInDays}
                    />
                  );
                },
              },
            ]
          : []),
        ...(page === ActionsPage.Completed
          ? [
              {
                title: formatMessage({ id: 'Completed' }),
                dataIndex: 'completedAt',
                key: 'completedAt',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'completedAt'
                ),
                render(_: unknown, action: Action) {
                  if (!action.completedAtInformation) {
                    return null;
                  }

                  return (
                    <CompleteDateTableCell
                      completeDate={action.completedAtInformation.localTime}
                      timeZoneAbbreviation={
                        action.completedAtInformation.timeZoneAbbreviation
                      }
                      showLabel={
                        date()
                          .startOf('day')
                          .diff(
                            action.completedAtInformation.localTime,
                            'day'
                          ) < 0
                      }
                      labelText={formatMessage({ id: 'PastDue' })}
                    />
                  );
                },
              },
            ]
          : []),
        ...(page === ActionsPage.Expired
          ? [
              {
                title: formatMessage({ id: 'ExpiredDate' }),
                dataIndex: 'expiredAt',
                key: 'expiredAt',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'expiredAt'
                ),
                render(_: unknown, action: Action) {
                  if (!action.expiredAtInformation) {
                    return null;
                  }

                  return (
                    <ExpiredDateWithBadge
                      date={
                        <ZonedDateTime dateTime={action.expiredAtInformation} />
                      }
                      isAutoExpired={action.expiredBy === null}
                      onlyAutoExpired
                    />
                  );
                },
              },
            ]
          : []),
        ...(canManageCorrectiveActions && page !== ActionsPage.Expired
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center' as 'center',
                width: 70,
                render: (_: unknown, action: Action) => (
                  <ActionsThreeDotMenu action={action} context="table" />
                ),
              },
            ]
          : []),
      ]}
      onRow={({ id }) => ({
        onClick: async (e) => {
          e.stopPropagation();
          e.preventDefault();

          dispatch(actionsActions.toggleFiltersModal(false));
          dispatch(generalActions.selectTableRows([]));
          dispatch(actionsActions.toggleActionDetailsModal(id));
        },
      })}
      dataSource={data.map((action: Action) => ({
        key: action.id,
        ...action,
      }))}
      locale={{
        emptyText: (
          <EmptyTable
            text={
              filters.search !== ''
                ? formatMessage(
                    { id: 'NoCorrectiveActionsSearchResults' },
                    { searchStr: filters.search, linebreak: <br /> }
                  )
                : formatMessage(
                    { id: 'NoCorrectiveActions' },
                    { linebreak: <br /> }
                  )
            }
            howItWorksUrl={config.urls.actionsSupport}
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(actionsActions.getActions({ orderBy, orderByDirection }))
      }
    />
  );
};

export default ActionsTable;
