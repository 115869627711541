import React from 'react';
import Layout from 'antd/es/layout';

import { Container, Content, Main } from './styled';
import { useAppDispatch } from '@trends/shared/store';
import { accountActions } from '@store';

import Sidebar from '@components/shared/Sidebar/Sidebar';
import Header from '@components/shared/Header/Header';
import PayWall from '@components/account/Billing/PayWall/PayWall';
import WelcomeModal from '@components/account/WelcomeModal/WelcomeModal';
import DownloadAppsModal from '@components/account/DownloadAppsModal/DownloadAppsModal';
import SampleDataModal from '@components/account/SampleDataModal/SampleDataModal';
import StartSampleAuditModal from '@presentation/Audits/StartSampleAuditModal';
import SelfGuidedTour from '@components/account/SelfGuidedTour/SelfGuidedTour';
import FormsDigitizationModal from '@components/shared/DigitizeFormsModal/FormsDigitizationModal';
import ErrorBoundary from '@components/shared/ErrorBoundary/ErrorBoundary';
import NewVersionReleasedModal from '@components/account/NewVersionReleasedModal';

const AppLayout: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  return (
    <ErrorBoundary
      onChunkError={() => {
        dispatch(accountActions.toggleNewVersionReleasedModal(true));
      }}
    >
      <Container>
        <Layout>
          <Sidebar />
          <Content>
            <Header />
            <Main>
              <ErrorBoundary
                onChunkError={() => {
                  dispatch(accountActions.toggleNewVersionReleasedModal(true));
                }}
              >
                {children}
              </ErrorBoundary>
            </Main>
            <PayWall />
            <WelcomeModal />
            <DownloadAppsModal />
            <SampleDataModal />
            <StartSampleAuditModal />
            <SelfGuidedTour />
            <FormsDigitizationModal />
            <NewVersionReleasedModal />
          </Content>
        </Layout>
      </Container>
    </ErrorBoundary>
  );
};

export default AppLayout;
