import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { IssueOpenedEvent } from '@domain/Issues/models/IssueEvent';
import { Badge, ContactInformation } from './styled';
import { issuesSelectors } from '@application/Issues/store';
import { contactFieldTypesLangIds } from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetailsContactFormFields';

import IssueDetailsEventCommentText from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvent/IssueDetailsEventData/IssueDetailsEventCommentText/IssueDetailsEventCommentText';

interface IProps {
  event: IssueOpenedEvent;
}

const IssueDetailsOpenedEvent: React.FC<React.PropsWithChildren<IProps>> = ({
  event,
}) => {
  const { formatMessage } = useIntl();

  const contactInformationFields = useSelector(
    issuesSelectors.getIssueContactInformationFields
  );

  const isReopened = !!event.data?.isReopen;

  return (
    <>
      {!isReopened && contactInformationFields.length > 0 && (
        <ContactInformation>
          {contactInformationFields.map((field) => (
            <div key={field.id}>
              <strong>{contactFieldTypesLangIds[field.type]}</strong>:{' '}
              {field.value}
            </div>
          ))}
        </ContactInformation>
      )}
      <Badge>
        {formatMessage({
          id: isReopened ? 'IssueHasBeenReopened' : 'IssueHasBeenCreated',
        })}
      </Badge>
      {!!event.data?.comment && (
        <IssueDetailsEventCommentText text={event.data.comment} />
      )}
    </>
  );
};

export default IssueDetailsOpenedEvent;
