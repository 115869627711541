import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import {
  usersActions,
  usersSelectors,
  generalSelectors,
  generalActions,
  accountSelectors,
} from '@store';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { colors } from '@repo/shared/config';

import Modal from '../../../shared/ant/Modal/Modal';

interface IProps {
  userId?: string;
  auditObjectId?: string;
}

const ConfirmDeleteUsersAccessModal: React.FC<
  React.PropsWithChildren<IProps>
> = ({ userId, auditObjectId }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const deleteCandidatesIds = useSelector(
    usersSelectors.access.getAccessIdsDeleteCandidates
  );
  const accessMap = useSelector(usersSelectors.access.getMap);
  const auditObjectsMap = useSelector(
    generalSelectors.getConciseAuditObjectsMap
  );
  const usersMap = useSelector(generalSelectors.getConciseUsersMap);
  const access =
    deleteCandidatesIds.length === 1 ? accessMap[deleteCandidatesIds[0]] : null;

  const closeModal = useCallback(
    () => dispatch(usersActions.access.toggleConfirmDeleteModal([])),
    []
  );

  return (
    <Modal
      title={formatMessage({ id: 'RevokeAccess' })}
      open={deleteCandidatesIds.length > 0}
      onOk={async () => {
        setLoading(true);

        const resultAction = await dispatch(
          usersActions.access.deleteAccess(deleteCandidatesIds)
        );

        if (usersActions.access.deleteAccess.fulfilled.match(resultAction)) {
          dispatch(usersActions.access.getData());

          if (selectedTableRows.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileRevokingAccess' }),
          });
        }

        closeModal();
        setLoading(false);
      }}
      okText={formatMessage({ id: 'Revoke' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {!!userId
        ? formatMessage(
            { id: 'YouAreAboutToRevokeAccessFromSystemUser' },
            {
              userName: usersMap[userId]?.name,
              count: deleteCandidatesIds.length,
              auditObjectName: access
                ? auditObjectsMap[access.auditObjectId]?.name
                : `${
                    deleteCandidatesIds.length
                  } ${auditObjectName.plural.toLowerCase()}`,
            }
          )
        : formatMessage(
            { id: 'YouAreAboutToRevokeAccessFromSystemObject' },
            {
              userName: access
                ? (access.userId && usersMap[access.userId]?.name) ||
                  (access.userGroupId && usersMap[access.userGroupId]?.name)
                : deleteCandidatesIds.length,
              userCount: deleteCandidatesIds.length,
              auditObjectName:
                (!!auditObjectId && auditObjectsMap[auditObjectId]?.name) || '',
            }
          )}
    </Modal>
  );
};

export default ConfirmDeleteUsersAccessModal;
