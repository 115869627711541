import { createSelector, EntityState } from '@reduxjs/toolkit';

import { IRootState } from '../../../core/frameworks/redux';
import { AssignmentType } from '@repo/shared/enums';
import { Action, ActionDetails } from '@domain/Actions/models/Action';
import { ActionDueDateStatus } from '@application/Actions/enums/ActionDueDateStatus';
import { actionsAdapter } from '@application/Actions/store/entityAdapters';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { ActionsFilters } from '@application/Actions/models/ActionsFilters';
import { IPagingMeta } from '@repo/shared/types';
import { AssignActionsToUsersModalState } from '@application/Actions/models/AssignActionsToUsersModalState';
import { ChangeStatusModalState } from '@application/Actions/models/ChangeStatusModalState';
import { DeleteActionCommentModalState } from '@application/Actions/models/DeleteActionCommentModalState';
import { ActionsState } from '@application/Actions/models/ActionsState';

const getActionsState = (state: IRootState): ActionsState => state.actions;

const getPage = (state: IRootState): ActionsPage =>
  state.actions.actionsList.page;

const getActions = createSelector(
  [
    (state: IRootState): boolean => state.actions.actionsList.loading,
    (state: IRootState): IPagingMeta | null => state.actions.actionsList.meta,
    (state: IRootState): string | null => state.actions.actionsList.error,
    (state: IRootState): EntityState<Action, string> =>
      state.actions.actionsList.data,
  ],
  (loading, meta, error, data) => ({
    loading,
    error,
    meta,
    data: actionsAdapter.getSelectors().selectAll(data),
  })
);

const getActionsDictionary = (state: IRootState): Record<string, Action> =>
  actionsAdapter.getSelectors().selectEntities(state.actions.actionsList.data);

const getFilters = createSelector(
  [
    getPage,
    (state: IRootState): Record<ActionsPage, ActionsFilters> =>
      state.actions.actionsList.filters,
  ],
  (page, filters) => {
    return filters[page]
  }
);

const getSelectedFiltersQty = createSelector(getFilters, (filters) => {
  let selected = 0;

  if (filters.createdBy !== null) {
    selected += 1;
  }

  if (filters.templateId !== null) {
    selected += 1;
  }

  if (filters.priority !== null) {
    selected += 1;
  }

  if (filters.startDate !== null && filters.endDate !== null) {
    selected += 1;
  }

  if (filters.assignment !== AssignmentType.all) {
    selected += 1;
  }

  if (filters.dueDateStatus !== ActionDueDateStatus.All) {
    selected += 1;
  }

  if (
    filters.auditObjectIds.length > 0 ||
    filters.auditObjectGroupIds.length > 0
  ) {
    selected += 1;
  }

  if (filters.completeAuditDate !== null) {
    selected += 1;
  }

  if (filters.auditId !== null) {
    selected += 1;
  }

  if (filters.tagsIds.length > 0) {
    selected += 1;
  }

  return selected;
});

const getChangeStatusModalState = (state: IRootState): ChangeStatusModalState =>
  state.actions.changeStatusModal;

const isFiltersModalVisible = (state: IRootState): boolean =>
  state.actions.actionsList.showFiltersModal;

const getAssignUsersToActionsModalState = (
  state: IRootState
): AssignActionsToUsersModalState => state.actions.assignUsersToActionsModal;

const getActionDetailsAction = (state: IRootState): ActionDetails | null =>
  state.actions.actionDetails.data;

const getActionDetailsLoading = (state: IRootState): boolean =>
  state.actions.actionDetails.loading;

const getActionDetailsError = (state: IRootState): string | null =>
  state.actions.actionDetails.error;

const getEditingCommentId = (state: IRootState): string | null =>
  state.actions.actionDetails.editingCommentId;

const getActionDetailsComments = createSelector(
  getActionDetailsAction,
  (action) => action?.comments || []
);

const getEditingComment = createSelector(
  [getActionDetailsAction, getEditingCommentId],
  (action, editingCommentId) => {
    if (!action || !editingCommentId) {
      return null;
    }

    return action.comments.find(({ id }) => editingCommentId === id) || null;
  }
);

const getActionDetails = createSelector(
  [getActionDetailsAction, getActionDetailsLoading, getActionDetailsError],
  (data, loading, error) => ({ data, loading, error })
);

const getViewModalActionId = (state: IRootState): string | null =>
  state.actions.viewModalActionId;

const getDeleteActionCommentState = (
  state: IRootState
): DeleteActionCommentModalState =>
  state.actions.actionDetails.deleteActionCommentModal;

const getAllAuditsActions = createSelector(
  getActionsState,
  ({ auditActions }) => ({
    ...auditActions,
    data: actionsAdapter.getSelectors().selectAll(auditActions.data),
  })
);

const getActionDetailsId = (state: IRootState): string | null =>
  state.actions.actionDetails.id;

export const actionsSelectors = {
  getPage,
  getActions,
  getActionsDictionary,
  getFilters,
  getSelectedFiltersQty,
  getChangeStatusModalState,
  isFiltersModalVisible,
  getEditingComment,
  getActionDetailsComments,
  getAssignUsersToActionsModalState,
  getActionDetailsAction,
  getActionDetailsLoading,
  getActionDetailsError,
  getActionDetails,
  getViewModalActionId,
  getDeleteActionCommentState,
  getAllAuditsActions,
  getActionDetailsId,
};
