import React from 'react';
import { MoreHorizontal } from 'react-feather';
import { useIntl } from 'react-intl';

import { HeaderActionButton } from './styled';
import { useAppDispatch } from '@hooks';
import { issueTypesActions } from '@application/IssueTypes/store';
import { IssueTypeDetails } from '@domain/IssueTypes/models/IssueType';

import DropDownMenu from '@components/shared/DropDownMenu/DropDownMenu';

interface IProps {
  issueType: IssueTypeDetails;
}

const HeaderActionMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  issueType,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  return (
    <DropDownMenu
      menuItems={[
        {
          name: formatMessage({
            id: 'Delete',
          }),
          onClick: () => {
            dispatch(
              issueTypesActions.toggleConfirmDeleteIssueTypesModal({
                multiple: false,
                data: issueType,
              })
            );
          },
        },
      ]}
    >
      <HeaderActionButton type="button">
        <MoreHorizontal />
      </HeaderActionButton>
    </DropDownMenu>
  );
};

export default HeaderActionMenu;
