import { useEffect, useState } from 'react';

import { Logger } from '@repo/shared/services';
import { Locale } from '@repo/shared/enums';
import { delay } from '@repo/shared/utils';

export function useTranslations(
  locale: Locale = Locale.en
): [boolean, Record<string, string>, Locale] {
  const [loading, setLoading] = useState(true);
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    setLoading(true);

    async function loadTranslations() {
      const locales = [import(`../translations/${locale.toLowerCase()}.json`)];

      if (locale !== Locale.en) {
        locales.push(import(`../translations/en.json`));
      }

      try {
        const result = await Promise.all(locales);
        const translations = result[0].default;
        const fallbackTranslations = result[1]?.default;

        if (fallbackTranslations) {
          Object.keys(fallbackTranslations).forEach((key) => {
            if (!(key in translations)) {
              translations[key] = fallbackTranslations[key];
            }
          });
        }

        await delay(100);

        setTranslations(translations);
        setLoading(false);
      } catch (e) {
        Logger.captureException(e);
        setLoading(false);
      }
    }

    loadTranslations();
  }, [locale]);

  return [loading, translations, locale];
}
