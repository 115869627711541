import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin';

import { Container, Disclaimer, GroupName, Loader, NoAudits } from './styled';
import { useAppDispatch } from '@hooks';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { MyAuditsGroup } from '@application/Audits/enums/MyAuditsGroup';
import { config } from '@repo/shared/config';

import Scrollbar from '@components/shared/Scrollbar/Scrollbar';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import MyAudit from '@presentation/Audits/MyAuditsList/MyAudit/MyAudit';

const groupNamesLangIds = {
  [MyAuditsGroup.Past]: 'Past',
  [MyAuditsGroup.Today]: 'Today',
  [MyAuditsGroup.Monday]: 'Monday',
  [MyAuditsGroup.Tuesday]: 'Tuesday',
  [MyAuditsGroup.Wednesday]: 'Wednesday',
  [MyAuditsGroup.Thursday]: 'Thursday',
  [MyAuditsGroup.Friday]: 'Friday',
  [MyAuditsGroup.Saturday]: 'Saturday',
  [MyAuditsGroup.Sunday]: 'Sunday',
  [MyAuditsGroup.Upcoming]: 'Upcoming',
};

interface IProps {}

const MyAuditsList: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, audits, meta, error } = useSelector(
    auditsSelectors.getMyAudits
  );

  useEffect(() => {
    return () => {
      dispatch(auditsActions.resetListData());
    };
  }, []);

  return (
    <Container>
      <Disclaimer>
        {formatMessage({
          id: 'InspectionsScheduledForYouWithinTheNext14DaysWillBeDisplayedHere',
        })}
      </Disclaimer>
      {audits.list.length === 0 && loading ? (
        <Skeleton active style={{ width: '400px', marginTop: '40px' }} />
      ) : (
        <>
          {audits.list.length === 0 ? (
            <NoAudits>
              <EmptyTable
                text={formatMessage({
                  id: 'CurrentlyYouHaveNoAssignedAudits',
                })}
                howItWorksUrl={config.urls.auditsSupport}
              />
            </NoAudits>
          ) : (
            <>
              {error ? (
                error
              ) : (
                <Scrollbar
                  onScrollStop={(event) => {
                    const { scrollTop, clientHeight, scrollHeight } = event;

                    if (
                      scrollTop + clientHeight >= scrollHeight &&
                      meta &&
                      meta.totalCount > meta.pageNumber * meta.pageSize &&
                      !loading
                    ) {
                      dispatch(
                        auditsActions.getAudits({
                          pageNumber: meta.pageNumber + 1,
                        })
                      );
                    }
                  }}
                >
                  {audits.list.map((group) => {
                    return (
                      <React.Fragment key={group}>
                        <GroupName>
                          {formatMessage({ id: groupNamesLangIds[group] })}
                        </GroupName>
                        {audits.dictionary[group].map((audit) => (
                          <MyAudit key={audit.id} audit={audit} />
                        ))}
                      </React.Fragment>
                    );
                  })}
                  {loading && (
                    <Loader>
                      <Spin />
                    </Loader>
                  )}
                </Scrollbar>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default MyAuditsList;
