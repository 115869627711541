import React, { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import mime from 'mime';

import { Container, Input, Zone, Desc, Icon, Header } from './styled';
import { config } from '@repo/shared/config';
import { dateUTC } from '@utils';
import { IFile } from '@repo/shared/types';
import { MimeType } from '@repo/shared/enums';

interface IProps {
  e2eDataAttr?: string;
  onChange: (files: IFile[]) => void;
  allowedMimeTypes?: MimeType[];
  uploadLimit?: number;
  hasError?: boolean;
  disabled?: boolean;
}

const UploadZone: React.FC<React.PropsWithChildren<IProps>> = ({
  e2eDataAttr,
  onChange,
  allowedMimeTypes,
  uploadLimit,
  hasError,
  disabled,
}) => {
  const { formatMessage } = useIntl();

  const onDrop = useCallback((rawFiles: File[]) => {
    const files = rawFiles.map((file) => ({
      id: uuid(),
      file,
      contentType: file.type,
      name: file.name,
      size: file.size,
      updatedAt: dateUTC().format(config.apiDateFormat),
    }));

    onChange(files);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    disabled,
  });

  return (
    <Container {...getRootProps()} data-e2e={e2eDataAttr}>
      <Input {...getInputProps()} />
      <Zone isDragActive={isDragActive} hasError={hasError}>
        <Icon />
        <Header>
          {formatMessage({ id: 'ClickOrDragFileToThisAreaToUpload' })}
        </Header>
        <Desc>
          {formatMessage(
            { id: 'MaximumFileSize' },
            {
              fileSize: config.maxUploadFileSizeMb,
            }
          )}
          {Array.isArray(allowedMimeTypes) && (
            <>
              {' '}
              {formatMessage(
                { id: 'SupportedFileTypes' },
                {
                  fileTypes: allowedMimeTypes
                    .map((mimeType) => mime.getExtension(mimeType))
                    .join(', ')
                    .toUpperCase(),
                }
              )}
            </>
          )}
          {!!uploadLimit && uploadLimit > 1 && (
            <>
              <br />
              {formatMessage(
                {
                  id: 'UploadLimit',
                },
                {
                  count: uploadLimit,
                }
              )}
            </>
          )}
        </Desc>
      </Zone>
    </Container>
  );
};

export default UploadZone;
