import React, { useEffect } from 'react';
import AntSelect, { SelectProps } from 'antd/es/select';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { accountSelectors, rolesActions } from '@store';
import { SubscriptionType } from '@repo/shared/enums';
import { useUserRolesLimitsInfo, filterOptionsByChildren } from '@utils';
import { useAppDispatch } from '@hooks';

import Select from '../../../shared/ant/Select/Select';
import EntitySelect from '../../../shared/EntitySelects/EntitySelect';

const { Option, OptGroup } = AntSelect;

interface IProps extends SelectProps<any> {
  e2eDataAttr?: string;
}

const RolesSelect: React.FC<React.PropsWithChildren<IProps>> = React.forwardRef(
  ({ disabled, placeholder, ...props }, _) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();

    useEffect(() => {
      dispatch(rolesActions.getRoles());
    }, []);

    const company = useSelector(accountSelectors.getCompany);
    const {
      loading,
      otherRoles,
      observerRoles,
      otherRolesCount,
      observersCount,
      otherRolesLimit,
      observersLimit,
    } = useUserRolesLimitsInfo();

    if (company?.subscriptionType === SubscriptionType.Custom) {
      return (
        <Select
          {...props}
          disabled={loading || disabled}
          placeholder={
            loading ? `${formatMessage({ id: 'loading' })}...` : placeholder
          }
          loading={loading}
          showSearch
          optionFilterProp="children"
          filterOption={filterOptionsByChildren}
        >
          {otherRoles.length > 0 && (
            <OptGroup label={`${otherRolesCount}/${otherRolesLimit || '-'}`}>
              {otherRoles.map((entity: any) => (
                <Option
                  value={entity.id}
                  key={entity.id}
                  // disabled={
                  //   otherRolesLimit ? otherRolesCount >= otherRolesLimit : false
                  // }
                >
                  {entity.name}
                </Option>
              ))}
            </OptGroup>
          )}
          {observerRoles.length > 0 && (
            <OptGroup label={`${observersCount}/${observersLimit || '-'}`}>
              {observerRoles.map((option: any, i) => (
                <Option
                  value={option.id}
                  key={`${option.id}-${i}`}
                  // disabled={
                  //   observersLimit ? observersCount >= observersLimit : false
                  // }
                >
                  {option.name}
                </Option>
              ))}
            </OptGroup>
          )}
        </Select>
      );
    }

    return (
      <EntitySelect
        {...props}
        loading={loading}
        disabled={disabled}
        placeholder={placeholder}
        options={[...otherRoles, ...observerRoles]}
      />
    );
  }
);

export default RolesSelect;
