import React, { useCallback, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { unwrapResult } from '@reduxjs/toolkit';

import { issuesSelectors, issuesActions } from '@application/Issues/store';
import { useAppDispatch, useOpacityTransition } from '@hooks';
import { CreateIssueDto } from '@infrastructure/Issues/models/CreateIssueDto';
import { IExposedFormRef } from '@application/Issues/hooks/useExposedForm';
import { notification } from '@utils';
import { Container, FormContainer } from './styled';
import { issueTypesActions } from '@application/IssueTypes/store';

import SlideModal from '@components/shared/SideModal/SideModal';
import { Footer } from '@presentation/Audits/CreateOneTimeAuditModal/styled';
import Button from '@components/shared/ant/Button';
import IssueQuestionsForm from '@src/presentation/Issues/IssueQuestionsForm/IssueQuestionsForm';
import IssueDetailsForm from '@src/presentation/Issues/CreateIssueModal/IssueBaseDataForm';

interface IProps {}

export function isCreateIssueFormValuesComplete(
  values: Partial<CreateIssueDto>
): values is CreateIssueDto {
  return (
    !!values.issueTypeId &&
    !!values.auditObjectId &&
    Array.isArray(values.questions)
  );
}

enum Step {
  ChooseIssueType,
  IssueTypeDetailsLoading,
  FillIssueTypeQuestions,
}

const CreateIssueModal: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const formRef = useRef<IExposedFormRef>(null);

  const [values, setValues] = useState<Partial<CreateIssueDto>>();
  const [processing, setProcessing] = useState(false);
  const [step, setStep] = useState<Step>(Step.ChooseIssueType);

  const transition = useOpacityTransition<Step>(step);

  const opened = useSelector(issuesSelectors.isCreateModalOpened);

  const closeModal = useCallback(() => {
    setValues(undefined);
    formRef?.current?.resetFields();
    dispatch(issuesActions.toggleCreateIssueModal(false));
    setStep(Step.ChooseIssueType);
  }, []);

  return (
    <SlideModal
      opened={opened}
      onModalClose={closeModal}
      title={formatMessage({ id: 'CreateIssue' })}
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              loading={processing}
              onClick={async () => {
                setProcessing(true);

                const update = await formRef?.current?.submitForm();

                if (update) {
                  const updatedValues = {
                    ...(values || {}),
                    ...update,
                  };

                  if (isCreateIssueFormValuesComplete(updatedValues)) {
                    const resultAction = await dispatch(
                      issuesActions.createIssue({
                        createIssueDto: updatedValues,
                      })
                    );

                    if (
                      issuesActions.createIssue.fulfilled.match(resultAction)
                    ) {
                      dispatch(issuesActions.getIssues());

                      const issueId = unwrapResult(resultAction);

                      dispatch(issuesActions.toggleIssueDetailsModal(issueId));

                      notification.success({
                        message: formatMessage({
                          id: 'IssueSuccessfullyCreated',
                        }),
                      });

                      closeModal();
                    } else {
                      notification.error({
                        message: formatMessage({
                          id: 'ErrorWhileCreatingIssue',
                        }),
                      });
                    }
                  } else {
                    setValues(updatedValues);

                    setStep(Step.IssueTypeDetailsLoading);

                    if (updatedValues?.issueTypeId) {
                      const resultAction = await dispatch(
                        issueTypesActions.getIssueTypeDetails({
                          issueTypeId: updatedValues.issueTypeId,
                        })
                      );

                      if (
                        issueTypesActions.getIssueTypeDetails.fulfilled.match(
                          resultAction
                        )
                      ) {
                        setStep(Step.FillIssueTypeQuestions);
                      } else {
                        setStep(Step.ChooseIssueType);

                        notification.error({
                          message: formatMessage({
                            id: 'ErrorWhileLoadingIssueDetails',
                          }),
                        });
                      }
                    }
                  }
                }

                setProcessing(false);
              }}
            >
              {formatMessage({
                id: !values?.issueTypeId ? 'Next' : 'Create',
              })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                closeModal();
              }}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
        </Footer>
      }
    >
      <Container>
        {transition.map(({ item, key, props }) => {
          if (item === Step.ChooseIssueType) {
            return (
              <FormContainer key={key} style={props}>
                <IssueDetailsForm ref={formRef} />
              </FormContainer>
            );
          }

          if (item === Step.FillIssueTypeQuestions) {
            return (
              <FormContainer key={key} style={props}>
                <IssueQuestionsForm ref={formRef} />
              </FormContainer>
            );
          }

          return (
            <FormContainer key={key} style={props}>
              <Skeleton active />
            </FormContainer>
          );
        })}
      </Container>
    </SlideModal>
  );
};

export default CreateIssueModal;
