import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { accountSelectors } from '@store';
import {
  useExposedForm,
  IExposedFormRef,
} from '@application/Issues/hooks/useExposedForm';

import Form from '@components/shared/ant/Form';
import IssueTypesSelect from '@components/shared/EntitySelects/IssueTypesSelect';
import AuditObjectsSelect from '@components/shared/EntitySelects/AuditObjectsSelect';
import { Input } from '@components/shared/ant/Input';

type FormValues = {
  name: string;
  issueTypeId: string;
  auditObjectId: string;
};

const ChooseIssueType = forwardRef<IExposedFormRef<FormValues>, unknown>(
  (_, ref) => {
    const { formatMessage } = useIntl();

    const form = useExposedForm<FormValues>(ref);

    const auditObjectName = useSelector(accountSelectors.getObjectName);

    return (
      <Form<FormValues> form={form} layout="vertical">
        <Form.Item
          name="name"
          label={formatMessage({ id: 'Name' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <Input placeholder={formatMessage({ id: 'EnterName' })} />
        </Form.Item>
        <Form.Item
          name="issueTypeId"
          label={formatMessage({ id: 'IssueType' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <IssueTypesSelect
            placeholder={formatMessage({ id: 'SelectIssueType' })}
          />
        </Form.Item>
        <Form.Item
          name="auditObjectId"
          label={auditObjectName.single}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <AuditObjectsSelect
            placeholder={formatMessage(
              { id: 'SelectAuditObject' },
              { objectName: auditObjectName.single.toLowerCase() }
            )}
          />
        </Form.Item>
      </Form>
    );
  }
);

export default ChooseIssueType;
