import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import { IssueDetailsAudit } from '@domain/Issues/models/Issue';
import { IExposedFormRef } from '@application/Issues/hooks/useExposedForm';
import { issuesSelectors } from '@application/Issues/store';
import { AssigneesModalContext, AuditStatus } from '@repo/shared/enums';
import { date } from '@utils';

import AddEditAuditModal, {
  AuditFormValues,
} from '@presentation/Audits/CreateOneTimeAuditModal/AddEditAuditModal';

const IssueDetailsCreateAuditModal: React.FC<
  React.PropsWithChildren<{
    opened: boolean;
    onCancel: () => void;
    onSave: (item: IssueDetailsAudit) => Promise<void>;
    onDelete: (itemId: string) => void;
    auditObjectId: string;
  }>
> = ({ opened, onDelete, onCancel, onSave }) => {
  const issue = useSelector(issuesSelectors.getDetailsIssue);

  const formRef = useRef<IExposedFormRef<AuditFormValues>>(null);

  return (
    <>
      {createPortal(
        <AddEditAuditModal
          ref={formRef}
          opened={opened}
          onCancel={onCancel}
          onSave={async ({ endDate, auditId, name }) => {
            if (auditId) {
              await onSave({
                id: auditId,
                name,
                number: null,
                status: AuditStatus.Pending,
                scoreColor: null,
                scoreLabel: null,
                dueInDays: date().diff(endDate, 'day'),
              });
            }
          }}
          onDelete={onDelete}
          auditObject={issue?.auditObject}
          assigneesContext={AssigneesModalContext.Issue}
        />,
        document.body
      )}
    </>
  );
};

export default IssueDetailsCreateAuditModal;
