import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { getColumnSortProperties } from '@utils';
import { accountSelectors } from '@store';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { issueTypeQrCodesSelectors } from '@application/IssueTypes/store/issueTypeQrCodesSelectors';
import { useAppDispatch } from '@hooks';
import { IssueTypeQrCode } from '@domain/IssueTypes/models/IssueTypeQrCode';
import { More, PopoverContent, ObjectsNamesContainer } from './styled';
import { config } from '@repo/shared/config';

import Table from '@components/shared/ant/Table/Table';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import IssueTypeQrCodesTableRowMenu from '@src/presentation/IssueTypes/IssueTypeQrCodes/IssueTypeQrCodesTable/IssueTypeQrCodesTableRowMenu';
import Popover from '@components/shared/ant/Popover';

interface IProps {
  auditObjectId?: string;
  auditObjectGroupId?: string;
  issueTypeId?: string;
}

const IssueTypeQrCodesTable: React.FC<React.PropsWithChildren<IProps>> = ({
  auditObjectId,
  auditObjectGroupId,
  issueTypeId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, meta, data, error } = useSelector(
    issueTypeQrCodesSelectors.getIssueQrCodes
  );
  const filters = useSelector(issueTypeQrCodesSelectors.getFilters);
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  return (
    <Table<IssueTypeQrCode>
      onPageChange={(update) => {
        dispatch(issueTypeQrCodesActions.getQrCodes(update));
      }}
      onUnmount={() => {
        dispatch(issueTypeQrCodesActions.resetQrCodesData());
      }}
      onSelectRow={() => {
        dispatch(issueTypeQrCodesActions.toggleFiltersModal(false));
      }}
      loading={loading}
      meta={meta}
      error={error}
      dataSource={data.map((issue) => ({
        key: issue.id,
        ...issue,
      }))}
      columns={[
        ...(!auditObjectId && !auditObjectGroupId
          ? [
              {
                title: `${auditObjectName.plural} ${formatMessage({
                  id: 'or',
                })} ${formatMessage({ id: 'Groups' })}`,
                dataIndex: 'auditObjects',
                key: 'auditObjects',
                render(_: any, qrCode: IssueTypeQrCode) {
                  if (qrCode.allowAnyAuditObject) {
                    return formatMessage({ id: 'All' });
                  }

                  const names = [
                    ...qrCode.auditObjects.map(({ name }) => name),
                    ...qrCode.auditObjectGroups.map(({ name }) => name),
                  ];

                  const visibleObjectsNumber = 3;

                  if (names.length <= visibleObjectsNumber) {
                    return names.join(', ');
                  }

                  return (
                    <ObjectsNamesContainer>
                      <div>
                        {names.slice(0, visibleObjectsNumber).join(', ')}
                      </div>
                      {names.length > visibleObjectsNumber && (
                        <Popover
                          content={
                            <PopoverContent>
                              {names
                                .slice(visibleObjectsNumber)
                                .map((name, i) => (
                                  <React.Fragment key={i}>
                                    {name}
                                    <br />
                                  </React.Fragment>
                                ))}
                            </PopoverContent>
                          }
                        >
                          <More>
                            + {names.length - visibleObjectsNumber}{' '}
                            {formatMessage({ id: 'More' })}
                          </More>
                        </Popover>
                      )}
                    </ObjectsNamesContainer>
                  );
                },
                className: 'cell-text-link',
              },
            ]
          : []),
        ...(!issueTypeId
          ? [
              {
                title: formatMessage({ id: 'IssueType' }),
                dataIndex: 'issueTypeName',
                key: 'issueTypeName',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'issueTypeName'
                ),
                render(_: any, qrCode: IssueTypeQrCode) {
                  return qrCode.issueType.name;
                },
              },
            ]
          : []),
        {
          title: formatMessage({ id: 'Description' }),
          dataIndex: 'description',
          key: 'description',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'description'
          ),
          render(_, qrCode) {
            return qrCode.description;
          },
        },
        {
          title: formatMessage({ id: 'Action' }),
          key: 'action',
          align: 'center' as 'center',
          width: 70,
          render: (_: any, qrCode: IssueTypeQrCode) => (
            <IssueTypeQrCodesTableRowMenu issueTypeQrCode={qrCode} />
          ),
        },
      ]}
      onRow={(qrCode) => ({
        onClick: (e) => {
          e.stopPropagation();
          e.preventDefault();
          dispatch(issueTypeQrCodesActions.toggleQrCodeModal(qrCode));
        },
      })}
      locale={{
        emptyText: (
          <EmptyTable
            text={
              filters.search !== ''
                ? formatMessage(
                    { id: 'NoIssueQrCodesSearchResults' },
                    { searchStr: filters.search, linebreak: <br /> }
                  )
                : formatMessage({ id: 'NoIssueQrCodes' }, { linebreak: <br /> })
            }
            button={{
              text: `+ ${formatMessage({ id: 'CreateQrCode' })}`,
              type: 'link',
              onClick: () =>
                dispatch(
                  issueTypeQrCodesActions.toggleAddEditModal({ show: true })
                ),
            }}
            howItWorksUrl={config.urls.qrCodesSupport}
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          issueTypeQrCodesActions.getQrCodes({
            orderBy,
            orderByDirection,
          })
        )
      }
    />
  );
};

export default IssueTypeQrCodesTable;
