export enum MyAuditsGroup {
  Past,
  Today,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
  Upcoming,
}
