import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { IssueTypeDetails } from '@domain/IssueTypes/models/IssueType';

import { CloseModalButton, Title } from './styled';
import { ArrowLeft } from '@icons';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';
import {
  issueTypesSelectors,
  issueTypesActions,
} from '@application/IssueTypes/store';
import { IssueTypePage } from '@application/IssueTypes/enums/IssueTypePage';

import PageHeader from '@components/shared/ant/PageHeader';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';

interface IProps {
  page: IssueTypePage;
  extra?: React.ReactNode;
  onIssueTypeDetailsFetch?: (
    issueTypeDetails: IssueTypeDetails
  ) => Promise<boolean>;
}

const IssueTypeContentWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
  children,
  onIssueTypeDetailsFetch,
  extra,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const { loading, data } = useSelector(
    issueTypesSelectors.getIssueTypeDetails
  );

  useEffect(() => {
    async function loadDetails(issueTypeId: string) {
      if (!data || data.id !== id) {
        const resultAction = await dispatch(
          issueTypesActions.getIssueTypeDetails({ issueTypeId })
        );

        if (
          issueTypesActions.getIssueTypeDetails.rejected.match(resultAction)
        ) {
          navigate(routes.issueTypes);
          return;
        }

        if (onIssueTypeDetailsFetch) {
          const success = await onIssueTypeDetailsFetch(
            unwrapResult(resultAction)
          );

          if (!success) {
            navigate(routes.issueTypes);
            dispatch(issueTypesActions.resetIssueType());
          }
        }
      }
    }

    if (id) {
      loadDetails(id);
    }
  }, [id]);

  return (
    <>
      <PageHeader
        title={
          <Space align="center">
            <CloseModalButton
              type="button"
              onClick={() => {
                navigate(routes.issueTypes);
                dispatch(issueTypesActions.resetIssueType());
              }}
            >
              <ArrowLeft />
            </CloseModalButton>
            <Title>
              {loading ? (
                <Skeleton.Input
                  active
                  size="small"
                  style={{ borderRadius: 5 }}
                />
              ) : (
                data?.name || formatMessage({ id: 'CreateIssueType' })
              )}
            </Title>
          </Space>
        }
        extra={extra}
        footer={
          <PageTabbedSwitcher<IssueTypePage>
            pages={[
              {
                onClick() {
                  if (data) {
                    navigate(routes.issueTypeDetails.replace(':id', data.id));
                  }
                },
                name: formatMessage({ id: 'Details' }),
                id: IssueTypePage.Details,
              },
              ...(data
                ? [
                    {
                      onClick() {
                        if (data) {
                          navigate(
                            routes.issueTypeQrCodes.replace(':id', data.id)
                          );
                        }
                      },
                      name: formatMessage({ id: 'QrCodes' }),
                      id: IssueTypePage.QrCodes,
                    },
                  ]
                : []),
            ]}
            activePage={page}
          />
        }
      />
      {children}
    </>
  );
};

export default IssueTypeContentWrapper;
