import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Detail, Value, Label, Divider, Total } from './styled';
import { accountSelectors } from '@store';
import { round } from '../../../../../core/utils/misc';

import Coupon from './Coupon/Coupon';

interface IProps {
  details: {
    label: string | React.ReactNode;
    value: string | React.ReactNode;
  }[];
  loading?: boolean;
  readOnly?: boolean;
}

const SubscriptionSummary: React.FC<React.PropsWithChildren<IProps>> = ({
  details,
  loading,
  readOnly,
}) => {
  const { formatMessage } = useIntl();

  const { totalCost, couponDiscount } = useSelector(
    accountSelectors.getTotalCost
  );

  return (
    <>
      {details.map(({ value, label }, i) => (
        <Detail key={i}>
          <Label>{label}</Label>
          <Value>{loading ? '—' : value}</Value>
        </Detail>
      ))}
      <Coupon loading={loading} readOnly={readOnly} />
      <Divider />
      <Total>
        <span>{formatMessage({ id: 'Total' })}</span>
        <span>
          {loading ? '—' : `$${round(totalCost - couponDiscount, 2)}`}
        </span>
      </Total>
    </>
  );
};

export default SubscriptionSummary;
