import React from 'react';

import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { useSetActionsPage } from '@application/Actions/hooks/useSetActionsPage';

import Actions from './Actions';

const PendingActions: React.FC<React.PropsWithChildren<unknown>> = () => {
  useSetActionsPage(ActionsPage.Pending);

  return <Actions page={ActionsPage.Pending} />;
};

export default PendingActions;
