import { createReducer, Reducer } from '@reduxjs/toolkit';

import { usersActions as actions } from './users.actions';
import { getInitialFilters } from '@utils';
import { IUsersFilters } from '@repo/shared/types';
import { IUsersState } from '@types';
import {
  jobTitlesAdapter,
  userAccessAdapter,
  userGroupsAssignmentsAdapter,
  usersAdapter,
  usersGroupAdapter,
} from '@store/entityAdapters';

export function getInitialUsersFilters(): IUsersFilters {
  return {
    ...getInitialFilters(),
    ungrouped: false,
    userGroupIds: [],
    userRoleIds: [],
  };
}

export const usersReducer: Reducer<IUsersState> = createReducer<IUsersState>(
  {
    list: {
      data: usersAdapter.getInitialState(),
      loading: false,
      meta: null,
      error: null,
      showFiltersModal: false,
      setUserPasswordModalUserId: null,
      filters: {
        ...getInitialUsersFilters(),
      },
    },
    user: {
      data: null,
      loading: false,
      error: null,
    },
    groups: {
      data: usersGroupAdapter.getInitialState(),
      loading: false,
      error: null,
      meta: null,
      filters: {
        ...getInitialFilters(),
      },
      addEditGroupModal: {
        visible: false,
        groupId: null,
      },
      deleteCandidatesIds: [],
    },
    jobTitles: {
      data: jobTitlesAdapter.getInitialState(),
      loading: false,
      error: null,
      meta: null,
      filters: {
        ...getInitialFilters(),
      },
      addEditModal: {
        show: false,
        jobTitleId: null,
      },
      deleteCandidatesIds: [],
    },
    groupAssignments: {
      data: userGroupsAssignmentsAdapter.getInitialState(),
      loading: false,
      meta: null,
      error: null,
      filters: {
        ...getInitialFilters(),
        userIds: [],
        userGroupIds: [],
      },
      showFiltersModal: false,
      showAddToGroupModal: false,
      deleteCandidatesIds: [],
    },
    access: {
      data: userAccessAdapter.getInitialState(),
      loading: false,
      meta: null,
      error: null,
      filters: {
        ...getInitialFilters(),
        auditObjectId: null,
        userId: null,
        userGroupId: null,
        jobTitleId: null,
      },
      showAssignAccessModal: false,
      showFiltersModal: false,
      deleteCandidatesIds: [],
    },
  },
  (builder) =>
    builder
      .addCase(actions.list.resetData, (state) => {
        state.list = {
          ...state.list,
          data: usersAdapter.getInitialState(),
          loading: false,
          meta: null,
          error: null,
          showFiltersModal: false,
          setUserPasswordModalUserId: null,
        };
      })
      .addCase(actions.list.getData.pending, (state, { meta: { arg } }) => {
        state.list.filters = {
          ...state.list.filters,
          ...(arg || {}),
        };
        state.list.loading = true;
        state.list.error = null;
      })
      .addCase(actions.list.getData.fulfilled, (state, action) => {
        state.list.loading = false;
        state.list.meta = action.payload.meta;
        usersAdapter.setAll(state.list.data, action.payload.data);
      })
      .addCase(actions.list.getData.rejected, (state, action) => {
        state.list.loading = false;
        state.list.error = action.payload as string;
      })
      .addCase(
        actions.list.toggleSetUserPasswordModal,
        (state, { payload: userId }) => {
          state.list.setUserPasswordModalUserId = userId;
        }
      )
      .addCase(actions.groups.getData.pending, (state, { meta: { arg } }) => {
        state.groups.filters = {
          ...state.groups.filters,
          ...(arg || {}),
        };
        state.groups.loading = true;
        state.groups.error = null;
      })
      .addCase(actions.groups.getData.fulfilled, (state, { payload }) => {
        usersGroupAdapter.setAll(state.groups.data, payload.data);
        state.groups.meta = payload.meta;
        state.groups.loading = false;
      })
      .addCase(actions.groups.getData.rejected, (state, { payload }) => {
        if (payload) {
          state.groups.error = payload;
        }

        state.groups.loading = false;
      })
      .addCase(
        actions.groupAssignments.toggleFiltersModal,
        (state, { payload }) => {
          state.groupAssignments.showFiltersModal = payload;
        }
      )
      .addCase(
        actions.groups.toggleAddEditGroupModal,
        (state, { payload: { visible, groupId } }) => {
          state.groups.addEditGroupModal.visible = visible;
          state.groups.addEditGroupModal.groupId = groupId || null;
        }
      )
      .addCase(
        actions.groups.toggleConfirmDeleteGroupsModal,
        (state, { payload }) => {
          state.groups.deleteCandidatesIds = payload;
        }
      )
      .addCase(
        actions.jobTitles.getData.pending,
        (state, { meta: { arg } }) => {
          state.jobTitles.loading = true;
          state.jobTitles.error = null;
          state.jobTitles.filters = {
            ...state.jobTitles.filters,
            ...(arg || {}),
          };
        }
      )
      .addCase(actions.jobTitles.getData.fulfilled, (state, { payload }) => {
        jobTitlesAdapter.setAll(state.jobTitles.data, payload.data);
        state.jobTitles.meta = payload.meta;
        state.jobTitles.loading = false;
      })
      .addCase(actions.jobTitles.getData.rejected, (state, { payload }) => {
        if (payload) {
          state.jobTitles.error = payload;
        }

        state.jobTitles.loading = false;
      })
      .addCase(actions.jobTitles.toggleAddEditModal, (state, { payload }) => {
        state.jobTitles.addEditModal.show = payload.show;
        state.jobTitles.addEditModal.jobTitleId = payload.jobTitleId || null;
      })
      .addCase(
        actions.jobTitles.toggleConfirmDeleteModal,
        (state, { payload }) => {
          state.jobTitles.deleteCandidatesIds = payload;
        }
      )
      .addCase(
        actions.groupAssignments.getData.pending,
        (state, { meta: { arg } }) => {
          state.groupAssignments.loading = true;
          state.groupAssignments.error = null;
          state.groupAssignments.filters = {
            ...state.groupAssignments.filters,
            ...(arg || {}),
          };
        }
      )
      .addCase(
        actions.groupAssignments.getData.fulfilled,
        (state, { payload }) => {
          userGroupsAssignmentsAdapter.setAll(
            state.groupAssignments.data,
            payload.data
          );
          state.groupAssignments.meta = payload.meta;
          state.groupAssignments.loading = false;
        }
      )
      .addCase(
        actions.groupAssignments.getData.rejected,
        (state, { payload }) => {
          if (payload) {
            state.groupAssignments.error = payload;
          }

          state.groupAssignments.loading = false;
        }
      )
      .addCase(
        actions.groupAssignments.toggleAddToGroupModal,
        (state, { payload }) => {
          state.groupAssignments.showAddToGroupModal = payload;
        }
      )
      .addCase(
        actions.groupAssignments.toggleConfirmDeleteModal,
        (state, { payload }) => {
          state.groupAssignments.deleteCandidatesIds = payload;
        }
      )
      .addCase(actions.access.getData.pending, (state, { meta: { arg } }) => {
        state.access.loading = true;
        state.access.error = null;
        state.access.filters = {
          ...state.access.filters,
          ...(arg || {}),
        };
      })
      .addCase(actions.access.getData.fulfilled, (state, { payload }) => {
        userAccessAdapter.setAll(state.access.data, payload.data);
        state.access.meta = payload.meta;
        state.access.loading = false;
      })
      .addCase(actions.access.getData.rejected, (state, { payload }) => {
        if (payload) {
          state.access.error = payload;
        }

        state.access.loading = false;
      })
      .addCase(actions.access.toggleAssignAccessModal, (state, { payload }) => {
        state.access.showAssignAccessModal = payload;
      })
      .addCase(
        actions.access.toggleConfirmDeleteModal,
        (state, { payload }) => {
          state.access.deleteCandidatesIds = payload;
        }
      )
      .addCase(actions.access.toggleFiltersModal, (state, { payload }) => {
        state.access.showFiltersModal = payload;
      })
      .addCase(actions.list.getUserDetails.pending, (state) => {
        state.user.loading = true;
        state.user.data = null;
      })
      .addCase(actions.list.getUserDetails.fulfilled, (state, { payload }) => {
        state.user.loading = false;
        state.user.data = payload;
      })
      .addCase(actions.list.getUserDetails.rejected, (state, { payload }) => {
        state.user.loading = false;
        state.user.error = payload || null;
      })
      .addCase(actions.list.updateUserDetails, (state, { payload }) => {
        state.user.data = payload;
      })
      .addCase(actions.list.toggleFiltersModal, (state, { payload }) => {
        state.list.showFiltersModal = payload;
      })
);
