import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  createAddOrUpdateEntityThunk,
  createDeleteEntityThunk,
  removeUndefinedAndNullProps,
} from '@utils';
import { getErrorMessage } from '@repo/shared/utils';
import {
  IAuditObject,
  IAuditObjectAttrAssignmentFilters,
  IAuditObjectAttribute,
  IAuditObjectAttributeAssignment,
  IAuditObjectGroup,
  IAuditObjectGroupAssignment,
  IConfirmResponse,
  IPagedResponse,
  ITableFilters,
  IUpdateAuditObjectRequest,
  IAuditObjectsGroupsAssignmentsFilters,
} from '@repo/shared/types';
import { IRootState } from '../../../frameworks/redux';
import { Logger } from '@repo/shared/services';
import { apiUrls } from '@config';
import { auditObjectsSelectors } from '@store';
import { InternalApiService } from '@repo/shared/api';

const apiService = InternalApiService.getInstance();

export const auditObjectsActions = {
  objects: {
    getData: createAsyncThunk<
      IPagedResponse<IAuditObject>,
      Partial<ITableFilters> | undefined,
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/objects/getData',
      async (update = {}, { rejectWithValue, getState }) => {
        const state = getState();
        const filters = auditObjectsSelectors.getFilters(state);

        try {
          return apiService.post({
            url: `${apiUrls.auditObjects}/detailed`,
            body: removeUndefinedAndNullProps({
              ...filters,
              ...update,
            }),
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    updateObject: createAddOrUpdateEntityThunk<IUpdateAuditObjectRequest>({
      apiUrl: apiUrls.auditObjects,
      entityName: 'auditObjects',
    }),
    deleteObjects: createDeleteEntityThunk({
      apiUrl: apiUrls.auditObjects,
      entityName: 'auditObjects',
    }),
    getObjectDetails: createAsyncThunk<
      IAuditObject,
      string,
      { rejectValue: string }
    >('auditObjects/objects/getDetails', async (id, { rejectWithValue }) => {
      try {
        return apiService.get({
          url: `${apiUrls.auditObjects}/${id}`,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }),
    updateObjectDetails: createAction<IAuditObject | null>(
      'auditObjects/objects/updateObjectDetails'
    ),
    showAddEditModal: createAction<IAuditObject | undefined>(
      'auditObjects/objects/showAddEditModal'
    ),
    hideAddEditModal: createAction('auditObjects/objects/hideAddEditModal'),
    toggleFiltersModal: createAction<boolean>(
      'auditObjects/list/toggleFiltersModal'
    ),
  },
  groups: {
    getData: createAsyncThunk<
      IPagedResponse<IAuditObjectGroup>,
      Partial<ITableFilters> | undefined,
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/groups/getData',
      async (update = {}, { rejectWithValue, getState }) => {
        const state = getState();
        const filters = auditObjectsSelectors.groups.getFilters(state);

        try {
          return apiService.get({
            url: `${apiUrls.auditObjectsGroups}/detailed`,
            query: removeUndefinedAndNullProps({
              ...filters,
              ...update,
            }),
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    deleteGroups: createAsyncThunk<void, string[], { rejectValue: string }>(
      'auditObjects/groups/delete',
      async (ids, { rejectWithValue }) => {
        try {
          await apiService.delete({
            url: apiUrls.auditObjectsGroups,
            body: {
              ids,
            },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleAddEditModal: createAction<{
      visible: boolean;
      groupId?: string;
    }>('auditObjects/groups/toggleAddEditModal'),
    toggleConfirmDeleteModal: createAction<string[]>(
      'auditObjects/groups/toggleConfirmDeleteModal'
    ),
    updateGroup: createAsyncThunk<
      IConfirmResponse,
      Partial<{ id?: string; name?: string; auditObjectIds?: string[] }>,
      { rejectValue: string }
    >(
      'auditObjects/groups/updateGroup',
      async (payload, { rejectWithValue }) => {
        const createNew = !payload.id;

        try {
          const response = await apiService[createNew ? 'post' : 'put']({
            url: apiUrls.auditObjectsGroups,
            body: payload,
          });

          return response as IConfirmResponse;
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
  },
  groupAssignments: {
    getData: createAsyncThunk<
      IPagedResponse<IAuditObjectGroupAssignment>,
      Partial<IAuditObjectsGroupsAssignmentsFilters> | undefined,
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/groupAssignments/getData',
      async (update = {}, { rejectWithValue, getState }) => {
        const state = getState();
        const filters =
          auditObjectsSelectors.groupAssignments.getFilters(state);

        try {
          return apiService.post({
            url: `${apiUrls.auditObjects}/groups/assignments/detailed`,
            body: removeUndefinedAndNullProps({
              ...filters,
              ...update,
            }),
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleAddToGroupModal: createAction<boolean>(
      'auditObjects/groupAssignments/toggleAddToGroupModal'
    ),
    toggleFiltersModal: createAction<boolean>(
      'auditObjects/groupAssignments/toggleFiltersModal'
    ),
    createAssignment: createAsyncThunk<
      void,
      {
        auditObjectsIds: string[];
        groupsIds: string[];
      },
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/groupAssignments/create',
      async ({ auditObjectsIds, groupsIds }, { rejectWithValue }) => {
        try {
          return apiService.post({
            url: `${apiUrls.auditObjects}/groups/assignments`,
            body: { auditObjectsIds, auditObjectGroupsIds: groupsIds },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleConfirmDeleteModal: createAction<string[]>(
      'auditObjects/groupAssignments/toggleConfirmDeleteModal'
    ),
    deleteAssignments: createAsyncThunk<
      void,
      string[],
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/groupAssignments/delete',
      async (assignmentsIds, { rejectWithValue }) => {
        try {
          return apiService.delete({
            url: `${apiUrls.auditObjects}/groups/assignments`,
            body: {
              ids: assignmentsIds,
            },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
  },
  attributesAssignments: {
    getData: createAsyncThunk<
      IPagedResponse<IAuditObjectAttributeAssignment>,
      Partial<IAuditObjectAttrAssignmentFilters> | undefined,
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/assignments/getData',
      async (update = {}, { rejectWithValue, getState }) => {
        const state = getState();
        const filters =
          auditObjectsSelectors.attributesAssignments.getFilters(state);

        try {
          return apiService.get({
            url: `${apiUrls.auditObjects}/assignments`,
            query: removeUndefinedAndNullProps({
              ...filters,
              ...update,
            }),
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleAddModal: createAction<boolean>(
      'auditObjects/assignments/toggleAddModal'
    ),
    createAssignment: createAsyncThunk<
      void,
      {
        auditObjectsIds: string[];
        attributeId: string;
        optionId: string;
      },
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/assignments/create',
      async (
        { attributeId, auditObjectsIds, optionId },
        { rejectWithValue }
      ) => {
        try {
          return apiService.post({
            url: `${apiUrls.auditObjects}/assignments`,
            body: {
              attributeId,
              auditObjectsIds,
              optionId,
            },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleConfirmDeleteModal: createAction<string[]>(
      'auditObjects/assignments/toggleConfirmDeleteModal'
    ),
    deleteAssignments: createAsyncThunk<
      void,
      string[],
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/assignments/delete',
      async (assignmentsIds, { rejectWithValue }) => {
        try {
          return apiService.delete({
            url: `${apiUrls.auditObjects}/assignments`,
            body: {
              ids: assignmentsIds,
            },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleFiltersModal: createAction<boolean>(
      'auditObjects/assignments/toggleFiltersModal'
    ),
  },
  attributes: {
    getData: createAsyncThunk<
      IPagedResponse<IAuditObjectAttribute>,
      Partial<ITableFilters> | undefined,
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/attributes/getDataPaged',
      async (update = {}, { rejectWithValue, getState }) => {
        const state = getState();
        const filters = auditObjectsSelectors.attributes.getFilters(state);

        try {
          return apiService.get({
            url: `${apiUrls.auditObjects}/attributes`,
            query: removeUndefinedAndNullProps({
              ...filters,
              ...update,
            }),
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleAddEditModal: createAction<{ show: boolean; attributeId?: string }>(
      'auditObjects/attributes/toggleAddEditModal'
    ),
    addEditAttribute: createAsyncThunk<
      void,
      Omit<IAuditObjectAttribute, 'id'> & { id?: string },
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/attributes/addEditAttribute',
      async (attribute, { rejectWithValue }) => {
        try {
          const isEdit = attribute.id !== undefined;

          return apiService[isEdit ? 'put' : 'post']({
            url: `${apiUrls.auditObjects}/attributes${
              isEdit ? `/${attribute.id}` : ''
            }`,
            body: attribute,
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    deleteAttributes: createAsyncThunk<
      void,
      string[],
      { state: IRootState; rejectValue: string }
    >(
      'auditObjects/attributes/deleteAttributes',
      async (attributesIds, { rejectWithValue }) => {
        try {
          return apiService.delete({
            url: `${apiUrls.auditObjects}/attributes`,
            body: {
              ids: attributesIds,
            },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleConfirmDeleteModal: createAction<string[]>(
      'auditObjects/attributes/toggleConfirmDeleteModal'
    ),
  },
};
