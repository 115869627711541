import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { actionsActions, actionsSelectors, generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';

import ConfirmDeleteModal from '@components/shared/ConfirmDeleteModal/ConfirmDeleteModal';

interface IProps {
  onSuccess(): void;
}

const DeleteActionModal: React.FC<React.PropsWithChildren<IProps>> = ({
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const correctiveActions = useSelector(actionsSelectors.getActionsDictionary);
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  return (
    <ConfirmDeleteModal
      text={{
        title: formatMessage(
          { id: 'DeleteCorrectiveActions' },
          { itemCount: selectedTableRowKeys.length || 1 }
        ),
        error: formatMessage(
          { id: 'ErrorWhileDeletingCorrectiveActions' },
          { itemCount: selectedTableRowKeys.length || 1 }
        ),
        desc: formatMessage(
          { id: 'YouAreAboutToRemoveCorrectiveActionsFromSystem' },
          { itemCount: selectedTableRowKeys.length || 1 }
        ),
      }}
      onSuccess={onSuccess}
      onSubmit={async (ids) => {
        dispatch(actionsActions.deleteActions(ids));
      }}
      data={correctiveActions}
    />
  );
};

export default DeleteActionModal;
