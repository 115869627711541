import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { IRole } from '@repo/shared/types';
import { getColumnSortProperties, getInitialFilters } from '@utils';
import { delay } from '@repo/shared/utils';
import { usePermission, useAppDispatch } from '@hooks';
import { Modal } from '@repo/shared/enums';
import {
  accountSelectors,
  generalActions,
  generalSelectors,
  rolesActions,
  rolesSelectors,
} from '@store';
import { Permission, RoleType } from '@repo/shared/enums';

import Table from '../../shared/ant/Table/Table';
import EmptyTable from '../../shared/ant/EmptyTable/EmptyTable';
import RolesActionsMenu from './RolesActionsMenu';

const RolesTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { loading, meta, roles, error } = useSelector(
    rolesSelectors.getRoles,
    shallowEqual
  );
  const filters = useSelector(rolesSelectors.getFilters, shallowEqual);
  const { formatMessage } = useIntl();
  const { opened: showAddEditRoleModal } = useSelector(
    generalSelectors.getModal(Modal.AddEdit)
  );
  const { opened: showViewRoleModal } = useSelector(
    generalSelectors.getModal(Modal.View)
  );
  const canManageRoles = usePermission(Permission.CanManageRoles);
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const isCurrentUserAdmin = currentUser?.role.roleType === RoleType.Admin;

  useEffect(() => {
    dispatch(rolesActions.getRoles());
  }, [dispatch, filters]);

  return (
    <Table
      onPageChange={(update) => dispatch(rolesActions.setFilters(update))}
      loading={loading}
      canSelectRow={canManageRoles}
      meta={meta}
      error={error}
      onRow={({ id, name, description }) => ({
        onClick: async () => {
          if (showAddEditRoleModal) {
            dispatch(generalActions.hideModal(Modal.AddEdit));
          }

          if (showViewRoleModal) {
            dispatch(generalActions.hideModal(Modal.View));
          }

          if (showAddEditRoleModal || showViewRoleModal) {
            await delay(250);
          }

          dispatch(
            generalActions.showModal({
              name: Modal.View,
              data: {
                id,
                title: name,
                subTitle: description,
              },
            })
          );
        },
      })}
      columns={[
        {
          title: formatMessage({ id: 'RoleName' }),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
          className: 'cell-text-link',
        },
        {
          title: formatMessage({ id: 'Description' }),
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: formatMessage({ id: 'NumberOfUsers' }),
          dataIndex: 'usersCount',
          key: 'usersCount',
        },
        ...(canManageRoles
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center' as 'center',
                width: 70,
                render: (role: IRole) =>
                  !role.isDefault ? <RolesActionsMenu role={role} /> : null,
              },
            ]
          : []),
      ]}
      dataSource={
        roles
          ? roles.map(
              ({
                id,
                name,
                description,
                usersCount,
                isDefault,
                roleType,
              }: IRole) => ({
                key: id,
                id,
                name,
                description,
                usersCount,
                isDefault,
                roleType,
                disableRowSelection: isDefault,
              })
            )
          : []
      }
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {formatMessage(
                  {
                    id:
                      filters.search !== ''
                        ? 'NoRolesSearchResults'
                        : 'NoRoles',
                  },
                  { searchStr: filters.search }
                )}
                {isCurrentUserAdmin && (
                  <>
                    <br />
                    {formatMessage({ id: 'YouCanAddRoleRightNow' })}
                  </>
                )}
              </>
            }
            button={
              isCurrentUserAdmin
                ? {
                    text: `+ ${formatMessage({ id: 'CreateNewRole' })}`,
                    type: 'link',
                    onClick: () =>
                      dispatch(rolesActions.showAddEditRoleModal()),
                  }
                : null
            }
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(rolesActions.setFilters({ orderBy, orderByDirection }))
      }
    />
  );
};

export default RolesTable;
