import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import {
  userAccessAdapter,
  jobTitlesAdapter,
  userGroupsAssignmentsAdapter,
  usersAdapter,
  usersGroupAdapter,
} from '@store/entityAdapters';
import { IUsersFilters } from '@repo/shared/types';

export const usersSelectors = {
  list: {
    getData: createSelector(
      (state: IRootState) => state.users.list,
      (users) => ({
        loading: users.loading,
        error: users.error,
        meta: users.meta,
        users: usersAdapter.getSelectors().selectAll(users.data),
      })
    ),
    getMap: (state: IRootState) =>
      usersAdapter.getSelectors().selectEntities(state.users.list.data),
    getFilters: createSelector(
      (state: IRootState) => state.users.list,
      (users): IUsersFilters => users.filters
    ),
    isFiltersModalVisible: (state: IRootState) =>
      state.users.list.showFiltersModal,
    getSetUserPasswordModalUserId: (state: IRootState) =>
      state.users.list.setUserPasswordModalUserId,
    getUserDetails: (state: IRootState) => state.users.user,
    getSelectedFiltersQty: createSelector(
      (state: IRootState): IUsersFilters => state.users.list.filters,
      (filters) => {
        let selected = 0;

        if (filters.ungrouped) {
          selected += 1;
        }

        if (filters.userGroupIds.length > 0) {
          selected += 1;
        }

        if (filters.userRoleIds.length > 0) {
          selected += 1;
        }

        return selected;
      }
    ),
  },
  groups: {
    getData: createSelector(
      (state: IRootState) => state.users.groups,
      ({ loading, error, data, meta }) => ({
        loading,
        error,
        data: usersGroupAdapter.getSelectors().selectAll(data),
        meta,
      })
    ),
    getFilters: (state: IRootState) => state.users.groups.filters,
    getMap: (state: IRootState) =>
      usersGroupAdapter.getSelectors().selectEntities(state.users.groups.data),
    getAddEditGroupModalState: (state: IRootState) =>
      state.users.groups.addEditGroupModal,
    getDeleteCandidatesIds: (state: IRootState) =>
      state.users.groups.deleteCandidatesIds,
  },
  jobTitles: {
    getData: createSelector(
      (state: IRootState) => state.users.jobTitles,
      ({ loading, error, data, meta }) => ({
        loading,
        error,
        meta,
        data: jobTitlesAdapter.getSelectors().selectAll(data),
      })
    ),
    getMap: (state: IRootState) =>
      jobTitlesAdapter
        .getSelectors()
        .selectEntities(state.users.jobTitles.data),
    getFilters: (state: IRootState) => state.users.jobTitles.filters,
    getAddEditModalData: (state: IRootState) =>
      state.users.jobTitles.addEditModal,
    getDeleteCandidatesIds: (state: IRootState) =>
      state.users.jobTitles.deleteCandidatesIds,
  },
  groupAssignments: {
    getData: createSelector(
      (state: IRootState) => state.users.groupAssignments,
      ({ loading, error, data, meta }) => ({
        loading,
        error,
        data: userGroupsAssignmentsAdapter.getSelectors().selectAll(data),
        meta,
      })
    ),
    getMap: (state: IRootState) =>
      userGroupsAssignmentsAdapter
        .getSelectors()
        .selectEntities(state.users.groupAssignments.data),
    getFilters: (state: IRootState) => state.users.groupAssignments.filters,
    isAddToGroupModalVisible: (state: IRootState) =>
      state.users.groupAssignments.showAddToGroupModal,
    getDeleteCandidatesIds: (state: IRootState) =>
      state.users.groupAssignments.deleteCandidatesIds,
    isFiltersModalVisible: (state: IRootState) =>
      state.users.groupAssignments.showFiltersModal,
    getSelectedFiltersQty: createSelector(
      (state: IRootState) => state.users.groupAssignments.filters,
      (filters) => {
        let selected = 0;

        if (filters.userIds.length > 0) {
          selected += 1;
        }

        if (filters.userGroupIds.length > 0) {
          selected += 1;
        }

        return selected;
      }
    ),
  },
  access: {
    getData: createSelector(
      (state: IRootState) => state.users.access,
      ({ loading, error, data, meta }) => ({
        loading,
        error,
        data: userAccessAdapter.getSelectors().selectAll(data),
        meta,
      })
    ),
    getMap: (state: IRootState) =>
      userAccessAdapter.getSelectors().selectEntities(state.users.access.data),
    getFilters: (state: IRootState) => state.users.access.filters,
    isAssignAccessModalVisible: (state: IRootState) =>
      state.users.access.showAssignAccessModal,
    getAccessIdsDeleteCandidates: (state: IRootState) =>
      state.users.access.deleteCandidatesIds,
    getSelectedFiltersQty: createSelector(
      (state: IRootState) => state.users.access.filters,
      (filters) => {
        let selected = 0;

        if (filters.auditObjectId !== null) {
          selected += 1;
        }

        if (filters.userId !== null) {
          selected += 1;
        }

        if (filters.userGroupId !== null) {
          selected += 1;
        }

        if (filters.jobTitleId !== null) {
          selected += 1;
        }

        return selected;
      }
    ),
  },
};
