import React from 'react';
import { X } from 'react-feather';

import { Container, Error, Image, RemoveFile } from './styled';
import { IApiService } from '@repo/shared/types';

import ImageContainer from '@repo/shared/components/Previews/ImageContainer';

interface IProps {
  fileId: string;
  getFilePath: () => string;
  getMetadataPath?: (fileId: string) => string;
  onRemove?: () => void;
  onClick: (id: string) => void;
  forceFetch?: boolean;
  imagePreviewStyle?: React.CSSProperties;
  imagePreviewContainerStyle?: React.CSSProperties;
  apiService?: IApiService;
  skipCompanyId?: boolean;
}

const ImagePreview: React.FC<React.PropsWithChildren<IProps>> = ({
  fileId,
  getFilePath,
  getMetadataPath,
  onRemove,
  onClick,
  imagePreviewStyle,
  imagePreviewContainerStyle,
  apiService,
  skipCompanyId,
}) => {
  return (
    <ImageContainer
      fileId={fileId}
      getFilePath={getFilePath}
      getMetadataPath={getMetadataPath}
      apiService={apiService}
      skipCompanyId={skipCompanyId}
      onClick={() => {
        onClick(fileId);
      }}
      extra={
        onRemove && (
          <RemoveFile
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            type="button"
          >
            <X />
          </RemoveFile>
        )
      }
    >
      {({ hasError, imageUrl, onLoadImage, onLoadImageError }) => (
        <Container
          style={{
            width: imagePreviewStyle?.width,
            height: imagePreviewStyle?.height,
            ...(imagePreviewContainerStyle || {}),
          }}
        >
          {hasError ? (
            <Error />
          ) : (
            <Image
              src={imageUrl}
              style={imagePreviewStyle}
              onLoad={onLoadImage}
              onError={onLoadImageError}
            />
          )}
        </Container>
      )}
    </ImageContainer>
  );
};

export default ImagePreview;
