import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Container, Header, EventDate, User } from './styled';
import { IssueEvent } from '@domain/Issues/models/IssueEvent';
import { config } from '@repo/shared/config';
import { issuesSelectors } from '@application/Issues/store';

import ZonedDateTime from '@components/shared/ZonedDateTime';
import Avatar from '@components/shared/ant/Avatar';
import IssueDetailsEventData from './IssueDetailsEventData/IssueDetailsEventData';

interface IProps {
  event: IssueEvent;
}

const IssueDetailsEvent: React.FC<React.PropsWithChildren<IProps>> = ({
  event,
}) => {
  const { formatMessage } = useIntl();

  const createdByFromContactInformation = useSelector(
    issuesSelectors.getIssueCreatedByContactInformation
  );

  return (
    <Container>
      <Header>
        <div>
          {event.createdBy ? (
            <User>
              <Avatar user={event.createdBy} size={26} />
              <span>{event.createdBy.name}</span>
            </User>
          ) : (
            <>
              {createdByFromContactInformation ? (
                <User>
                  <Avatar
                    user={{
                      id: 'no-id',
                      name: createdByFromContactInformation,
                    }}
                    size={26}
                  />
                  <span>{createdByFromContactInformation}</span>
                </User>
              ) : (
                formatMessage({ id: 'CreatedViaPublicForm' })
              )}
            </>
          )}
        </div>
        <EventDate>
          <ZonedDateTime
            dateTime={event.createDate}
            format={config.dateTimeFormat}
          />
        </EventDate>
      </Header>
      <IssueDetailsEventData event={event} />
    </Container>
  );
};

export default IssueDetailsEvent;
