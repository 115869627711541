import React from 'react';
import Skeleton from 'antd/es/skeleton';

import { Container, Label, Value } from './styled';

interface Props {
  horizontal?: boolean;
  label: string;
  value: string | React.ReactNode;
  loading?: boolean;
}

const Definition: React.FC<Props> = ({
  horizontal = false,
  label,
  value,
  loading,
}) => {
  return (
    <Container horizontal={horizontal}>
      <Label>{label}</Label>
      <Value>
        {loading ? (
          <Skeleton.Input active size="small" style={{ borderRadius: 5 }} />
        ) : (
          value
        )}
      </Value>
    </Container>
  );
};

export default Definition;
