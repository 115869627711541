export const issues = {
  issues: {
    table: 'issues-table',
    createIssue: 'issues-header-create-issue',
  },
  issueTypes: {
    createIssueType: 'issues-header-create-issue-type',
    table: 'issue-types-table',
    addEditModal: {
      saveBtn: 'issue-types-add-edit-modal-save-btn',
      closeBtn: 'issue-types-close-add-edit-modal',
      nameInput: 'issue-types-add-edit-modal-name-input',
      autoAssignSelect: 'issue-types-add-edit-modal-auto-assign-select',
      description: 'issue-types-add-edit-modal-description',
    },
  },
  issueTypeFields: {
    createIssueTypeField: 'issues-header-create-issue-type-field',
    table: 'issue-type-fields-table',
    addEditModal: {
      saveBtn: 'issue-type-fields-add-edit-modal-save-btn',
      closeBtn: 'issue-type-fields-close-add-edit-modal',
      nameInput: 'issue-type-fields-add-edit-modal-name-input',
      typeSelect: 'issue-type-fields-add-edit-modal-type-select',
      requiredSwitch: 'issue-type-fields-add-edit-modal-required-switch',
      allowAttachmentsSwitch:
        'issue-type-fields-add-edit-modal-allow-attachments-switch',
      requiredPhotoSwitch:
        'issue-type-fields-add-edit-modal-required-photos-switch',
    },
  },
};
