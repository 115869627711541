import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Container } from './styled';
import { accountSelectors } from '@store';
import { SubscriptionType } from '@repo/shared/enums';
import { RoleType } from '@repo/shared/enums';
import { routes } from '@config';
import { date } from '@utils';

const TrialNotifier: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();

  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const company = useSelector(accountSelectors.getCompany);
  const isCurrentUserAdmin = currentUser?.role.roleType === RoleType.Admin;

  if (
    !company ||
    company.subscriptionType !== SubscriptionType.Trial ||
    !isCurrentUserAdmin
  ) {
    return null;
  }

  const trialDaysLeft = date(company.trialPeriodEndDate).diff(date(), 'days');

  return (
    <Container>
      <strong>
        {formatMessage(
          { id: 'XDaysLeft' },
          { count: trialDaysLeft > 0 ? trialDaysLeft : 0 }
        )}
      </strong>{' '}
      {formatMessage({ id: 'InYourTrial' })}
      <Link to={routes.subscription}>
        {formatMessage({ id: 'UpgradePlan' })}
      </Link>
    </Container>
  );
};

export default TrialNotifier;
