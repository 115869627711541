import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { routes } from '@config';

const pageToRouteMap = {
  [IssuesPage.Pending]: {
    list: routes.pendingIssues,
    single: routes.pendingIssue,
  },
  [IssuesPage.Archive]: {
    list: routes.archiveIssues,
    single: routes.archiveIssue,
  },
};

export function getRoutesFromIssuesPage(page: IssuesPage) {
  return pageToRouteMap[page];
}
