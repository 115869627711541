import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  issueTypesActions,
  issueTypesSelectors,
} from '@application/IssueTypes/store';
import { useAppDispatch } from '@hooks';
import {
  convertConciseToLabelValue,
  convertLabelValueToConcise,
  notification,
} from '@utils';
import { routes, e2eTestElements } from '@config';
import { IssueTypePage } from '@application/IssueTypes/enums/IssueTypePage';
import { Content, LeftCol, RightCol, SectionLabel } from './styled';
import { IssueTypeDetailsFormValues } from '@application/IssueTypes/models/IssueTypeDetailsFormValues';
import { DefaultIssueTypeDueDatePeriod } from '@domain/IssueTypes/models/IssueType';
import { Priority } from '@repo/shared/enums';

import IssueTypeContentWrapper from '@src/presentation/IssueTypes/IssueType/IssueTypeContentWrapper/IssueTypeContentWrapper';
import HeaderActionMenu from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/HeaderActionMenu/HeaderActionMenu';
import DeleteIssueTypesModal from '@src/presentation/IssueTypes/DeleteIssueTypesModal';
import Form from '@components/shared/ant/Form';
import IssueTypeDetailsGeneralFormFields from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetailsGeneralFormFields';
import IssueTypeDetailsQuestionsFormFields from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetailsQuestionsFormFields';
import IssueTypeDetailsContactFormFields from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetailsContactFormFields';
import Button from '@components/shared/ant/Button';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';

interface IProps {}

const IssueTypeDetails: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<IssueTypeDetailsFormValues>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id: issueTypeId } = useParams();

  const { loading: issueTypeDetailsLoading, data } = useSelector(
    issueTypesSelectors.getIssueTypeDetails
  );

  const [loading, setLoading] = useState(!!issueTypeId && !data);
  const [saving, setSaving] = useState(false);

  const onFormSubmit = useCallback(
    async ({
      name,
      autoAssignJobTitles,
      description,
      fields,
      defaultPriority,
      defaultDueDatePeriodValue,
      defaultDueDatePeriod,
      contactInformationFields,
      contactInformationInfo,
    }: IssueTypeDetailsFormValues) => {
      setSaving(true);

      const update = {
        name,
        description,
        autoAssignJobTitles: autoAssignJobTitles.map(
          convertLabelValueToConcise
        ),
        fields,
        defaultPriority,
        defaultDueDatePeriodValue,
        defaultDueDatePeriod,
        contactInformationFields,
        contactInformationInfo,
      };

      const actionCreator = issueTypeId
        ? issueTypesActions.editIssueType
        : issueTypesActions.createIssueType;

      const actionToDispatch =
        issueTypeId && data
          ? issueTypesActions.editIssueType({
              ...data,
              ...update,
            })
          : issueTypesActions.createIssueType(update);

      const resultAction = await dispatch(actionToDispatch);

      const item = formatMessage({ id: 'IssueType' }).toLowerCase();

      if (actionCreator.fulfilled.match(resultAction)) {
        notification.success({
          message: formatMessage(
            {
              id: issueTypeId
                ? 'YouHaveSuccessfullyEditedItem'
                : 'YouHaveSuccessfullyCreatedItem',
            },
            {
              item,
            }
          ),
        });

        if (!issueTypeId) {
          navigate(
            routes.issueTypeDetails.replace(':id', unwrapResult(resultAction))
          );
        }
      } else {
        notification.error({
          message: formatMessage(
            {
              id: issueTypeId
                ? 'ErrorWhileEditingItem'
                : 'ErrorWhileCreatingItem',
            },
            {
              item,
            }
          ),
        });
      }

      setSaving(false);
    },
    [JSON.stringify(data || {})]
  );

  useEffect(() => {
    if (!issueTypeDetailsLoading && data) {
      form.setFieldsValue({
        name: data.name,
        autoAssignJobTitles: data.autoAssignJobTitles.map(
          convertConciseToLabelValue
        ),
        description: data.description || '',
        fields: data.fields,
        contactInformationFields: data.contactInformationFields,
        contactInformationInfo: data.contactInformationInfo || '',
        defaultDueDatePeriod: data.defaultDueDatePeriod,
        defaultDueDatePeriodValue:
          data.defaultDueDatePeriodValue?.toString() || '',
        defaultPriority: data.defaultPriority,
      });

      setLoading(false);
    }
  }, [issueTypeDetailsLoading, JSON.stringify(data || {})]);

  return (
    <>
      <IssueTypeContentWrapper
        page={IssueTypePage.Details}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
              loading={saving}
              data-e2e={e2eTestElements.issues.issueTypes.addEditModal.saveBtn}
            >
              {formatMessage({ id: data ? 'Save' : 'Create' })}
            </Button>
            {!!data && <HeaderActionMenu issueType={data} />}
          </Space>
        }
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <Form<IssueTypeDetailsFormValues>
            form={form}
            name="add-edit-issue-type-form"
            layout="vertical"
            onFinish={onFormSubmit}
            initialValues={{
              name: '',
              autoAssignJobTitles: [],
              description: '',
              fields: [],
              contactInformationFields: [],
              contactInformationInfo: '',
              defaultDueDatePeriod: DefaultIssueTypeDueDatePeriod.Week,
              defaultDueDatePeriodValue: '1',
              defaultPriority: Priority.Low,
            }}
          >
            <Content>
              <LeftCol>
                <IssueTypeDetailsGeneralFormFields saving={saving} />
              </LeftCol>
              <RightCol>
                <SectionLabel required>
                  {formatMessage({ id: 'Questions' })}
                </SectionLabel>
                <IssueTypeDetailsQuestionsFormFields
                  saving={saving}
                  form={form}
                />
                <SectionLabel style={{ marginTop: '30px' }}>
                  {formatMessage({ id: 'ContactInformationFields' })}
                  <CircleTooltip
                    contentMaxWidth={300}
                    triggerStyles={{ position: 'relative', top: '-1px' }}
                  >
                    {formatMessage({
                      id: 'PleaseBeAwareContactInformationFieldsOnlyAvailable',
                    })}
                  </CircleTooltip>
                </SectionLabel>
                <IssueTypeDetailsContactFormFields
                  saving={saving}
                  form={form}
                />
              </RightCol>
            </Content>
          </Form>
        )}
      </IssueTypeContentWrapper>
      <DeleteIssueTypesModal />
    </>
  );
};

export default IssueTypeDetails;
