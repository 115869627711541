import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { useAppDispatch } from '@hooks';
import { issueTypesActions } from '@application/IssueTypes/store';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { Container } from './styled';

import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import IssuesHeader from '@src/presentation/Issues/IssuesHeader/IssuesHeader';
import IssuesTable from '@src/presentation/Issues/IssuesTable/IssuesTable';
import IssuesFiltersModal from '@src/presentation/Issues/IssuesFiltersModal/IssuesFiltersModal';
import CreateIssueModal from '@src/presentation/Issues/CreateIssueModal/CreateIssueModal';
import DeleteIssueModal from '@src/presentation/Issues/DeleteIssueModal';
import IssuesActionsModal from '@src/presentation/Issues/IssuesActionsModal';
import CloseIssuesModal from '@src/presentation/Issues/CloseIssuesModal';
import ReopenIssuesModal from '@src/presentation/Issues/ReopenIssuesModal';
import AssignUsersToIssuesModal from '@src/presentation/Issues/AssignUsersToIssuesModal';
import IssueDetailsRouteHandler from '@presentation/Issues/IssueDetailsRouteHandler';
import IssueDetails from '@presentation/Issues/IssueDetails/IssueDetails';

interface IProps {
  page: IssuesPage;
}

const Issues: React.FC<React.PropsWithChildren<IProps>> = ({ page }) => {
  const dispatch = useAppDispatch();

  const currentPage = useSelector(issuesSelectors.getPage);

  useEffect(() => {
    if (currentPage === page) {
      dispatch(issuesActions.getIssues());
      dispatch(issueTypesActions.getConciseIssueTypes());
    }
  }, [currentPage]);

  return (
    <Container>
      <SampleDataAlert />
      <IssuesHeader page={page} />
      <IssuesTable page={page} />
      <IssuesFiltersModal page={page} />
      <CreateIssueModal />
      <DeleteIssueModal />
      <IssuesActionsModal page={page} />
      <ReopenIssuesModal />
      <CloseIssuesModal />
      <AssignUsersToIssuesModal />
      <IssueDetailsRouteHandler page={page} />
      <IssueDetails />
    </Container>
  );
};

export default Issues;
