import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import { itemAnalysisReportSelectors } from './itemAnalysis-report.selectors';
import {
  IFailedItem,
  IFailedItemsFilters,
  ICompletedAuditDetailsFilters,
  IItemStats,
  IPagedResponse,
  ISectionAnalysisDataItem,
  ISharedFilters,
  IDetailsModalAudit,
  IPagingMeta,
  IDetailsModalParams,
  ICompletedAuditDetails,
  IGetAuditItemsFlagsStatsResponse,
} from '@repo/shared/types';
import { GetActionDto } from '@infrastructure/Actions/models/GetActionDto';
import {
  DetailsModalType,
  ExportTable,
  ExportType,
  HttpMethods,
} from '@repo/shared/enums';
import {
  createGetEntitiesThunk,
  createGetFailedCriticalItemsThunk,
  createGetScoreBreakdownThunk,
  prepareReportPDFRequestBody,
} from '@utils';
import {
  getErrorMessage,
  getExportFileMetaData,
  parseContentDispositionHeader,
  saveFile,
} from '@repo/shared/utils';
import { apiUrls, routes } from '@config';
import {
  dashboardSelectors,
  generalActions,
  generalSelectors,
  performanceReportSelectors,
} from '@store';
import { Logger } from '@repo/shared/services';
import { InternalApiService } from '@repo/shared/api';
import { ItemFlag } from '@repo/shared/enums';
import { DetailsModalItem } from '@domain/common/DetailsModalItem';

const apiService = InternalApiService.getInstance();

const getSectionAnalysis = createGetEntitiesThunk<
  ISectionAnalysisDataItem[],
  ISharedFilters,
  ISectionAnalysisDataItem[]
>({
  apiUrl: `${apiUrls.reports.itemAnalysis}/audits-sections-score-breakdown`,
  entityName: 'reports/itemAnalysis/getSectionAnalysis',
  filtersSelector: itemAnalysisReportSelectors.getFilters,
  method: HttpMethods.post,
});
const getItemStats = createGetEntitiesThunk<
  IItemStats,
  ISharedFilters,
  IItemStats
>({
  apiUrl: `${apiUrls.reports.itemAnalysis}/audits-items-analysis`,
  entityName: 'reports/itemAnalysis/getItemStats',
  filtersSelector: itemAnalysisReportSelectors.getFilters,
  method: HttpMethods.post,
});
const getAuditItemsFlagsStats = createGetEntitiesThunk<
  ICompletedAuditDetails,
  ISharedFilters,
  IGetAuditItemsFlagsStatsResponse
>({
  apiUrl: `${apiUrls.reports.auditPerformance}/audit-items-flags-stats`,
  entityName: 'reports/itemAnalysis/getAuditItemsFlagsStats',
  filtersSelector: itemAnalysisReportSelectors.getFilters,
  method: HttpMethods.post,
});
const getMostFailedItems = createGetEntitiesThunk<
  IFailedItem,
  IFailedItemsFilters,
  IPagedResponse<IFailedItem>
>({
  apiUrl: `${apiUrls.reports.itemAnalysis}/audits-items-report`,
  entityName: 'reports/itemAnalysis/getMostFailedItems',
  // @ts-ignore
  filtersSelector: itemAnalysisReportSelectors.getMostFailedItemsFilters,
  method: HttpMethods.post,
});
const getFailedCriticalItems = createGetFailedCriticalItemsThunk(
  itemAnalysisReportSelectors.getFailedCriticalItemsFilters
);
const getTemplateSections = createAsyncThunk<
  {
    id: string;
    name: string;
    parentSectionId: string | null;
    parentSectionName: string | null;
  }[],
  string,
  { rejectValue: string; state: IRootState }
>('reports/getTemplateSections', async (templateId) => {
  return apiService.get({
    url: `${apiUrls.auditTemplates}/${templateId}/sections`,
  });
});

export const itemAnalysisReportActions = {
  getItemAnalysisReport: createAsyncThunk<
    void,
    Partial<ISharedFilters> | undefined,
    { rejectValue: string; state: IRootState }
  >('reports/itemAnalysis/get', async (update, { dispatch }) => {
    if (update) {
      const { templateIds, ...sharedFilters } = update;

      if (Object.keys(sharedFilters).length > 0) {
        dispatch(generalActions.setSharedFilters(update));
      }
    }

    dispatch(
      createGetScoreBreakdownThunk(itemAnalysisReportSelectors.getFilters)()
    );
    dispatch(getSectionAnalysis());
    dispatch(getItemStats());
    dispatch(getAuditItemsFlagsStats());

    if (!!update?.templateIds && update.templateIds.length === 1) {
      dispatch(getTemplateSections(update.templateIds[0]));
      dispatch(getFailedCriticalItems({ sectionId: null, subSectionId: null }));
      dispatch(getMostFailedItems({ sectionId: null, subSectionId: null }));
    } else {
      dispatch(getFailedCriticalItems());
      dispatch(getMostFailedItems());
    }
  }),
  setReportLoadingStatus: createAction<boolean>(
    'reports/itemAnalysis/setLoading'
  ),
  getSectionAnalysis,
  getItemStats,
  getMostFailedItems,
  getTemplateSections,
  getFailedCriticalItems,
  getAuditItemsFlagsStats,
  setSectionAnalysisPage: createAsyncThunk<number, number>(
    'reports/itemAnalysis/setSectionAnalysisPage',
    async (newPage) => newPage
  ),
  exportReportAsPDF: createAsyncThunk<void, undefined, { state: IRootState }>(
    'reports/exportReportAsPDF',
    async (_, { getState, rejectWithValue }) => {
      const state = getState();

      const { mostFailedItems, failedCriticalItems } =
        state.reports.itemAnalysis;

      try {
        const { data, headers } = await apiService.post<{
          data: 'arraybuffer';
          headers: any;
        }>({
          responseType: 'arraybuffer',
          url: `${apiUrls.reports.itemAnalysis}/export-pdf`,
          body: prepareReportPDFRequestBody({
            state,
            reportFilters: itemAnalysisReportSelectors.getFilters(state),
            failedCriticalItemsFilters: failedCriticalItems.filters,
            mostFailedItemsFilters: mostFailedItems.filters,
          }),
          withHeaders: true,
        });

        const { mimeType } = getExportFileMetaData(ExportType.Pdf);

        saveFile({
          data,
          fileName:
            parseContentDispositionHeader(headers['content-disposition']) ||
            'report.pdf',
          mimeType,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  exportTableData: createAsyncThunk<
    void,
    {
      exportType: ExportType;
      table: ExportTable;
      filters: IFailedItemsFilters | ICompletedAuditDetailsFilters;
    },
    { state: IRootState }
  >(
    'reports/itemAnalysis/exportFailedCriticalItems',
    async ({ exportType, table, filters }, { getState, rejectWithValue }) => {
      const state = getState();

      const { mimeType, urlSuffix, extension } =
        getExportFileMetaData(exportType);

      const meta = {
        [ExportTable.CompletedAuditDetails]: {
          url: `${apiUrls.reports.auditPerformance}/completed-audits/export-${urlSuffix}`,
          fileName: `completed-audits.${extension}`,
        },
        [ExportTable.MostFailedItems]: {
          url: `${apiUrls.reports.itemAnalysis}/audits-items-report/export-${urlSuffix}`,
          fileName: `most-failed-items.${extension}`,
        },
        [ExportTable.CriticalFailedItems]: {
          url: `${apiUrls.reports.itemAnalysis}/failed-critical-items/export-${urlSuffix}`,
          fileName: `failed-critical-items.${extension}`,
        },
      };

      try {
        const { data } = await apiService.post<{
          data: 'arraybuffer';
          headers: any;
        }>({
          responseType: 'arraybuffer',
          url: meta[table].url,
          body: {
            ...itemAnalysisReportSelectors.getFilters(state),
            ...filters,
          },
          withHeaders: true,
        });

        saveFile({
          data,
          fileName: meta[table].fileName,
          mimeType,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  detailsModal: {
    show: createAction<IDetailsModalParams>('reports/detailsModal/showItems'),
    hide: createAction('reports/detailsModal/hide'),
    getData: createAsyncThunk<
      {
        audits: Record<string, IDetailsModalAudit>;
        meta: IPagingMeta;
        itemsByAudit: Record<string, DetailsModalItem[]>;
      },
      undefined,
      { state: IRootState; rejectValue: string }
    >(
      'reports/detailsModal/getItems',
      async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const type = itemAnalysisReportSelectors.detailsModal.getType(state);

        if (type === null) {
          throw new Error(
            'Invalid details modal configuration: type is required'
          );
        }

        try {
          const meta = itemAnalysisReportSelectors.detailsModal.getMeta(state);
          const currentPage = meta?.pageNumber || 0;

          const endpointsUrlsByModalType: Record<DetailsModalType, string> = {
            [DetailsModalType.Items]: `${apiUrls.reports.detailed}/audit-items`,
            [DetailsModalType.Audits]: `${apiUrls.reports.detailed}/audits`,
          };

          const filtersByRoute = {
            [routes.dashboard]: dashboardSelectors.getFilters,
            [routes.itemAnalysisReport]: itemAnalysisReportSelectors.getFilters,
            [routes.auditorPerformanceReportRealTime]:
              performanceReportSelectors.getFilters,
            [routes.auditObjectPerformanceReportHistorical]:
              performanceReportSelectors.getFilters,
            [routes.auditObjectPerformanceReportRealTime]:
              performanceReportSelectors.getFilters,
            [routes.auditorPerformanceReportHistorical]:
              performanceReportSelectors.getFilters,
          };

          const modalFilters =
            itemAnalysisReportSelectors.detailsModal.getFilters(state);
          const filters = (
            filtersByRoute[window.location.pathname] ||
            generalSelectors.getSharedFilters
          )(state);

          const endpoint =
            itemAnalysisReportSelectors.detailsModal.getEndpoint(state);

          const response = await apiService.post<any>({
            url: endpoint || endpointsUrlsByModalType[type],
            body: {
              pageNumber: currentPage + 1,
              pageSize: meta?.pageSize || 10,
              ...filters,
              ...modalFilters,
            },
          });

          const audits: Record<string, IDetailsModalAudit> = {};
          const itemsByAudit: Record<string, DetailsModalItem[]> = {};

          if (type === DetailsModalType.Audits) {
            for (let i = 0; i < response.data.length; i++) {
              const audit: IDetailsModalAudit = response.data[i];

              if (!audits[audit.id]) {
                audits[audit.id] = audit;
              }
            }
          } else {
            for (let i = 0; i < response.data.length; i++) {
              const item: DetailsModalItem = {
                ...response.data[i],
                flags: response.data[i].flags.map(
                  ({ flag }: { flag: ItemFlag }) => flag
                ),
                flagsInRow: response.data[i].flags,
                actions: response.data[i].actions.map(
                  ({ dueDateInformation, ...action }: GetActionDto) => ({
                    ...action,
                    dueDate: dueDateInformation?.localTime || null,
                    dueDateTimeZone: dueDateInformation?.timeZoneAbbreviation,
                  })
                ),
              };

              if (!audits[item.audit.id]) {
                audits[item.audit.id] = item.audit;
              }

              if (!itemsByAudit[item.audit.id]) {
                itemsByAudit[item.audit.id] = [];
              }

              itemsByAudit[item.audit.id].push(item);
            }
          }

          return {
            audits,
            itemsByAudit,
            meta: response.meta,
          };
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
  },
};
