import styled from 'styled-components';
import { AlertTriangle } from 'react-feather';

import { colors } from '@repo/shared/config';
import { hexToRGB } from '@repo/shared/utils';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  background: ${colors.gray5};
  border-radius: 5px;
`;

export const Image = styled.img`
  object-fit: cover;
  cursor: pointer;
`;

export const Error = styled(AlertTriangle)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  color: ${colors.red};
`;

export const RemoveFile = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  background: ${hexToRGB(colors.gray10, 0.8)};
  line-height: 1;
  z-index: 20;
  border-top-right-radius: 5px;

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.white};
  }
`;

export const VideoPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background: ${colors.gray2};
  border: 1px solid ${colors.gray5};

  svg {
    width: 26px;
    height: 26px;
    color: ${colors.blue1};
  }
`;
