import React from 'react';
import { IssueQuestionType } from '@domain/Issues/models/IssueQuestion';
import { Input } from '@components/shared/ant/Input';

type IProps =
  | {
      type: IssueQuestionType.TextField;
      onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
      onBlur?: (value: string) => void;
      disabled?: boolean;
      value?: string;
    }
  | {
      type: IssueQuestionType.TextArea;
      onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
      onBlur?: (value: string) => void;
      disabled?: boolean;
      value?: string;
    };

const IssueQuestionAnswer: React.FC<React.PropsWithChildren<IProps>> = ({
  type,
  onChange,
  onBlur,
  disabled,
  value,
}) => {
  switch (type) {
    case IssueQuestionType.TextArea:
      return (
        <Input.TextArea
          rows={3}
          disabled={disabled}
          onChange={onChange}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e.target.value);
            }
          }}
          value={value}
        />
      );
    case IssueQuestionType.TextField:
      return (
        <Input
          onChange={onChange}
          disabled={disabled}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e.target.value);
            }
          }}
          value={value}
        />
      );
    default:
      return null;
  }
};

export default IssueQuestionAnswer;
