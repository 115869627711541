import React from 'react';
import { useIntl } from 'react-intl';
import { ChevronDown } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Container,
  Content,
  Title,
  EditNote,
  TooltipLine,
  TooltipContent,
} from './styled';
import { BillingPeriod } from '@repo/shared/enums';
import { accountActions, accountSelectors } from '@store';
import useBillingPeriodsNames from './useBillingPeriodsNames';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';

import DropDownMenu from '../../../../../../shared/DropDownMenu/DropDownMenu';
import SubscriptionSummary from '../../../../SubscriptionSummary/SubscriptionSummary';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';

interface IProps {
  edit?: boolean;
}

const OrderSummary: React.FC<React.PropsWithChildren<IProps>> = ({ edit }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading } = useSelector(accountSelectors.getPrices);
  const billingPeriod = useSelector(accountSelectors.getBillingPeriod);
  const periods = useBillingPeriodsNames();

  let {
    totalCost,
    usersCost,
    observersCost,
    observersQuantity,
    usersQuantity,
  } = useSelector(accountSelectors.getTotalCost);

  const details: {
    label: string | React.ReactNode;
    value: string | React.ReactNode;
  }[] = [
    {
      label: (
        <div>
          <TooltipLine>
            {formatMessage({
              id: 'Professional',
            })}
            {observersQuantity === 0 && (
              <>
                ,{' '}
                {formatMessage(
                  { id: 'XActiveUsers' },
                  { count: usersQuantity }
                )}
              </>
            )}
            <CircleTooltip contentMaxWidth={220}>
              <TooltipContent>
                {formatMessage({
                  id: 'YouOnlyPayForActiveUsers',
                })}{' '}
                <Link to={routes.users.list}>
                  {formatMessage({ id: 'ManageUsers' })}
                </Link>
              </TooltipContent>
            </CircleTooltip>
          </TooltipLine>
          {observersQuantity > 0 && (
            <div style={{ paddingTop: '2px', fontStyle: 'italic' }}>
              {formatMessage({ id: 'XUsers' }, { count: usersQuantity })} ($
              {usersCost}) +<br />
              {formatMessage(
                { id: 'XObservers' },
                { count: observersQuantity }
              )}{' '}
              ($
              {observersCost})
            </div>
          )}
        </div>
      ),
      value: `$${totalCost}`,
    },
    {
      label: formatMessage({
        id: 'BillingPeriod',
      }),
      value: (
        <DropDownMenu
          menuItems={[
            {
              name: periods[BillingPeriod.Monthly],
              onClick: () =>
                dispatch(
                  accountActions.setBillingPeriod(BillingPeriod.Monthly)
                ),
            },
            {
              name: periods[BillingPeriod.Annually],
              onClick: () =>
                dispatch(
                  accountActions.setBillingPeriod(BillingPeriod.Annually)
                ),
            },
          ]}
        >
          <div>
            {periods[billingPeriod as BillingPeriod]}
            <ChevronDown />
          </div>
        </DropDownMenu>
      ),
    },
  ];

  return (
    <Container>
      <Content>
        <Title>{formatMessage({ id: 'OrderSummary' })}</Title>
        <SubscriptionSummary details={details} loading={loading} />
      </Content>
      {edit && (
        <EditNote>{formatMessage({ id: 'EditSubscriptionNote' })}</EditNote>
      )}
    </Container>
  );
};

export default OrderSummary;
