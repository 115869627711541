import React from 'react';

import { Container, Header, AddBtn, ListWrapper } from './styled';
import { IConcise } from '@repo/shared/types';

import LinkedItem from '@src/presentation/Issues/IssueDetails/IssueDetailsForm/LinkedEntities/LinkedItem/LinkedItem';

interface IProps<T> {
  header: string;
  addBtnLabel: string;
  items: T[];
  getItemRoute: (item: T) => string;
  onAddClick: () => void;
  canAddItem?: boolean;
  canOpenLinkedItem: boolean;
  itemIcon: React.ReactNode;
  getItemExtra: (item: T) => React.ReactNode;
}

function IssueLinkedItemsList<T extends IConcise>({
  items,
  header,
  addBtnLabel,
  getItemRoute,
  onAddClick,
  canOpenLinkedItem,
  canAddItem,
  itemIcon,
  getItemExtra,
}: React.PropsWithChildren<IProps<T>>) {
  return (
    <Container>
      <Header>{header}</Header>
      {items.length > 0 && (
        <ListWrapper>
          {items.map((item) => {
            return (
              <LinkedItem
                disabled={!canOpenLinkedItem}
                key={item.id}
                route={getItemRoute(item)}
                extra={getItemExtra(item)}
                name={item.name}
                icon={itemIcon}
              />
            );
          })}
        </ListWrapper>
      )}
      {canAddItem && (
        <AddBtn
          type="button"
          onClick={() => {
            onAddClick();
          }}
        >
          {addBtnLabel}
        </AddBtn>
      )}
    </Container>
  );
}

export default IssueLinkedItemsList;
