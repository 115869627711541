import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { FormInstance } from 'antd/es/form';
import AntSelect from 'antd/es/select';
import { v4 as uuid } from 'uuid';

import { IssueTypeContactFieldType } from '@domain/IssueTypes/models/IssueType';
import {
  AddButton,
  FieldsRow,
} from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetailsFormFieldContainer/styled';
import { IssueTypeContactInformationField } from '@domain/IssueTypes/models/IssueType';
import { enumToArray } from '@utils';

import Select from '@components/shared/ant/Select/Select';
import LabeledSwitch from '@components/shared/LabeledSwitch/LabeledSwitch';
import Form from '@components/shared/ant/Form';
import IssueTypeDetailsFormFieldContainer from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetailsFormFieldContainer/IssueTypeDetailsFormFieldContainer';
import { Input } from '@components/shared/ant/Input';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';

interface IProps {
  saving: boolean;
  form: FormInstance;
}

const { Option } = AntSelect;

const contactFieldTypesArr = enumToArray<IssueTypeContactFieldType>(
  IssueTypeContactFieldType
).map(([, type]) => type);

export const contactFieldTypesLangIds = {
  [IssueTypeContactFieldType.Name]: 'Name',
  [IssueTypeContactFieldType.Phone]: 'Phone',
  [IssueTypeContactFieldType.Email]: 'Email',
};

const IssueTypeDetailsContactFormFields: React.FC<
  React.PropsWithChildren<IProps>
> = ({ saving, form }) => {
  const { formatMessage } = useIntl();

  const getNewField = useCallback(() => {
    const selectedTypes = (
      form.getFieldValue(
        'contactInformationFields'
      ) as IssueTypeContactInformationField[]
    ).reduce<Set<IssueTypeContactFieldType>>((acc, field) => {
      const a = acc;
      a.add(field.type);
      return a;
    }, new Set());

    return {
      id: uuid(),
      type: contactFieldTypesArr.find((type) => !selectedTypes.has(type)),
      required: false,
    };
  }, []);

  return (
    <>
      <Form.List name="contactInformationFields">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => {
              return (
                <IssueTypeDetailsFormFieldContainer
                  key={field.key}
                  disabledAddBtn={fields.length === 3}
                  onAdd={() => {
                    add(getNewField(), index + 1);
                  }}
                  onDelete={() => {
                    remove(field.name);
                  }}
                >
                  <FieldsRow>
                    <Form.Item
                      layout="horizontal"
                      name={[field.name, 'type']}
                      label={formatMessage({ id: 'Type' })}
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'RequiredField' }),
                        },
                      ]}
                      shouldUpdate
                    >
                      <Select disabled={saving} style={{ minWidth: '120px' }}>
                        {contactFieldTypesArr.map((type) => (
                          <Option
                            key={type}
                            value={type}
                            disabled={form
                              .getFieldValue('contactInformationFields')
                              .some(
                                ({ type: fieldType }: any, j: number) =>
                                  fieldType === type && j !== index
                              )}
                          >
                            {formatMessage({
                              id: contactFieldTypesLangIds[type],
                            })}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={[field.name, 'required']}>
                      <LabeledSwitch
                        title={formatMessage({
                          id: 'Required',
                        })}
                        disabled={saving}
                        titleStyle={{
                          fontSize: '13px',
                          lineHeight: '18px',
                          whiteSpace: 'nowrap',
                        }}
                      />
                    </Form.Item>
                  </FieldsRow>
                </IssueTypeDetailsFormFieldContainer>
              );
            })}
            {fields.length === 0 && (
              <AddButton
                type="button"
                onClick={() => {
                  add(getNewField(), fields.length);
                }}
              >
                + {formatMessage({ id: 'AddNewContactField' })}
              </AddButton>
            )}
          </>
        )}
      </Form.List>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          (getFieldValue('contactInformationFields') || []).length > 0 ? (
            <Form.Item
              name="contactInformationInfo"
              label={
                <div>
                  {formatMessage({ id: 'ContributorGuidanceOptional' })}
                  <CircleTooltip contentMaxWidth={300}>
                    {formatMessage({ id: 'ContributorGuidanceDesc' })}
                  </CircleTooltip>
                </div>
              }
              style={{
                marginTop: '15px',
                maxWidth: '800px',
                marginRight: '65px',
              }}
              rules={[
                {
                  max: 1600,
                  message: formatMessage(
                    {
                      id: 'NameMustNotExceedXCharacters',
                    },
                    { count: 1600 }
                  ),
                },
              ]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          ) : null
        }
      </Form.Item>
    </>
  );
};

export default IssueTypeDetailsContactFormFields;
