import React, { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { generalActions, scoresActions } from '@store';
import { useAppDispatch } from '@hooks';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { auditsActions } from '@application/Audits/store/auditsActions';

import AuditsHeader from './AuditsHeader/AuditsHeader';
import AuditsTable from './AuditsTable/AuditsTable';
import AuditsActionsModal from './AuditsActionsModal';
import DeleteAuditModal from './DeleteAuditModal';
import ViewAuditModalRouteHandler from '@presentation/Audits/ViewAuditModal/ViewAuditModalRouteHandler';
import AuditsFiltersModal from './AuditsFiltersModal/AuditsFiltersModal';
import ReopenAuditModal from './ReopenAuditModal/ReopenAuditModal';
import CreateOneTimeAuditModal from '@presentation/Audits/CreateOneTimeAuditModal/CreateOneTimeAuditModal';
import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import FeedbackModal from '@components/shared/FeedbackModal/FeedbackModal';
import MyAuditsList from '@presentation/Audits/MyAuditsList/MyAuditsList';
import ConfirmExpireAuditModal from '@presentation/Audits/ConfirmExpireAuditModal';
import TrendsModal from '@presentation/PerformAudit/TrendsModal/TrendsModal';
import ViewAuditModal from '@presentation/Audits/ViewAuditModal/ViewAuditModal';
import ActionDetails from '@presentation/Actions/ActionDetails/ActionDetails';

const PerformAudit = lazy(
  () => import('@presentation/PerformAudit/PerformAudit')
);

interface IProps {
  page: AuditsPage;
}

const Audits: React.FC<React.PropsWithChildren<IProps>> = ({ page }) => {
  const dispatch = useAppDispatch();

  const currentType = useSelector(auditsSelectors.getPage);

  useEffect(() => {
    if (currentType === page) {
      dispatch(scoresActions.getScores());
      dispatch(generalActions.getConciseActionTemplates());
    }
  }, [currentType, page]);

  return (
    <>
      <SampleDataAlert />
      <AuditsHeader type={page} />
      {page === AuditsPage.My ? <MyAuditsList /> : <AuditsTable page={page} />}
      <AuditsActionsModal page={page} />
      <DeleteAuditModal page={page} />
      <ViewAuditModalRouteHandler page={page} />
      <AuditsFiltersModal type={page} />
      <ViewAuditModal page={page} />
      <ReopenAuditModal />
      {(page === AuditsPage.My || page === AuditsPage.Pending) && (
        <CreateOneTimeAuditModal page={page} />
      )}
      <FeedbackModal />
      <ConfirmExpireAuditModal page={page} />
      <Suspense>
        <PerformAudit
          onClose={() => {
            dispatch(auditsActions.getAudits());
          }}
        />
      </Suspense>
      <TrendsModal />
      <ActionDetails />
    </>
  );
};

export default Audits;
