import { useState, useEffect, forwardRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { unwrapResult } from '@reduxjs/toolkit';
import Space from 'antd/es/space';
import Skeleton from 'antd/es/skeleton';
import { Dayjs } from 'dayjs';

import { auditsActions } from '@application/Audits/store/auditsActions';
import { accountSelectors } from '@store';
import { date } from '@utils';
import { delay } from '@repo/shared/utils';
import { config, colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';
import { IConcise } from '@repo/shared/types';
import {
  IExposedFormRef,
  useExposedForm,
} from '@application/Issues/hooks/useExposedForm';
import { AssigneesModalContext } from '@repo/shared/enums';
import { Footer } from './styled';
import { notification } from '@utils/notifications';

import SlideModal from '@components/shared/SideModal/SideModal';
import Button from '@components/shared/ant/Button';
import Form from '@components/shared/ant/Form';
import { Input } from '@components/shared/ant/Input';
import AuditObjectsSelect from '@components/shared/EntitySelects/AuditObjectsSelect';
import AuditTemplatesSelect from '@components/shared/EntitySelects/AuditTemplatesSelect';
import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';
import AssigneesFormItem from '@presentation/PerformAudit/PerformSection/PerformAuditItemTree/ActionModal/AssigneesFormItem';
import Select from '@components/shared/ant/Select/Select';
import { AuditDetails } from '@domain/Audits/models/AuditDetails';

export interface AuditFormValues {
  templateId: string;
  name: string;
  auditObjectId: string;
  endDate: Dayjs;
  auditorHint: string;
  assignedUsers: IConcise[];
}

interface IProps {
  loading?: boolean;
  opened: boolean;
  onCancel: () => void;
  onSave: (update: {
    auditId: string;
    name: string;
    endDate: string;
  }) => Promise<void>;
  audit?: AuditDetails;
  onDelete?: (auditId: string) => void;
  auditObject?: IConcise;
  assigneesContext: AssigneesModalContext;
}

const AddEditAuditModal = forwardRef<IExposedFormRef<AuditFormValues>, IProps>(
  (
    {
      loading,
      opened,
      onCancel,
      onSave,
      audit,
      onDelete,
      auditObject,
      assigneesContext,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const form = useExposedForm<AuditFormValues>(ref);
    const dispatch = useAppDispatch();

    const [saving, setSaving] = useState(false);

    const auditObjectName = useSelector(accountSelectors.getObjectName);

    const updateAudit = useCallback(
      async (
        auditId: string,
        {
          templateId,
          name,
          auditObjectId,
          endDate,
          auditorHint,
          assignedUsers,
        }: AuditFormValues
      ) => {
        const endDateStr = date(endDate.endOf('day')).format(
          config.apiDateFormat
        );

        const resultAction = await dispatch(
          auditsActions.updatePendingAudit({
            id: auditId,
            templateId,
            name,
            auditObjectId,
            endDate: endDateStr,
            auditorHint,
            assignees: assignedUsers,
          })
        );

        if (auditsActions.updatePendingAudit.fulfilled.match(resultAction)) {
          await onSave({
            auditId,
            name,
            endDate: endDateStr,
          });
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileCreatingAudit' }),
            description: resultAction.payload,
          });
        }
      },
      []
    );

    const createAudit = useCallback(
      async ({
        templateId,
        name,
        auditObjectId,
        endDate,
        auditorHint,
        assignedUsers,
      }: AuditFormValues) => {
        const endDateStr = date(endDate.endOf('day')).format(
          config.apiDateFormat
        );

        const resultAction = await dispatch(
          auditsActions.createOneTimeAudit({
            templateId,
            name,
            auditObjectId,
            endDate: date(endDate.endOf('day')).format(config.apiDateFormat),
            auditorHint,
            assignedUsersIds: assignedUsers.map(({ id }) => id),
          })
        );

        if (auditsActions.createOneTimeAudit.fulfilled.match(resultAction)) {
          const auditId = unwrapResult(resultAction);

          await onSave({
            auditId,
            name,
            endDate: endDateStr,
          });
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileCreatingAudit' }),
            description: resultAction.payload,
          });
        }
      },
      []
    );

    useEffect(() => {
      if (opened) {
        form.resetFields();
      }
    }, [opened]);

    return (
      <SlideModal
        opened={opened}
        onModalClose={onCancel}
        title={formatMessage({ id: audit ? 'EditAudit' : 'CreateAudit' })}
        footer={
          <Footer>
            <Space size={10}>
              <Button
                type="primary"
                loading={saving || loading}
                onClick={() => {
                  form?.submit();
                }}
                data-e2e={
                  e2eTestElements.audits.createAdhocAuditModal.createBtn
                }
              >
                {formatMessage({ id: audit ? 'Edit' : 'Create' })}
              </Button>
              <Button
                type="default"
                onClick={() => {
                  form?.resetFields();

                  setTimeout(() => {
                    onCancel();
                  }, 50);
                }}
                data-e2e={
                  e2eTestElements.audits.createAdhocAuditModal.cancelBtn
                }
              >
                {formatMessage({ id: 'Cancel' })}
              </Button>
            </Space>
            {!!onDelete && !!audit && (
              <Button
                type="primary"
                onClick={() => {
                  onDelete(audit?.id);
                  onCancel();
                }}
                disabled={saving || loading}
                style={{ background: colors.red }}
              >
                {formatMessage({ id: 'Delete' })}
              </Button>
            )}
          </Footer>
        }
      >
        <Form<{
          templateId: string;
          name: string;
          auditObjectId: string;
          endDate: Dayjs;
          auditorHint: string;
          assignedUsers: IConcise[];
        }>
          layout="vertical"
          initialValues={{
            templateId: audit?.template.id || null,
            name: audit?.name || '',
            auditObjectId: auditObject?.id || audit?.auditObject.id || null,
            endDate: date(audit?.endDateInformation.localTime) || null,
            auditorHint: audit?.auditorHint || '',
            assignedUsers: audit?.assignees || [],
          }}
          onFinish={async (values) => {
            setSaving(true);

            if (audit) {
              await updateAudit(audit.id, values);
            } else {
              await createAudit(values);
            }

            await delay(200);

            setSaving(false);
          }}
          form={form}
        >
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <Form.Item
                name="templateId"
                label={formatMessage({ id: 'AuditTemplate' })}
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'RequiredField' }),
                  },
                ]}
              >
                <AuditTemplatesSelect
                  disabled={saving}
                  placeholder={formatMessage({ id: 'SelectAuditTemplate' })}
                  e2eDataAttr={
                    e2eTestElements.audits.createAdhocAuditModal
                      .auditTemplateSelect
                  }
                />
              </Form.Item>
              <Form.Item
                name="name"
                label={formatMessage({ id: 'Name' })}
                rules={[
                  {
                    max: 800,
                    message: formatMessage(
                      {
                        id: 'NameMustNotExceedXCharacters',
                      },
                      { count: 800 }
                    ),
                  },
                ]}
              >
                <Input
                  disabled={saving}
                  placeholder={formatMessage({ id: 'EnterName' })}
                  data-e2e={
                    e2eTestElements.audits.createAdhocAuditModal.auditName
                  }
                />
              </Form.Item>
              <Form.Item
                name="auditObjectId"
                label={auditObjectName.single}
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'RequiredField' }),
                  },
                ]}
              >
                {auditObject ? (
                  <Select
                    options={[
                      { value: auditObject.id, label: auditObject.name },
                    ]}
                  />
                ) : (
                  <AuditObjectsSelect
                    disabled={saving}
                    placeholder={formatMessage(
                      { id: 'SelectAuditObject' },
                      { objectName: auditObjectName.single.toLowerCase() }
                    )}
                    e2eDataAttr={
                      e2eTestElements.audits.createAdhocAuditModal
                        .auditObjectSelect
                    }
                  />
                )}
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name="assignedUsers"
                      label={formatMessage({ id: 'AssignedTo' })}
                    >
                      <AssigneesFormItem
                        auditObjectId={
                          auditObject?.id || getFieldValue('auditObjectId')
                        }
                        disabled={saving}
                        context={assigneesContext}
                        e2eDataAttr={
                          e2eTestElements.audits.createAdhocAuditModal.assignees
                        }
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="endDate"
                label={formatMessage({ id: 'DueDate' })}
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'PleaseSetDueDate' }),
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current: Dayjs) =>
                    current && current.isBefore(date().startOf('day'))
                  }
                  e2eDataAttr={
                    e2eTestElements.audits.createAdhocAuditModal.dueDate
                  }
                />
              </Form.Item>
              <Form.Item
                name="auditorHint"
                label={
                  <>
                    <>
                      {formatMessage({ id: 'AuditorHint' })}
                      <CircleTooltip contentMaxWidth={250}>
                        <p>
                          {formatMessage({
                            id: 'ProvideAnyAdditionalDetailsThatCouldAssistDuringTheAudit',
                          })}
                        </p>
                      </CircleTooltip>
                    </>
                  </>
                }
                rules={[
                  {
                    max: 800,
                    message: formatMessage(
                      {
                        id: 'NameMustNotExceedXCharacters',
                      },
                      { count: 800 }
                    ),
                  },
                ]}
              >
                <Input.TextArea
                  data-e2e={
                    e2eTestElements.audits.createAdhocAuditModal.auditorHint
                  }
                  disabled={saving}
                  placeholder={formatMessage({
                    id: 'EnterAnyHelpfulHintsOrPointersHere',
                  })}
                  rows={3}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </SlideModal>
    );
  }
);

export default AddEditAuditModal;
