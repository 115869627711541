import { createGlobalStyle } from 'styled-components';

import { colors, BREAKPOINTS } from '@repo/shared/config';
import { hexToRGB } from '@repo/shared/utils';

export const buttonStyles = `
  .ant-btn {
    border-radius: 5px;
    border: none;

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }

    & > span {
      vertical-align: middle;
      line-height: 1;
    }
    
    &.ant-btn-primary {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
    }
  
    &.ant-btn-primary {
      background: ${colors.blue2};
    }
  
    &.ant-btn-default {
      color: ${colors.gray8};
      background: ${colors.gray3};
      font-size: 14px;
    }
    
    &.ant-btn-link {
      color: ${colors.blue2};
      padding: 0;
      
      &:hover {
        color: ${colors.blue2};        
      }
    }
  
    &.ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      color: ${colors.gray7};
      background: ${colors.gray2};
      border-color: ${colors.gray2};
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    
    &.ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
       color: ${colors.gray7};
    }
    
    &.ant-btn-text:hover:not([disabled]) {
      color: inherit!important;
      background-color: transparent!important;
    }
  }
`;

export const GlobalStyles = createGlobalStyle`
  #root {
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  }
  
  button {
    cursor: pointer;
  
    &:focus {
      outline: none;
    }
    
    &:disabled {
      cursor: default;
    }
  }

  a {
    color: ${colors.blue2};

    &:hover {
      color: ${colors.blue2};
    }
  }

  .ant-dropdown {
    box-shadow:
      0px 0px 1px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    
    .ant-dropdown-menu {
      padding: 0;
      border-radius: 5px;
    }

    .ant-dropdown-menu-item {
      &:hover {
        border-radius: 0; 
      }

      .ant-dropdown-menu-title-content {
        white-space: nowrap;
      }
    }
  }
  
  .common-dropdown {
    .ant-dropdown-menu {
      padding: 4px 0;
      border-radius: 5px;
    }
  }
  
  .ant-select-dropdown {
    z-index: 1300!important;
  }
  
  .white-bg {
    &.ant-input, .ant-select-selector {
      background: ${colors.white}!important;
      border-color: transparent!important;
    }
  }
  
  .ant-page-header-heading-extra {
    display: flex;
    align-items: center;
  }
  
  .ant-modal-root {
    .ant-modal-wrap, .ant-modal-mask {
      z-index: 1160;
    }

    .ant-modal-close  {
      top: 17px;
      right: 20px;
      
      &:hover {
        background: none;
      }

      .ant-modal-close-x svg {
        color: ${colors.gray10} 
      }
    }
    
    .photos-slider-modal {
      width: auto!important;
      max-width: 762px;
      margin: 0 20px;

      @media (min-width: ${BREAKPOINTS.MD}px) {
        display: block;
        margin: 0 auto;
      }
      
      .ant-modal-content {
        border-radius: 0;
        padding: 0;
        overflow: visible;
        background-color: transparent;
      }
      
      .ant-modal-body {
        margin-bottom: 0;
      }
      
      .ant-modal-close {
        top: -18px;
        right: -18px;
        width: 36px;
        height: 36px;

        @media (min-width: ${BREAKPOINTS.LG}px) {
          top: -15px;
          right: -65px;
        }
        
        .ant-modal-close-x {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${hexToRGB(colors.gray10, 0.8)};
          transition: background .2s ease-in-out;
          cursor: pointer;
          border-radius: 100%;
          border: 1px solid ${colors.white};
          
          &:hover {
            background: ${colors.gray10};
          }
          
          svg {
            position: static;
            width: 16px;
            height: 16px;
            color: ${colors.white};
          }
        }
      }
    }
  
    .ant-modal-content {
      border-radius: 10px;
      padding: 25px;
      overflow: hidden;
    }
    
    .modal-no-padding {
      .ant-modal-content {
        padding: 0;
      }
    }

    .modal-large {
      top: 50px;
      height: calc(100vh - 100px);
      min-height: 700px;
      max-height: 1200px
    }
    
    .select-user-modal {
      .ant-modal-content {
        padding: 0;
      }
      
      .ant-modal-body {
        margin-bottom: 0;
      }
    }
    
    .ant-modal-header {
      border-bottom: 0;
      padding: 0;
      margin-top: -3px;
    }
    
    .ant-modal-title {
      line-height: 24px;
      margin-bottom: 8px;
    }
    
    .ant-modal-body {
      padding: 0;
      font-size: 14px;
      line-height: 22px;
      color: ${colors.gray10};
      margin-bottom: 25px;
    }
    
    .ant-modal-footer {
      padding: 0;
      text-align: left;
      border: 0;
      border-radius: 0;
    }
    
    .trends-modal {
      .ant-modal-close  {
        top: 11px;
      }
    }
  }
  
  .ant-notification {
    z-index: 1600;
    
    .ant-notification-notice {
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
    
    .ant-notification-close-x {
      & > svg {
        width: 17.6px;
        height: 17.6px;
        color: ${colors.gray10};
      }
    }
    
    .ant-notification-notice-description {
      color: ${colors.gray8};
    }
    
    .ant-notification-notice-icon-success {
      color: ${colors.green};
    }
    
    .ant-notification-notice-icon-error {
      color: ${colors.red};
    }
    
    .ant-notification-notice-icon-info {
      color: ${colors.blue2};
    }
    
    .ant-notification-notice-icon-warning {
      color: ${colors.yellow};
    }
    
    .ant-notification-notice-message, .ant-notification-notice-description {
      margin-left: 43px;
    }
  }
  
  .ant-picker-panel-container {
    border-radius: 5px;
  }
  
  .pac-container { 
    margin-top: 4px;
    border-radius: 5px;
    border: 1px solid ${colors.gray5};
    padding: 8px 0;
    z-index: 2100;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);

    &:after {
      display: none;
    }
  }

  .pac-item {
    font-family: -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 14px;
    color: ${colors.gray10};
    border-top: 0;
    padding: 0 12px;
    
    .pac-icon {
      display: none;
    }
  }
  
  .ant-popover-inner {
    border-radius: 10px;
    overflow: hidden;
  }
  
  .ant-popover {
    z-index: 1200;
  }

  .self-guided-tour-popover {
    .ant-popover-title {
      margin-bottom: 16px;
      border-bottom: 0;
    }
    
    .ant-popover-inner {
      padding: 16px;
    }
  }

  .onboarding-popover {
    width: 215px;

    .ant-popover-inner-content {
      position: relative;
      
      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 165px;
        height: 165px;
        border-radius: 100%;
        background: ${colors.blue3};
      }
    }
    
    &.ant-popover-placement-right {
      & > .ant-popover-content > .ant-popover-arrow {
        border-bottom-color: ${colors.blue2};
        border-left-color: ${colors.blue2};
      }

      .ant-popover-inner-content {
        &:before {
          top: -60px;
          right: -95px;
        }
      }
    }

    &.ant-popover-placement-bottom {
      & > .ant-popover-content > .ant-popover-arrow {
        border-top-color: ${colors.blue2};
        border-left-color: ${colors.blue2};
      }

      .ant-popover-inner-content {
        &:before {
          top: -60px;
          right: -95px;
        }
      }
    }

    &.ant-popover-placement-left {
      & > .ant-popover-content > .ant-popover-arrow {
        border-top-color: ${colors.blue2};
        border-right-color: ${colors.blue2};
      }

      .ant-popover-inner-content {
        &:before {
          top: -40px;
          left: -85px;
        }
      }
    }

    &.ant-popover-placement-top {
      & > .ant-popover-content > .ant-popover-arrow {
        border-bottom-color: ${colors.blue2};
        border-right-color: ${colors.blue2};
      }

      .ant-popover-inner-content {
        &:before {
          top: -40px;
          left: -85px;
        }
      }
    }
  }

  // safari 16.4 rendering issue temp fix
  .file-uploader-modal {
    .ant-modal-content {
      box-shadow: none;
    }
  }
  
  ${buttonStyles}
  
  .spin-no-blur {
    .ant-spin-blur {
      opacity: 0;
    }
  }
  
  .details-modal {
    top: 50px;
  }
  
  .pie-chart-cell-with-transition {
    transition: all .3s ease-in-out;
  }

  #chat-widget-container {
    z-index: 11!important;
  }
  
  .help-center-popover .ant-popover-inner {
    padding: 0;
  }
  
  .ant-color-picker-slider-hue {
    margin-bottom: 6px!important;
    margin-top: 10px!important;
  }

  .ant-color-picker-slider-alpha {
    height: 5px;
    background-image: none!important;
    
    & > * {
      display: none;
    }
  }

  .ant-color-picker-alpha-input {
    display: none;
  }
  
  .show-more-link {
    color: ${colors.blue2};
    cursor: pointer;
  }
  
  .export-template-pdf-modal-spin {
    .ant-spin-dot {
      margin-top: -50px!important;
    }
    
    .ant-spin-text {
      top: 40%!important;;
    }
  }
  
  .reverse-radio-group {
    .ant-radio-wrapper {
      flex-direction: row-reverse;
      width: 100%;
      margin-right: 0;
      
      span.ant-radio {
        margin-left: 10px;
      }

      span.ant-radio+* {
        padding-left: 0;
        flex-grow: 1;
      }
    }
  }
  
  .break-all {
    word-break: break-all;
  }
`;
