import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Space from 'antd/es/space';

import { actionsActions, actionsSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { getInitialActionsFilters } from '@application/Actions/store/actionsReducer';

import SearchInput from '@components/shared/SeachInput/SearchInput';
import PageHeader from '@components/shared/ant/PageHeader';
import FiltersButton from '@components/shared/FiltersButton/FiltersButton';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';
import PendingActionStatusSelect from '@src/presentation/Actions/ActionsFiltersModal/PendingActionStatusSelect';
import ExportButton from './ExportButton';

interface IProps {
  page: ActionsPage;
}

const ActionsHeader: React.FC<React.PropsWithChildren<IProps>> = ({ page }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const filters = useSelector(actionsSelectors.getFilters);
  const currentPage = useSelector(actionsSelectors.getPage);
  const selectedFiltersQty = useSelector(
    actionsSelectors.getSelectedFiltersQty
  );

  return (
    <PageHeader
      title={formatMessage({ id: 'CorrectiveActions' })}
      extra={
        <Space size={15}>
          <SearchInput
            value={filters.search}
            onEnter={(search: string) =>
              dispatch(actionsActions.getActions({ search, pageNumber: 1 }))
            }
          />
          {currentPage === ActionsPage.Pending && (
            <PendingActionStatusSelect
              value={filters.status === null ? -1 : filters.status}
              onChange={(status) =>
                dispatch(
                  actionsActions.getActions({
                    pageNumber: 1,
                    status: status === -1 ? null : status,
                  })
                )
              }
              width="175px"
            />
          )}
          <FiltersButton
            showModal={() => {
              dispatch(actionsActions.toggleFiltersModal(true));
            }}
            selectedFilters={selectedFiltersQty}
            resetFilters={() => {
              const { status, search, ...filters } = getInitialActionsFilters();
              dispatch(actionsActions.getActions(filters));
            }}
          />
          <ExportButton />
        </Space>
      }
      footer={
        <PageTabbedSwitcher<ActionsPage>
          activePage={page}
          pages={[
            {
              id: ActionsPage.Pending,
              name: formatMessage({ id: 'Pending' }),
              onClick: () => navigate(routes.pendingActions),
            },
            {
              id: ActionsPage.Completed,
              name: formatMessage({ id: 'Archive' }),
              onClick: () => navigate(routes.completedActions),
            },
            {
              id: ActionsPage.Expired,
              name: formatMessage({ id: 'Expired' }),
              onClick: () => navigate(routes.expiredActions),
            },
          ]}
        />
      }
    />
  );
};

export default ActionsHeader;
