import { createReducer, Reducer } from '@reduxjs/toolkit';

import { IAuditObjectsFilters } from '@repo/shared/types';
import { IAuditObjectsState } from '@types';
import { auditObjectsActions as actions } from './auditObjects.actions';
import { getInitialFilters } from '@utils';
import {
  attributesAdapter,
  attributesAssignmentsAdapter,
  auditObjectGroupAssignmentAdapter,
  auditObjectsAdapter,
  groupAdapter,
} from '@store/entityAdapters';

export function getInitialAuditObjectsFilters(): IAuditObjectsFilters {
  return {
    ...getInitialFilters(),
    ungrouped: false,
    auditObjectGroupIds: [],
    auditObjectAttributeId: null,
    auditObjectAttributeOptionId: null,
  };
}

export const auditObjectsReducer: Reducer<IAuditObjectsState> =
  createReducer<IAuditObjectsState>(
    {
      objects: {
        data: auditObjectsAdapter.getInitialState(),
        loading: false,
        meta: null,
        error: null,
        filters: {
          ...getInitialAuditObjectsFilters(),
        },
        showFiltersModal: false,
        addEditAuditObjectModal: {
          show: false,
          data: null,
        },
      },
      object: {
        data: null,
        loading: false,
        error: null,
      },
      groups: {
        data: groupAdapter.getInitialState(),
        meta: null,
        loading: false,
        error: null,
        filters: {
          ...getInitialFilters(),
        },
        addEditGroupModal: {
          visible: false,
          groupId: null,
        },
        deleteCandidatesIds: [],
      },
      groupAssignments: {
        data: auditObjectGroupAssignmentAdapter.getInitialState(),
        loading: false,
        meta: null,
        error: null,
        filters: {
          ...getInitialFilters(),
          auditObjectIds: [],
          auditObjectGroupIds: [],
        },
        showFiltersModal: false,
        showAddToGroupModal: false,
        deleteCandidatesIds: [],
      },
      attributesAssignments: {
        data: attributesAssignmentsAdapter.getInitialState(),
        loading: false,
        meta: null,
        error: null,
        filters: {
          ...getInitialFilters(),
          auditObjectId: null,
          attributeId: null,
          optionId: null,
        },
        showFiltersModal: false,
        showAddAttributeAssignmentModal: false,
        deleteCandidatesIds: [],
      },
      attributes: {
        data: attributesAdapter.getInitialState(),
        loading: false,
        meta: null,
        error: null,
        filters: {
          ...getInitialFilters(),
        },
        addEditAttributeModal: {
          show: false,
          attributeId: null,
        },
        deleteCandidatesIds: [],
      },
    },
    (builder) =>
      builder
        .addCase(
          actions.objects.getData.pending,
          (state, { meta: { arg } }) => {
            state.objects.loading = true;
            state.objects.error = null;
            state.objects.filters = {
              ...state.objects.filters,
              ...(arg || {}),
            };
          }
        )
        .addCase(actions.objects.getData.fulfilled, (state, action) => {
          state.objects.loading = false;
          state.objects.meta = action.payload.meta;
          auditObjectsAdapter.setAll(state.objects.data, action.payload.data);
        })
        .addCase(actions.objects.getData.rejected, (state, action) => {
          state.objects.loading = false;
          state.objects.error = action.payload as string;
        })
        .addCase(actions.groups.getData.pending, (state, { meta: { arg } }) => {
          state.groups.loading = true;
          state.groups.error = null;
          state.groups.filters = {
            ...state.groups.filters,
            ...(arg || {}),
          };
        })
        .addCase(actions.groups.getData.fulfilled, (state, { payload }) => {
          groupAdapter.setAll(state.groups.data, payload.data);
          state.groups.meta = payload.meta;
          state.groups.loading = false;
        })
        .addCase(actions.groups.getData.rejected, (state, { payload }) => {
          if (payload) {
            state.groups.error = payload;
          }

          state.groups.loading = false;
        })
        .addCase(
          actions.groups.toggleAddEditModal,
          (state, { payload: { visible, groupId } }) => {
            state.groups.addEditGroupModal.visible = visible;
            state.groups.addEditGroupModal.groupId = groupId || null;
          }
        )
        .addCase(
          actions.groups.toggleConfirmDeleteModal,
          (state, { payload }) => {
            state.groups.deleteCandidatesIds = payload;
          }
        )
        .addCase(actions.objects.showAddEditModal, (state, { payload }) => {
          state.objects.addEditAuditObjectModal.show = true;
          state.objects.addEditAuditObjectModal.data = payload || null;
        })
        .addCase(actions.objects.hideAddEditModal, (state) => {
          state.objects.addEditAuditObjectModal = {
            show: false,
            data: null,
          };
        })
        .addCase(
          actions.attributesAssignments.getData.pending,
          (state, { meta: { arg } }) => {
            state.attributesAssignments.loading = true;
            state.attributesAssignments.error = null;
            state.attributesAssignments.filters = {
              ...state.attributesAssignments.filters,
              ...(arg || {}),
            };
          }
        )
        .addCase(
          actions.attributesAssignments.getData.fulfilled,
          (state, { payload }) => {
            attributesAssignmentsAdapter.setAll(
              state.attributesAssignments.data,
              payload.data
            );
            state.attributesAssignments.meta = payload.meta;
            state.attributesAssignments.loading = false;
          }
        )
        .addCase(
          actions.attributesAssignments.getData.rejected,
          (state, { payload }) => {
            if (payload) {
              state.attributesAssignments.error = payload;
            }

            state.attributesAssignments.loading = false;
          }
        )
        .addCase(
          actions.attributesAssignments.toggleAddModal,
          (state, { payload }) => {
            state.attributesAssignments.showAddAttributeAssignmentModal =
              payload;
          }
        )
        .addCase(
          actions.attributesAssignments.toggleConfirmDeleteModal,
          (state, { payload }) => {
            state.attributesAssignments.deleteCandidatesIds = payload;
          }
        )
        .addCase(
          actions.attributesAssignments.toggleFiltersModal,
          (state, { payload }) => {
            state.attributesAssignments.showFiltersModal = payload;
          }
        )
        .addCase(
          actions.groupAssignments.getData.pending,
          (state, { meta: { arg } }) => {
            state.groupAssignments.loading = true;
            state.groupAssignments.error = null;
            state.groupAssignments.filters = {
              ...state.groupAssignments.filters,
              ...(arg || {}),
            };
          }
        )
        .addCase(
          actions.groupAssignments.getData.fulfilled,
          (state, { payload }) => {
            auditObjectGroupAssignmentAdapter.setAll(
              state.groupAssignments.data,
              payload.data
            );
            state.groupAssignments.meta = payload.meta;
            state.groupAssignments.loading = false;
          }
        )
        .addCase(
          actions.groupAssignments.getData.rejected,
          (state, { payload }) => {
            if (payload) {
              state.groupAssignments.error = payload;
            }

            state.groupAssignments.loading = false;
          }
        )
        .addCase(
          actions.groupAssignments.toggleFiltersModal,
          (state, { payload }) => {
            state.groupAssignments.showFiltersModal = payload;
          }
        )
        .addCase(
          actions.groupAssignments.toggleAddToGroupModal,
          (state, { payload }) => {
            state.groupAssignments.showAddToGroupModal = payload;
          }
        )
        .addCase(
          actions.groupAssignments.toggleConfirmDeleteModal,
          (state, { payload }) => {
            state.groupAssignments.deleteCandidatesIds = payload;
          }
        )
        .addCase(
          actions.attributes.getData.pending,
          (state, { meta: { arg } }) => {
            state.attributes.loading = true;
            state.attributes.error = null;
            state.attributes.filters = {
              ...state.attributes.filters,
              ...(arg || {}),
            };
          }
        )
        .addCase(actions.attributes.getData.fulfilled, (state, { payload }) => {
          attributesAdapter.setAll(state.attributes.data, payload.data);
          state.attributes.meta = payload.meta;
          state.attributes.loading = false;
        })
        .addCase(actions.attributes.getData.rejected, (state, { payload }) => {
          if (payload) {
            state.attributes.error = payload;
          }

          state.attributes.loading = false;
        })
        .addCase(
          actions.attributes.toggleAddEditModal,
          (state, { payload }) => {
            state.attributes.addEditAttributeModal.show = payload.show;
            state.attributes.addEditAttributeModal.attributeId =
              payload.attributeId || null;
          }
        )
        .addCase(
          actions.attributes.toggleConfirmDeleteModal,
          (state, { payload }) => {
            state.attributes.deleteCandidatesIds = payload;
          }
        )
        .addCase(actions.objects.getObjectDetails.pending, (state) => {
          state.object.loading = true;
          state.object.data = null;
        })
        .addCase(
          actions.objects.getObjectDetails.fulfilled,
          (state, { payload }) => {
            state.object.loading = false;
            state.object.data = payload;
          }
        )
        .addCase(
          actions.objects.getObjectDetails.rejected,
          (state, { payload }) => {
            state.object.loading = false;
            state.object.error = payload || null;
          }
        )
        .addCase(actions.objects.updateObjectDetails, (state, { payload }) => {
          state.object.data = payload;
        })
        .addCase(actions.objects.toggleFiltersModal, (state, { payload }) => {
          state.objects.showFiltersModal = payload;
        })
  );
