import React, { useEffect, useState, Suspense, lazy } from 'react';
import AntModal from 'antd/es/modal';
import { X } from 'react-feather';

import { Container, Content } from './styled';
import { useOpacityTransition } from '@repo/shared/hooks/misc';
import { ImageGalleryMode } from '@repo/shared/enums';
import { FileMetadata, IApiService } from '@repo/shared/types';

import Loader from '@repo/shared/components/Loader';
const FileSlider = lazy(() => import('./FileSlider/FileSlider'));
const ImageCrop = lazy(() => import('./ImageCrop/ImageCrop'));
const ImageDraw = lazy(() => import('./ImageDraw/ImageDraw'));

interface IProps {
  files: FileMetadata[];
  getFilePath?: (file: FileMetadata) => string;
  getMetadataPath?: (fileId: string) => string;
  initialFileId: string | null;
  onClose: () => void;
  onUpdate?: (file: FileMetadata, index: number) => Promise<boolean>;
  apiService: IApiService;
  companyId?: string;
  skipCompanyId?: boolean;
}

const FileGallery: React.FC<React.PropsWithChildren<IProps>> = ({
  files,
  getFilePath,
  getMetadataPath,
  initialFileId,
  onClose,
  onUpdate,
  apiService,
  skipCompanyId,
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(-1);
  const [mode, setMode] = useState<ImageGalleryMode>(ImageGalleryMode.Slider);

  useEffect(() => {
    if (initialFileId) {
      setCurrentSlideIndex(files.findIndex(({ id }) => id === initialFileId));
    }
  }, [initialFileId]);

  const transition = useOpacityTransition(mode);

  return (
    <AntModal
      open={!!initialFileId}
      onCancel={() => {
        onClose();
      }}
      className="photos-slider-modal"
      footer={null}
      closeIcon={<X />}
      zIndex={2000}
      destroyOnClose
    >
      <Container>
        {transition.map(({ item, key, props }) => {
          if (item === ImageGalleryMode.Crop) {
            return (
              <Content key={key} style={props}>
                <Suspense fallback={<Loader />}>
                  <ImageCrop
                    file={files[currentSlideIndex]}
                    getFilePath={
                      getFilePath
                        ? () => getFilePath(files[currentSlideIndex])
                        : undefined
                    }
                    getMetadataPath={getMetadataPath}
                    onCancelClick={() => {
                      setMode(ImageGalleryMode.Slider);
                    }}
                    onUpdate={async (file) => {
                      if (!onUpdate) {
                        return;
                      }

                      if (await onUpdate(file, currentSlideIndex)) {
                        setMode(ImageGalleryMode.Slider);
                      }
                    }}
                    apiService={apiService}
                  />
                </Suspense>
              </Content>
            );
          }

          if (item === ImageGalleryMode.Draw) {
            return (
              <Content key={key} style={props}>
                <Suspense fallback={<Loader />}>
                  <ImageDraw
                    file={files[currentSlideIndex]}
                    getFilePath={getFilePath}
                    getMetadataPath={getMetadataPath}
                    onCancelClick={() => {
                      setMode(ImageGalleryMode.Slider);
                    }}
                    onUpdate={async (file) => {
                      if (!onUpdate) {
                        return;
                      }

                      if (await onUpdate(file, currentSlideIndex)) {
                        setMode(ImageGalleryMode.Slider);
                      }
                    }}
                    apiService={apiService}
                  />
                </Suspense>
              </Content>
            );
          }

          return (
            <Content key={key} style={props}>
              <Suspense fallback={<Loader />}>
                <FileSlider
                  files={files}
                  getFilePath={getFilePath}
                  getMetadataPath={getMetadataPath}
                  apiService={apiService}
                  skipCompanyId={skipCompanyId}
                  currentSlideIndex={currentSlideIndex}
                  onSlideChange={(index) => {
                    setCurrentSlideIndex(index);
                  }}
                  onModeChange={(newMode) => {
                    setMode(newMode);
                  }}
                  readonly={!onUpdate}
                />
              </Suspense>
            </Content>
          );
        })}
      </Container>
    </AntModal>
  );
};

export default FileGallery;
