import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { auditsActions } from '@application/Audits/store/auditsActions';
import { actionsActions } from '@application/Actions/store/actionsActions';
import { useAppDispatch, usePermission } from '@hooks';
import { issuesSelectors } from '@application/Issues/store';
import { IssueDetails } from '@domain/Issues/models/Issue';
import { Permission } from '@repo/shared/enums';
import { getRoutesFromActionStatus } from '@application/Actions/utils/getRoutesFromActionStatus';
import { getRoutesFromAuditStatus } from '@application/Audits/utils/getRoutesFromAuditStatus';

import IssueLinkedItemsList from '@src/presentation/Issues/IssueDetails/IssueDetailsForm/LinkedEntities/IssueLinkedItemsList';
import IssueDetailsCreateAuditModal from '@src/presentation/Issues/IssueDetails/IssueDetailsForm/IssueDetailsCreateAuditModal';
import IssueDetailsCreateActionModal from '@src/presentation/Issues/IssueDetails/IssueDetailsForm/IssueDetailsCreateActionModal';
import AuditIcon from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvent/IssueDetailsEventData/IssueDetailsEventActionAudit/AuditIcon';
import ActionIcon from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvent/IssueDetailsEventData/IssueDetailsEventActionAudit/ActionIcon';
import LinkedAuditBadge from '@src/presentation/Issues/IssueDetails/IssueDetailsForm/LinkedEntities/LinkedItemBadge/LinkedAuditBadge';
import LinkedActionBadge from '@src/presentation/Issues/IssueDetails/IssueDetailsForm/LinkedEntities/LinkedItemBadge/LinkedActionBadge';

interface IProps {
  updateIssue: (update: Partial<IssueDetails>) => void;
}

enum CreateModalType {
  Audit,
  Action,
}

const IssueLinkedItems: React.FC<React.PropsWithChildren<IProps>> = ({
  updateIssue,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [modal, setModal] = useState<CreateModalType | null>(null);

  const issueAudits = useSelector(issuesSelectors.getIssueDetailsAudits);
  const issueActions = useSelector(issuesSelectors.getIssueDetailsActions);
  const issue = useSelector(issuesSelectors.getDetailsIssue);

  const [
    canEditIssues,
    canViewActions,
    canDoAudits,
    canViewAuditResults,
    canAssignActions,
    canScheduleAudits,
    canManageActions,
  ] = usePermission([
    Permission.CanEditIssues,
    Permission.CanViewCorrectiveActions,
    Permission.CanDoAudits,
    Permission.CanViewAuditResults,
    Permission.CanAssignCorrectiveActions,
    Permission.CanScheduleAudits,
    Permission.CanManageCorrectiveActions,
  ]);

  function closeModals() {
    dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
    dispatch(actionsActions.toggleActionDetailsModal(null));
    setModal(null);
  }

  useEffect(() => {
    return () => {
      closeModals();
    };
  }, []);

  if (!issue) {
    return null;
  }

  return (
    <>
      <IssueLinkedItemsList
        header={formatMessage({ id: 'LinkedAudits' })}
        addBtnLabel={`+ ${formatMessage({ id: 'Audit' })}`}
        itemIcon={<AuditIcon />}
        items={issueAudits}
        canAddItem={canEditIssues && canScheduleAudits}
        getItemRoute={(audit) =>
          getRoutesFromAuditStatus(audit.status).single.replace(':id', audit.id)
        }
        onAddClick={() => {
          closeModals();

          setModal(CreateModalType.Audit);
        }}
        canOpenLinkedItem={canDoAudits || canViewAuditResults}
        getItemExtra={(audit) => {
          return <LinkedAuditBadge audit={audit} />;
        }}
      />
      <IssueLinkedItemsList
        header={formatMessage({ id: 'LinkedCorrectiveActions' })}
        addBtnLabel={`+ ${formatMessage({ id: 'CorrectiveAction' })}`}
        itemIcon={<ActionIcon />}
        items={issueActions}
        canAddItem={canEditIssues && (canAssignActions || canManageActions)}
        getItemRoute={(action) =>
          getRoutesFromActionStatus(action.status).single.replace(
            ':id',
            action.id
          )
        }
        onAddClick={() => {
          closeModals();

          setModal(CreateModalType.Action);
        }}
        canOpenLinkedItem={canViewActions}
        getItemExtra={(action) => {
          return <LinkedActionBadge action={action} />;
        }}
      />
      <IssueDetailsCreateAuditModal
        opened={modal === CreateModalType.Audit}
        onCancel={() => {
          closeModals();
        }}
        onSave={async (item) => {
          updateIssue({
            audits: [...issueAudits, item],
          });

          closeModals();
        }}
        onDelete={(itemId) => {
          updateIssue({
            audits: issueAudits.filter(({ id }) => id !== itemId),
          });
        }}
        auditObjectId={issue.auditObject.id}
      />
      <IssueDetailsCreateActionModal
        opened={modal === CreateModalType.Action}
        onCancel={() => {
          closeModals();
        }}
        issueId={issue.id}
        onSave={async (item) => {
          updateIssue({
            correctiveActions: [...issueActions, item],
          });

          closeModals();
        }}
        onDelete={(itemId) => {
          updateIssue({
            correctiveActions: issueActions.filter(({ id }) => id !== itemId),
          });
        }}
        auditObjectId={issue.auditObject.id}
      />
    </>
  );
};

export default IssueLinkedItems;
