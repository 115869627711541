import React, { forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { issueTypesSelectors } from '@application/IssueTypes/store';
import {
  IExposedFormRef,
  useExposedForm,
} from '@application/Issues/hooks/useExposedForm';
import { IssueQuestion } from '@domain/Issues/models/IssueQuestion';
import { issuesSelectors } from '@application/Issues/store';
import {FileMetadata} from '@repo/shared/types';

import EntitySelect from '@components/shared/EntitySelects/EntitySelect';
import Form from '@components/shared/ant/Form';
import IssueQuestionFormItem from '@src/presentation/Issues/IssueQuestionsForm/IssueTypeFieldFormItem/IssueQuestionFormItem';

interface IProps {
  getFilePath?: (file: FileMetadata, size?: number) => string;
  getFileUploadPath?: (fileId: string) => string;
  getFileMetadataPath?: (fileId: string) => string;
}

type FormValues = {
  auditObjectId: string | null;
  questions: IssueQuestion[];
};

const IssueQuestionsForm = forwardRef<
  IExposedFormRef<FormValues>,
  React.PropsWithChildren<IProps>
>(({ getFilePath, getFileUploadPath, getFileMetadataPath }, ref) => {
  const { formatMessage } = useIntl();

  const { loading, data } = useSelector(
    issueTypesSelectors.getIssueTypeDetails
  );

  const { contextAuditObjects, auditObjectName } = useSelector(
    issuesSelectors.getIssueContext
  );

  const form = useExposedForm<FormValues>(ref);

  useEffect(() => {
    if (!loading && data) {
      form.setFieldsValue({
        auditObjectId: null,
        questions: data.fields.map((field) => ({
          ...field,
          files: [],
          answer: {
            text: '',
          },
        })),
      });
    }
  }, [loading, JSON.stringify(data)]);

  return (
    <Form<FormValues>
      form={form}
      layout="vertical"
      initialValues={{
        auditObjectId: null,
        questions: [],
      }}
    >
      {contextAuditObjects.length > 1 && (
        <Form.Item
          name="auditObjectId"
          label={auditObjectName}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <EntitySelect
            placeholder={formatMessage(
              { id: 'SelectAuditObject' },
              { objectName: auditObjectName }
            )}
            options={contextAuditObjects}
            loading={loading}
          />
        </Form.Item>
      )}
      <Form.List name="questions">
        {(fields) => (
          <>
            {fields.map((field, index) => (
              <IssueQuestionFormItem
                key={field.key}
                index={index}
                question={form.getFieldValue(['questions', index])}
                getFilePath={getFilePath}
                getFileUploadPath={getFileUploadPath}
                getFileMetadataPath={getFileMetadataPath}
              />
            ))}
          </>
        )}
      </Form.List>
    </Form>
  );
});

export default IssueQuestionsForm;
