import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import { ListContainer, List, Description } from './styled';
import { AssigneesModalContext } from '@repo/shared/enums';
import { IConcise } from '@repo/shared/types';

import LibraryModal from '@components/shared/ant/Modal/Modal';
import Assignees from '@components/shared/Assignees/Assignees';
import Scrollbar from '@components/shared/Scrollbar/Scrollbar';

interface IProps<T> {
  opened: boolean;
  title: string;
  description: string;
  entitiesIds: string[];
  entitiesMap: Record<string, T>;
  formatEntityName: (entity: T) => string;
  onAssignClick: (usersIds: string[]) => Promise<void>;
  onCancel: () => void;
  auditObjectId?: string;
  assigneesContext: AssigneesModalContext;
}

function BulkUsersAssignmentModal<T>({
  opened,
  title,
  description,
  entitiesIds,
  entitiesMap,
  formatEntityName,
  onAssignClick,
  onCancel,
  auditObjectId,
  assigneesContext,
}: React.PropsWithChildren<IProps<T>>) {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [users, selectUsers] = useState<IConcise[]>([]);

  useEffect(() => {
    if (opened) {
      selectUsers([]);
    }
  }, [opened]);

  return (
    <LibraryModal
      title={title}
      open={opened}
      width={480}
      onOk={async () => {
        setLoading(true);
        await onAssignClick(users.map(({ id }) => id));
        setLoading(false);
      }}
      okText={formatMessage({
        id: 'Save',
      })}
      confirmLoading={loading}
      onCancel={onCancel}
      closeIcon={<X />}
    >
      <Description>{description}</Description>
      <ListContainer>
        <div
          style={{
            height: (entitiesIds.length < 5 ? entitiesIds.length : 5) * 22,
          }}
        >
          <Scrollbar>
            <List>
              {entitiesIds.map((entityId) => {
                const entity = entitiesMap[entityId];

                if (!entity) {
                  return null;
                }

                return <li key={entityId}>{formatEntityName(entity)}</li>;
              })}
            </List>
          </Scrollbar>
        </div>
      </ListContainer>
      <Description>
        {formatMessage({ id: 'PleaseClickOnTheButtonBelow' })}:
      </Description>
      <Assignees
        context={assigneesContext}
        onChange={async (users) => selectUsers(users)}
        auditObjectId={auditObjectId}
        users={users}
      />
    </LibraryModal>
  );
}

export default BulkUsersAssignmentModal;
