import React, { useRef, useMemo, useState } from 'react';
import Scroller from 'react-indiana-drag-scroll';

import { ImagesContainer, ScrollContainer, FilesContainer } from './styled';
import { FileMetadata, IApiService } from '@repo/shared/types';
import { FileSourceType, ThumbSize } from '@repo/shared/enums';
import { InternalApiService } from '@repo/shared/api';

import ImagePreview from '@repo/shared/components/Previews/ImagePreview/ImagePreview';
import FilePreview from './FilePreview/FilePreview';
import FileGallery from './FileGallery/FileGallery';

interface IProps {
  files: FileMetadata[];
  getFilePath?: (file: FileMetadata, size?: number) => string;
  getMetadataPath?: (fileId: string) => string;
  thumbSize?: ThumbSize;
  onUpdate?: (file: FileMetadata, index: number) => Promise<boolean>;
  onRemove?: (id: string) => void;
  mode?: 'grid' | 'row';
  id?: string;
  fileSourceType?: FileSourceType;
  fileUrlPrefix?: string;
  containerStyle?: React.CSSProperties;
  imagePreviewStyle?: React.CSSProperties;
  imagePreviewContainerStyle?: React.CSSProperties;
  apiService?: IApiService;
  skipCompanyId?: boolean;
  onConflictError?: (error: string) => boolean;
}

const Previews: React.FC<React.PropsWithChildren<IProps>> = ({
  files,
  getFilePath,
  getMetadataPath,
  thumbSize = ThumbSize.md,
  onRemove,
  mode = 'row',
  id,
  containerStyle,
  imagePreviewStyle,
  imagePreviewContainerStyle,
  apiService = InternalApiService.getInstance(),
  skipCompanyId,
  onUpdate,
}) => {
  const container = useRef<HTMLDivElement | null>(null);

  const [showGalleryId, setShowGalleryId] = useState<string | null>(null);

  const { media, other } = useMemo(
    () =>
      files.reduce<{ media: FileMetadata[]; other: FileMetadata[] }>(
        (acc, file) => {
          if (
            file.contentType === null ||
            file.contentType.includes('image') ||
            file.contentType.includes('video')
          ) {
            acc.media.push(file);
          } else {
            acc.other.push(file);
          }

          return acc;
        },
        { media: [], other: [] }
      ),
    [JSON.stringify(files), id]
  );

  const previewSize = thumbSize * 2;

  const imagePreviews = useMemo(() => {
    return (
      <ImagesContainer mode={mode}>
        {media.map((file) => {
          return (
            <ImagePreview
              key={file.id}
              fileId={file.id}
              getFilePath={
                getFilePath
                  ? () => getFilePath(file, previewSize)
                  : () =>
                      file.contentType?.includes('video')
                        ? `files/${file.id}/video-preview`
                        : `files/${file.id}/resized?size=${previewSize}`
              }
              onClick={(id) => {
                setShowGalleryId(id);
              }}
              skipCompanyId={skipCompanyId}
              apiService={apiService}
              imagePreviewStyle={{
                height: `${thumbSize}px`,
                width: `${thumbSize}px`,
                borderRadius: '5px',
                ...(imagePreviewStyle || {}),
              }}
              imagePreviewContainerStyle={imagePreviewContainerStyle}
              getMetadataPath={getMetadataPath}
              onRemove={
                onRemove
                  ? () => {
                      onRemove(file.id);
                    }
                  : undefined
              }
            />
          );
        })}
      </ImagesContainer>
    );
  }, [mode, JSON.stringify(media), id]);

  if (files.length === 0) {
    return null;
  }

  return (
    <div style={containerStyle}>
      {mode === 'row' ? (
        <ScrollContainer visibleShadow={files.length > 7}>
          <Scroller innerRef={container}>{imagePreviews}</Scroller>
        </ScrollContainer>
      ) : (
        imagePreviews
      )}
      {other.length > 0 && (
        <FilesContainer>
          {other.map((file) => (
            <FilePreview
              key={file.id}
              file={file}
              skipCompanyId={skipCompanyId}
              onRemove={onRemove}
              apiService={apiService}
            />
          ))}
        </FilesContainer>
      )}
      <FileGallery
        files={media}
        apiService={apiService}
        onClose={() => {
          setShowGalleryId(null);
        }}
        getFilePath={getFilePath}
        onUpdate={onUpdate}
        initialFileId={showGalleryId}
        skipCompanyId={skipCompanyId}
      />
    </div>
  );
};

export default Previews;
