import React from 'react';
import { useIntl } from 'react-intl';

import { IssueClosedEvent } from '@domain/Issues/models/IssueEvent';
import { ClosedBadge } from './styled';

import IssueCloseReasonName from '@src/presentation/Issues/IssueDetails/IssueCloseReasonName';
import IssueDetailsEventCommentText from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvent/IssueDetailsEventData/IssueDetailsEventCommentText/IssueDetailsEventCommentText';

interface IProps {
  event: IssueClosedEvent;
}

const IssueDetailsClosedEvent: React.FC<React.PropsWithChildren<IProps>> = ({
  event,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <ClosedBadge>{formatMessage({ id: 'Closed' })}</ClosedBadge>
      <div>
        <strong>{formatMessage({ id: 'Reason' })}:</strong>{' '}
        <IssueCloseReasonName reason={event.data.reason} />
      </div>
      {!!event.data.comment && (
        <IssueDetailsEventCommentText text={event.data.comment} />
      )}
    </>
  );
};

export default IssueDetailsClosedEvent;
