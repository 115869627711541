import pluralize from 'pluralize';
import pluralizeFr from 'pluralize-fr';
import pluralizeEs from 'pluralize-es';

import { Locale, UsagePurpose } from '@repo/shared/enums';
import { intl } from '@repo/shared/components/IntlGlobalProvider';

export function getAuditObjectName({
  usagePurpose,
  usagePurposeObjectName,
  locale,
}: {
  usagePurpose: UsagePurpose;
  usagePurposeObjectName: string | null;
  locale: Locale;
}) {
  if (!intl) {
    return {
      single: 'HistoryAudit Object',
      plural: 'HistoryAudit Objects',
    };
  }

  const { formatMessage } = intl;

  const defaultName = formatMessage({ id: 'AuditObject' });

  const objectNames = {
    [UsagePurpose.Locations]: formatMessage({ id: 'Location' }),
    [UsagePurpose.Stores]: formatMessage({ id: 'Store' }),
    [UsagePurpose.Sites]: formatMessage({ id: 'Site' }),
    [UsagePurpose.Machines]: formatMessage({ id: 'Machine' }),
    [UsagePurpose.Plants]: formatMessage({ id: 'Plant' }),
    [UsagePurpose.Vehicles]: formatMessage({ id: 'Vehicle' }),
    [UsagePurpose.Processes]: formatMessage({ id: 'Process' }),
    [UsagePurpose.SomethingElse]: usagePurposeObjectName,
  };

  const single = objectNames[usagePurpose] || defaultName;
  let plural;

  switch (locale) {
    case Locale.fr:
      plural = pluralizeFr(single);
      break;
    case Locale.es:
      plural = pluralizeEs(single);
      break;
    default:
      plural = pluralize(single, 0);
      break;
  }

  return {
    single,
    plural,
  };
}
