import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import {
  usersActions,
  usersSelectors,
  accountSelectors,
  generalSelectors,
} from '@store';
import { Footer } from './styled';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';

import SlideModal from '../../../../shared/SideModal/SideModal';
import Form from '../../../../shared/ant/Form';
import Button from '../../../../shared/ant/Button';
import AuditObjectsSelect from '../../../../shared/EntitySelects/AuditObjectsSelect';
import JobTitlesSelect from '@components/shared/EntitySelects/JobTitlesSelect';
import UsersAndUserGroupsSelect from '@components/shared/EntitySelects/UsersAndUserGroupsSelect';

interface IProps {
  userId?: string;
  auditObjectId?: string;
}

const AssignUsersAccessModal: React.FC<React.PropsWithChildren<IProps>> = ({
  userId,
  auditObjectId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const visible = useSelector(usersSelectors.access.isAssignAccessModalVisible);
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  const closeModal = useCallback(
    () => dispatch(usersActions.access.toggleAssignAccessModal(false)),
    []
  );

  useEffect(() => {
    if (selectedTableRows.length > 0) {
      closeModal();
    }
  }, [selectedTableRows.length]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <SlideModal
      opened={visible}
      onModalClose={() => closeModal()}
      title={formatMessage({
        id: 'AssignAccess',
      })}
      closeBtnE2eDataAttr={e2eTestElements.users.access.closeAssignModal}
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              loading={loading}
              onClick={() => form?.submit()}
              data-e2e={e2eTestElements.users.access.assignBtn}
            >
              {formatMessage({ id: 'Assign' })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                setTimeout(() => {
                  closeModal();
                }, 50);
              }}
              data-e2e={e2eTestElements.users.access.cancelBtn}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
        </Footer>
      }
    >
      <Form
        form={form}
        onFinish={async ({
          assignedTo,
          auditObjectsIds,
          jobTitlesIds,
        }: any) => {
          setLoading(true);

          const update: {
            auditObjectsIds: string[];
            jobTitlesIds: string[];
            usersIds?: string[];
            userGroupsIds?: string[];
          } = {
            auditObjectsIds: !auditObjectId ? auditObjectsIds : [auditObjectId],
            jobTitlesIds,
          };

          if (!userId) {
            update.usersIds = assignedTo.userIds;
            update.userGroupsIds = assignedTo.userGroupIds;
          } else {
            update.usersIds = [userId];
          }

          const resultAction = await dispatch(
            usersActions.access.createAccess(update)
          );

          if (usersActions.access.createAccess.fulfilled.match(resultAction)) {
            dispatch(usersActions.access.getData());
            closeModal();

            notification.success({
              message: formatMessage({ id: 'AccessSuccessfullyCreated' }),
            });
          } else {
            notification.error({
              message: formatMessage({ id: 'ErrorWhileAssigningAccess' }),
            });
          }

          setLoading(false);
        }}
        layout="vertical"
        initialValues={{
          assignedTo: {
            userIds: [],
            userGroupIds: [],
          },
          jobTitlesIds: [],
          auditObjectsIds: [],
        }}
      >
        {!userId && (
          <Form.Item
            name="assignedTo"
            label={formatMessage({ id: 'UserOrUserGroups' })}
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  const assignedTo = getFieldValue('assignedTo');

                  if (
                    assignedTo?.userIds.length === 0 &&
                    assignedTo.userGroupIds.length === 0
                  ) {
                    return Promise.reject(
                      formatMessage({ id: 'RequiredField' })
                    );
                  }

                  return Promise.resolve();
                },
              }),
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
          >
            <UsersAndUserGroupsSelect
              placeholder={formatMessage({ id: 'SelectUserOrUsersGroups' })}
              e2eDataAttr={e2eTestElements.users.access.selectUsersDropdown}
              mode="multiple"
            />
          </Form.Item>
        )}
        <Form.Item
          name="jobTitlesIds"
          label={formatMessage({ id: 'JobTitles' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <JobTitlesSelect
            disabled={loading}
            placeholder={formatMessage({ id: 'SelectJobTitles' })}
            e2eDataAttr={e2eTestElements.users.access.selectJobTitleDropdown}
            mode="multiple"
          />
        </Form.Item>
        {!auditObjectId && (
          <Form.Item
            name="auditObjectsIds"
            label={auditObjectName.plural}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
          >
            <AuditObjectsSelect
              disabled={loading}
              mode="multiple"
              placeholder={formatMessage(
                { id: 'SelectAuditObjects' },
                { objectName: auditObjectName.plural.toLowerCase() }
              )}
              e2eDataAttr={e2eTestElements.users.access.selectObjectsDropdown}
            />
          </Form.Item>
        )}
      </Form>
    </SlideModal>
  );
};

export default AssignUsersAccessModal;
