import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormInstance } from 'antd/es/form';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAppDispatch } from '@hooks';
import {
  accountActions,
  accountSelectors,
  generalActions,
  generalSelectors,
} from '@store';
import { getSystemTimeZone, notification } from '@utils';
import { routes } from '@config';
import { ITimeZone } from '@repo/shared/types';
import { AnalyticsEvent } from '@repo/shared/enums';

import CompanyForm from '@components/account/CompanyForm';
import Form from '../../../shared/ant/Form';

interface IProps {
  children?: (p: { loading: boolean; form: FormInstance }) => React.ReactNode;
  templateId?: string | null;
}

const SetupCompanyForm: React.FC<IProps> = ({ children, templateId }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(generalActions.getTimeZones());
  }, [dispatch]);

  const [loading, setLoading] = useState(false);
  const { loading: timezonesLoading, data: timezones } = useSelector(
    generalSelectors.getTimeZones
  );
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const defaultIanaTimeZone = useMemo(() => {
    const systemTimeZone = getSystemTimeZone();

    if (
      !timezonesLoading &&
      timezones.find((tz: ITimeZone) => tz.ianaName === systemTimeZone)
    ) {
      return systemTimeZone;
    }

    return null;
  }, [timezonesLoading]);

  return (
    <>
      <CompanyForm
        form={form}
        showAdminFields
        initialValues={{
          fullName: '',
          phone: '',
          name: '',
          companySize: null,
          industry: null,
          usagePurpose: null,
          usagePurposeObjectName: '',
          defaultIanaTimeZone,
        }}
        onSubmit={async ({
          fullName,
          phone,
          name,
          companySize,
          industry,
          usagePurpose,
          usagePurposeObjectName,
          defaultIanaTimeZone,
        }) => {
          setLoading(true);

          const resultAction = await dispatch(
            accountActions.createCompany({
              admin: {
                fullName,
                phone,
              },
              name,
              companySize,
              industry,
              usagePurpose,
              usagePurposeObjectName,
              defaultIanaTimeZone,
              templateId,
            })
          );

          if (accountActions.createCompany.fulfilled.match(resultAction)) {
            if (currentUser === null) {
              await dispatch(accountActions.getAppData());
              navigate(routes.dashboard, { state: { fromCompanySetup: true } });
            } else {
              const { companyId } = unwrapResult(resultAction);
              dispatch(accountActions.toggleSetupCompanyModal(false));
              await dispatch(
                accountActions.changeAccount({
                  companyId,
                  fromCompanySetup: true,
                })
              );
            }

            dispatch(
              accountActions.sendAnalyticsEvent(AnalyticsEvent.SetupCompany)
            );
          } else {
            notification.error({
              message: formatMessage({ id: 'ErrorWhileSettingUpCompany' }),
              description: formatMessage({
                id: 'PleaseTryAgainOrContactSupport',
              }),
            });

            setLoading(false);
          }
        }}
      />
      {!!children && children({ loading, form })}
    </>
  );
};

export default SetupCompanyForm;
