import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  flex-grow: 1;
  margin-bottom: 60px;
`;

export const Disclaimer = styled.div`
  color: ${colors.gray8};
  font-size: 12px;
  margin-bottom: 24px;
`;

export const GroupName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 740px;
  padding: 16px 0;
`;

export const NoAudits = styled.div`
  margin-top: 55px;
`;
