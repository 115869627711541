import React, { FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Spin from 'antd/es/spin';

import { accountSelectors, accountActions, generalActions } from '@store';
import {
  PaymentStep,
  SubscriptionStatus,
  SubscriptionType,
} from '@repo/shared/enums';
import {
  ButtonRow,
  ChargifyForm,
  Checkbox,
  Container,
  FormsContainer,
  Note,
} from './styled';
import { useAppDispatch } from '@hooks';
import useChargify from './useChargify';
import { isObject, notification } from '@utils';
import { hasOwnProperty } from '@repo/shared/utils';
import { routes } from '@config';
import { config } from '@repo/shared/config';

import Form from '../../../../../shared/ant/Form';
import FormSection from './FormSection/FormSection';
import CompanyInfoFormFields from './CompanyInfoFormFields/CompanyInfoFormFields';
import CreditCardFormFields from './CreditCardFormFields/CreditCardFormFields';
import Button from '../../../../../shared/ant/Button';
import OrderSummary from './OrderSummary/OrderSummary';

interface IProps {
  isEdit: boolean;
}

const PaymentFormContent: React.FC<React.PropsWithChildren<IProps>> = ({
  isEdit,
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [companyInfoFormRef] = Form.useForm();
  const { chargifyFormRef, submitChargifyForm, chargifyLoading } =
    useChargify();
  const [processing, setProcessing] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const billingPeriod = useSelector(accountSelectors.getBillingPeriod);
  const { data: subscription } = useSelector(accountSelectors.getSubscription);
  const coupon = useSelector(accountSelectors.getCoupon);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setProcessing(true);

    try {
      const [, creditCardToken] = await Promise.all([
        companyInfoFormRef.validateFields(),
        submitChargifyForm(),
      ]);
      const companyInfo = companyInfoFormRef.getFieldsValue();

      const resultAction = await dispatch(
        accountActions.createSubscription({
          ...companyInfo,
          creditCardToken,
          billingPeriod,
          couponCode: coupon?.code || null,
        })
      );

      if (accountActions.createSubscription.fulfilled.match(resultAction)) {
        await dispatch(accountActions.getSubscription());

        dispatch(
          accountActions.updateCompany({
            local: true,
            update: {
              trialPeriodEndDate: null,
              subscriptionType: SubscriptionType.Automated,
              subscriptionStatus: SubscriptionStatus.Active,
            },
          })
        );

        dispatch(generalActions.getConciseData());

        dispatch(accountActions.setPaymentStep(PaymentStep.Result));
      } else {
        notification.error({
          message: formatMessage({ id: 'ErrorWhileCreatingSubscription' }),
          description: resultAction.payload,
        });
        setProcessing(false);
      }
    } catch (e) {
      if (
        isObject(e) &&
        hasOwnProperty(e, 'status') &&
        hasOwnProperty(e, 'errors') &&
        e.status !== 200
      ) {
        notification.error({
          message: formatMessage({ id: 'ErrorWhileCreatingSubscription' }),
          description: e.errors as string,
        });
      }

      setProcessing(false);
    }
  };

  return (
    <Container>
      <FormsContainer>
        <FormSection title={`1. ${formatMessage({ id: 'CompanyInfo' })}`}>
          <Form
            layout="vertical"
            form={companyInfoFormRef}
            initialValues={{
              ...(subscription?.automatedSubscriptionData?.customer || {}),
            }}
          >
            <CompanyInfoFormFields
              processing={processing}
              form={companyInfoFormRef}
            />
          </Form>
        </FormSection>
        <FormSection
          title={`2. ${formatMessage({ id: 'PaymentMethod' })}`}
          secure
        >
          <ChargifyForm onSubmit={handleSubmit} ref={chargifyFormRef}>
            <Spin spinning={chargifyLoading || processing}>
              <CreditCardFormFields />
            </Spin>
            <Note>
              <span>
                {formatMessage({
                  id: 'WeWillInvoiceAndManuallyRenewYourProfessionalPlan',
                })}{' '}
                {formatMessage({ id: 'ContactSalesAt' })}{' '}
                <a href={`mailto:${config.urls.salesEmail}`}>
                  {config.urls.salesEmail}
                </a>
                .
              </span>
            </Note>
            <Note>
              <span>
                {formatMessage({
                  id: 'BySubmittingThisFormYouConfirmThatYouAgreeToOur',
                })}{' '}
                <a
                  href={config.urls.termsOfUse}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatMessage({ id: 'TermsAndConditions' })}
                </a>
                .
              </span>
              <Checkbox
                className="agreement-checkbox"
                onChange={(e) => setAgreed(e.target.checked)}
              />
            </Note>
            <ButtonRow>
              <Button
                type="default"
                htmlType="button"
                onClick={() => navigate(routes.subscription)}
              >
                {formatMessage({ id: 'Back' })}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!agreed || chargifyLoading}
                loading={processing}
              >
                {isEdit
                  ? formatMessage({ id: 'Edit' })
                  : formatMessage({ id: 'Purchase' })}
              </Button>
            </ButtonRow>
          </ChargifyForm>
        </FormSection>
      </FormsContainer>
      <div>
        <OrderSummary edit={isEdit} />
      </div>
    </Container>
  );
};

export default PaymentFormContent;
