import React from 'react';

import { IssueEventComment } from '@domain/Issues/models/IssueEvent';
import { Text, PreviewsWrapper } from './styled';

import Previews from '@components/shared/Previews/Previews';
import IssueDetailsEventCommentText from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvent/IssueDetailsEventData/IssueDetailsEventCommentText/IssueDetailsEventCommentText';

interface IProps {
  event: IssueEventComment;
}

const IssueDetailsEventComment: React.FC<React.PropsWithChildren<IProps>> = ({
  event,
}) => {
  return (
    <>
      <Text>
        <IssueDetailsEventCommentText text={event.data.body} />
      </Text>
      {event.data.files.length > 0 && (
        <PreviewsWrapper>
          <Previews files={event.data.files} />
        </PreviewsWrapper>
      )}
    </>
  );
};

export default IssueDetailsEventComment;
