import { BaseApiClient } from '@repo/shared/api';
import { IActionsApiClient } from '@infrastructure/Actions/api/clients/IActionsApiClient';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { ActionsFilters } from '@application/Actions/models/ActionsFilters';
import { IPagedResponse } from '@repo/shared/types';
import { Action, ActionDetails } from '@domain/Actions/models/Action';
import { transformActionDetailsToEditActionDto } from '@infrastructure/Actions/api/transformers/transformActionDetailsToEditActionDto';
import { CreateActionDto } from '@infrastructure/Actions/models/CreateActionDto';
import { ExportType, ActionStatus } from '@repo/shared/enums';
import { removeUndefinedAndNullProps } from '@utils';
import { Comment } from '@domain/common/Comment';

class ActionsApiClient extends BaseApiClient implements IActionsApiClient {
  public async getActions(
    page: ActionsPage,
    filters: ActionsFilters
  ): Promise<IPagedResponse<Action>> {
    const pages = {
      [ActionsPage.Completed]: 'resolved',
      [ActionsPage.Pending]: 'unresolved',
      [ActionsPage.Expired]: 'expired',
    };

    return this._apiService.post<IPagedResponse<Action>>({
      url: `audit/actions/${pages[page]}`,
      body: filters,
    });
  }

  public async getActionDetails(actionId: string): Promise<ActionDetails> {
    return this._apiService.get({
      url: `audit/actions/${actionId}`,
    });
  }

  public async createAction(dto: CreateActionDto): Promise<string> {
    const { id } = await this._apiService.post<{ id: string }>({
      url: `audit/actions/create`,
      body: {
        ...dto,
      },
    });

    return id;
  }

  public async updateAction(actionDetails: Action): Promise<void> {
    await this._apiService.put({
      url: `audit/actions/${actionDetails.id}`,
      body: transformActionDetailsToEditActionDto(actionDetails),
    });
  }

  public async deleteActions(ids: string[]): Promise<void> {
    await this._apiService.delete({
      url: `audit/actions`,
      body: {
        ids,
      },
    });
  }

  public async changeActionsStatus(
    ids: string[],
    status:
      | ActionStatus.Rejected
      | ActionStatus.Approved
      | ActionStatus.Submitted
  ): Promise<void> {
    const paths = {
      [ActionStatus.Approved]: 'approve',
      [ActionStatus.Rejected]: 'reject',
      [ActionStatus.Submitted]: 'submit',
    };

    await this._apiService.post({
      url: `audit/actions/${paths[status]}`,
      body: {
        ids,
      },
    });
  }

  public async expireActions(ids: string[]): Promise<void> {
    await this._apiService.post({
      url: `audit/actions/expire`,
      body: {
        ids,
      },
    });
  }

  public async createActionComment(
    actionId: string,
    dto: Comment
  ): Promise<void> {
    await this._apiService.post({
      url: `audit/actions/${actionId}/comment`,
      body: {
        ...dto,
      },
    });
  }

  public async editActionComment(
    actionId: string,
    dto: Comment
  ): Promise<void> {
    await this._apiService.put({
      url: `audit/actions/${actionId}/comment`,
      body: {
        ...dto,
      },
    });
  }

  public async deleteActionComment(
    actionId: string,
    commentId: string
  ): Promise<void> {
    await this._apiService.delete({
      url: `audit/actions/${actionId}/comment/${commentId}`,
    });
  }

  public async exportActions(
    page: ActionsPage,
    exportType: ExportType,
    filters: ActionsFilters
  ): Promise<ArrayBuffer> {
    const pagePaths: Record<ActionsPage, string> = {
      [ActionsPage.Completed]: 'resolved',
      [ActionsPage.Pending]: 'unresolved',
      [ActionsPage.Expired]: 'expired',
    };

    const exportTypePaths: Record<ExportType, string> = {
      [ExportType.Csv]: 'export-csv',
      [ExportType.Pdf]: 'export-pdf',
      [ExportType.Xls]: 'export-xls',
    };

    const { data } = await this._apiService.post<{ data: ArrayBuffer }>({
      responseType: 'arraybuffer',
      url: `audit/actions/${pagePaths[page]}/${exportTypePaths[exportType]}`,
      body: removeUndefinedAndNullProps(filters),
      withHeaders: true,
    });

    return data;
  }

  public async getAllAuditActions(auditId: string) {
    const filters = {
      pageSize: 1000,
      auditId,
    };

    const [resolved, unresolved] = await Promise.all([
      this._apiService.get<IPagedResponse<Action>>({
        url: 'audit/actions/resolved',
        query: {
          ...filters,
        },
      }),
      this._apiService.get<IPagedResponse<Action>>({
        url: 'audit/actions/unresolved',
        query: {
          ...filters,
        },
      }),
    ]);

    return [...resolved.data, ...unresolved.data];
  }
}

export default ActionsApiClient;
