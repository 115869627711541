import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

import { AnyObject } from '../types';

const APPLICATION_ID = '1e21def8-b9d4-4f6b-aed9-216c1334c6ea';
const CLIENT_TOKEN = 'pub2b9782bf7a048df3dd52246f1b8d788a';
const SITE = 'datadoghq.com';

interface IUser {
  id?: string;
  email?: string;
  companyId?: string;
  companyName?: string;
}

export class Logger {
  private static createConfig(
    version: string,
    service: string
  ): RumInitConfiguration {
    const config: RumInitConfiguration = {
      version,
      service,
      applicationId: APPLICATION_ID,
      clientToken: CLIENT_TOKEN,
      site: SITE,
      env: import.meta.env.VITE_ENV === 'production' ? 'prod' : 'dev',
      sessionSampleRate: 100,
      defaultPrivacyLevel: 'mask-user-input',
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      beforeSend: (event) => {
        if (
          event.type === 'error' &&
          (event.error.message.includes('ResizeObserver loop limit exceeded') ||
            event.error.message.includes('Object Not Found Matching') ||
            event.error.message.toLowerCase().includes('illegal invocation'))
        ) {
          return false;
        }
      },
    };

    if (import.meta.env.VITE_API_BASE_URL) {
      config.allowedTracingUrls = [import.meta.env.VITE_API_BASE_URL];
    }

    return config;
  }

  static init(version: string, service: string) {
    const config = this.createConfig(version, service);
    datadogRum.init(config);
    datadogRum.startSessionReplayRecording();
  }

  static setUser({ id, email, companyId, companyName }: Partial<IUser>) {
    datadogRum.setUser({
      id,
      email,
      extraInfo: {
        companyId: companyId,
        companyName: companyName,
      },
    });
  }

  static removeUser() {
    datadogRum.removeUser();
  }

  static captureAction(name: string, context?: AnyObject) {
    if (import.meta.env.VITE_ENV === 'development') {
      console.info('captured action: ', name, context);
    }

    datadogRum.addAction(name, context);
  }

  static captureException(e: any, context?: AnyObject) {
    if (import.meta.env.VITE_ENV === 'development') {
      console.error(e);
    }

    datadogRum.addError(e, context);
  }
}
