import React, { useEffect } from 'react';
import { TableProps } from 'antd/es/table';
import { TablePaginationConfig, SorterResult } from 'antd/es/table/interface';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';

import {
  StyledTable,
  TableContainer,
  GroupsContainer,
  SelectRowCheckboxWrapper,
} from './styled';
import { generalActions, generalSelectors } from '@store';
import { IOrderByDirection, IPagingMeta } from '@repo/shared/types';
import { normalizeOrderByDirection } from '@utils';
import { useAppDispatch } from '@hooks';

import Scrollbar from '../../Scrollbar/Scrollbar';
import TableFooter from './TableFooter';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import { FilterValue } from 'antd/es/table/interface';

interface IProps<RecordType> extends Omit<TableProps<RecordType>, 'rowKey'> {
  meta: IPagingMeta | null;
  loading: boolean;
  error?: any; // TODO string | null;
  onPageChange?: (update: { pageNumber: number; pageSize?: number }) => void;
  onSort?: (orderBy: string, orderByDirection: IOrderByDirection) => void;
  onUnmount?: () => void;
  canSelectRow?: boolean;
  onSelectRow?: () => void;
  groups?: React.ReactNode;
  e2eDataAttr?: string;
}

function Table<RecordType>({
  loading,
  groups,
  e2eDataAttr,
  onSelectRow,
  canSelectRow = true,
  ...props
}: React.PropsWithChildren<IProps<RecordType>>) {
  const dispatch = useAppDispatch();
  const selectedTableRowsKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  const { meta, error, onSort, onPageChange, onUnmount } = props;

  useEffect(() => {
    return () => {
      dispatch(generalActions.selectTableRows([]));

      if (onUnmount) {
        onUnmount();
      }
    };
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  let tableClassName = props.className || '';

  if (props.onRow !== undefined) {
    tableClassName += ' clickable-row';
  }

  return (
    <>
      <TableContainer data-e2e={e2eDataAttr}>
        {groups && <GroupsContainer>{groups}</GroupsContainer>}
        <StyledTable
          {...props}
          dataSource={loading ? [] : props.dataSource}
          locale={{
            ...(props.locale || {}),
            emptyText: loading ? (
              <Skeleton active style={{ width: '500px', marginTop: '20px' }} />
            ) : (
              <>
                {error ? (
                  <EmptyTable text={error} />
                ) : (
                  props.locale?.emptyText || null
                )}
              </>
            ),
          }}
          className={tableClassName}
          rowSelection={
            canSelectRow
              ? {
                  type: 'checkbox',
                  selectedRowKeys: selectedTableRowsKeys,
                  onChange: (keys: string[]) => {
                    dispatch(generalActions.selectTableRows(keys));

                    if (onSelectRow !== undefined) {
                      onSelectRow();
                    }
                  },
                  getCheckboxProps: (record: any) => ({
                    disabled: record.disableRowSelection,
                    name: record.name,
                  }),
                  renderCell: (
                    _: boolean,
                    __: RecordType,
                    ___: number,
                    originNode: React.ReactNode
                  ) => {
                    return (
                      <SelectRowCheckboxWrapper
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {originNode}
                      </SelectRowCheckboxWrapper>
                    );
                  },
                }
              : undefined
          }
          components={
            props.scroll?.y
              ? {
                  table(tableProps: any) {
                    const { className } = tableProps;

                    return (
                      <Scrollbar height={`${props.scroll?.y}px`}>
                        <table className={className}>
                          {tableProps.children}
                        </table>
                      </Scrollbar>
                    );
                  },
                }
              : undefined
          }
          showSorterTooltip={false}
          pagination={false}
          onChange={(
            _: TablePaginationConfig,
            __: Record<string, FilterValue | null>,
            sorter: SorterResult<RecordType> | SorterResult<RecordType>[]
          ) => {
            const singleSorter = sorter as SorterResult<any>;

            if (onSort && singleSorter.field) {
              onSort(
                singleSorter.field as string,
                normalizeOrderByDirection(singleSorter.order)
              );
            }
          }}
        />
      </TableContainer>
      {meta && onPageChange && (
        <TableFooter meta={meta} onPageChange={onPageChange} />
      )}
    </>
  );
}

export default Table;
