import React, { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useIntl } from 'react-intl';
import { FormInstance } from 'antd/es/form';
import AntSelect from 'antd/es/select';

import { IssueQuestionType } from '@domain/Issues/models/IssueQuestion';
import { e2eTestElements } from '@config';
import { limitLength } from '@utils';
import { IssueTypeDetailsFormValues } from '@application/IssueTypes/models/IssueTypeDetailsFormValues';
import {
  FieldsRow,
  AddButton,
  ErrorListWrapper,
} from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetailsFormFieldContainer/styled';

import Form from '@components/shared/ant/Form';
import Select from '@components/shared/ant/Select/Select';
import { Input } from '@components/shared/ant/Input';
import LabeledSwitch from '@components/shared/LabeledSwitch/LabeledSwitch';
import IssueTypeDetailsFormFieldContainer from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetailsFormFieldContainer/IssueTypeDetailsFormFieldContainer';

const { Option } = AntSelect;

interface IProps {
  saving: boolean;
  form: FormInstance;
}

const switchTitleStyle: React.CSSProperties = {
  fontSize: '13px',
  lineHeight: '18px',
  whiteSpace: 'nowrap',
};

const IssueTypeDetailsQuestionsFormFields: React.FC<
  React.PropsWithChildren<IProps>
> = ({ saving, form }) => {
  const { formatMessage } = useIntl();

  const getNewField = useCallback(
    () => ({
      id: uuid(),
      name: formatMessage({ id: 'NewTextField' }),
      type: IssueQuestionType.TextField,
      required: false,
      allowAttachments: false,
      requireAttachments: false,
    }),
    []
  );

  return (
    <Form.List
      name="fields"
      rules={[
        {
          validator(_, value) {
            if (!Array.isArray(value) || value.length === 0) {
              return Promise.reject(
                new Error(
                  formatMessage({
                    id: 'PleaseAddAtLeastOneFieldToProceed',
                  })
                )
              );
            }

            return Promise.resolve();
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => {
            return (
              <IssueTypeDetailsFormFieldContainer
                key={field.key}
                onAdd={() => {
                  add(getNewField(), index + 1);
                }}
                onDelete={() => {
                  remove(field.name);
                }}
              >
                <Form.Item
                  name={[field.name, 'name']}
                  label={formatMessage({ id: 'Name' })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: 'RequiredField' }),
                    },
                    limitLength(800),
                  ]}
                >
                  <Input
                    disabled={saving}
                    placeholder={formatMessage({ id: 'EnterName' })}
                    data-e2e={
                      e2eTestElements.issues.issueTypeFields.addEditModal
                        .nameInput
                    }
                  />
                </Form.Item>
                <FieldsRow>
                  <Form.Item
                    name={[field.name, 'type']}
                    layout="horizontal"
                    label={formatMessage({ id: 'Type' })}
                    shouldUpdate
                    rules={[
                      {
                        required: true,
                        message: formatMessage({ id: 'RequiredField' }),
                      },
                    ]}
                  >
                    <Select
                      e2eDataAttr={
                        e2eTestElements.issues.issueTypeFields.addEditModal
                          .typeSelect
                      }
                      disabled={saving}
                      style={{ minWidth: '120px' }}
                    >
                      <Option value={IssueQuestionType.TextField}>
                        {formatMessage({ id: 'TextField' })}
                      </Option>
                      <Option value={IssueQuestionType.TextArea}>
                        {formatMessage({ id: 'TextArea' })}
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name={[field.name, 'required']}>
                    <LabeledSwitch
                      title={formatMessage({
                        id: 'Required',
                      })}
                      disabled={saving}
                      titleStyle={switchTitleStyle}
                      containerStyle={{ justifyContent: 'flex-start' }}
                      e2eDataAttr={
                        e2eTestElements.issues.issueTypeFields.addEditModal
                          .requiredSwitch
                      }
                    />
                  </Form.Item>
                  <Form.Item<IssueTypeDetailsFormValues>
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.fields[index]?.requireAttachments !==
                      currentValues.fields[index]?.requireAttachments
                    }
                  >
                    {({ getFieldValue }) => (
                      <Form.Item name={[field.name, 'allowAttachments']}>
                        <LabeledSwitch
                          disabled={
                            saving ||
                            getFieldValue([
                              'fields',
                              index,
                              'requireAttachments',
                            ])
                          }
                          title={formatMessage({
                            id: 'AllowAttachments',
                          })}
                          titleStyle={switchTitleStyle}
                          containerStyle={{ justifyContent: 'flex-start' }}
                          e2eDataAttr={
                            e2eTestElements.issues.issueTypeFields.addEditModal
                              .allowAttachmentsSwitch
                          }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item name={[field.name, 'requireAttachments']}>
                    <LabeledSwitch
                      title={formatMessage({
                        id: 'RequireAttachments',
                      })}
                      disabled={saving}
                      e2eDataAttr={
                        e2eTestElements.issues.issueTypeFields.addEditModal
                          .requiredPhotoSwitch
                      }
                      titleStyle={switchTitleStyle}
                      containerStyle={{ justifyContent: 'flex-start' }}
                      onChange={(checked) => {
                        if (checked) {
                          form.setFieldValue(
                            ['fields', field.name, 'allowAttachments'],
                            true
                          );
                        }
                      }}
                    />
                  </Form.Item>
                </FieldsRow>
              </IssueTypeDetailsFormFieldContainer>
            );
          })}
          {fields.length === 0 && (
            <AddButton
              type="button"
              onClick={() => {
                add(getNewField(), fields.length);
              }}
            >
              + {formatMessage({ id: 'AddIssueTypeQuestion' })}
            </AddButton>
          )}
          {errors.length > 0 && (
            <ErrorListWrapper>
              <Form.ErrorList errors={errors} />
            </ErrorListWrapper>
          )}
        </>
      )}
    </Form.List>
  );
};

export default IssueTypeDetailsQuestionsFormFields;
