import React from 'react';
import { XCircle } from 'react-feather';

import { Container, Name, RemoveBtn } from './styled';
import { FileMetadata, IApiService } from '@repo/shared/types';
import { saveFile } from '@repo/shared/utils';
import { Logger } from '@repo/shared/services';
import { InternalApiService } from '@repo/shared/api';

interface IProps {
  file: FileMetadata;
  onRemove?: (id: string) => void;
  skipCompanyId?: boolean;
  apiService?: IApiService;
}

const FilePreview: React.FC<IProps> = ({
  file,
  onRemove,
  skipCompanyId,
  apiService = InternalApiService.getInstance(),
}) => {
  return (
    <Container>
      <Name
        type="button"
        onClick={async () => {
          try {
            const data = await apiService.getFile<ArrayBuffer>({
              id: file.id,
              responseType: 'arraybuffer',
              skipCompanyId,
            });

            saveFile({
              data,
              fileName: file.name,
              mimeType: file.contentType,
            });
          } catch (e) {
            Logger.captureException(e);
          }
        }}
      >
        {file.name}
      </Name>
      {onRemove && (
        <RemoveBtn
          type="button"
          onClick={() => {
            onRemove(file.id);
          }}
        >
          <XCircle />
        </RemoveBtn>
      )}
    </Container>
  );
};

export default FilePreview;
