import React from 'react';
import { useIntl } from 'react-intl';

import { Button, ButtonsRow } from './styled';

interface IProps {
  onChange?: (value: any) => void;
  value?: boolean;
}

const GroupingStatusButtons: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef(({ onChange, value }, _) => {
    const { formatMessage } = useIntl();

    function triggerChange(change: boolean) {
      if (onChange) {
        onChange(change);
      }
    }

    return (
      <ButtonsRow>
        <Button
          type="default"
          selected={!value}
          onClick={() => triggerChange(false)}
        >
          {formatMessage({ id: 'All' })}
        </Button>
        <Button
          type="default"
          selected={!!value}
          onClick={() => triggerChange(true)}
        >
          {formatMessage({ id: 'Ungrouped' })}
        </Button>
      </ButtonsRow>
    );
  });

export default GroupingStatusButtons;
