import React from 'react';
import { MoreHorizontal } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { HeaderActionButton } from './styled';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { useAppDispatch, usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';

import DropDownMenu from '@components/shared/DropDownMenu/DropDownMenu';

const ActionMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const issue = useSelector(issuesSelectors.getDetailsIssue);

  const [canDeleteIssues] = usePermission([Permission.CanDeleteIssues]);

  const menuItems = [];

  menuItems.push({
    name: formatMessage({
      id: 'Delete',
    }),
    onClick: () => {
      if (!issue) {
        return;
      }

      dispatch(
        issuesActions.toggleConfirmDeleteIssuesModal({
          multiple: false,
          data: issue,
        })
      );
    },
  });

  if (!canDeleteIssues) {
    return null;
  }

  return (
    <DropDownMenu menuItems={menuItems}>
      <HeaderActionButton type="button">
        <MoreHorizontal />
      </HeaderActionButton>
    </DropDownMenu>
  );
};

export default ActionMenu;
