import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useAppDispatch, usePermission } from '@hooks';
import { accountSelectors, usersActions } from '@store';
import { Permission } from '@repo/shared/enums';
import { e2eTestElements } from '@config';

import UserGroupContentWrapper, {
  UserGroupPage,
} from '@components/users/groups/single/UserGroupContentWrapper/UserGroupContentWrapper';
import AddToGroupModal from '@components/users/groups/single/UserGroupAssignments/AddToGroupModal/AddToGroupModal';
import ConfirmDeleteGroupAssignmentModal from '@components/users/groups/single/UserGroupAssignments/ConfirmDeleteGroupAssignmentModal';
import GroupsAssignmentsActionsModal from '@components/users/groups/single/UserGroupAssignments/GroupsAssignmentsActionsModal';
import UserGroupsAssignmentsTable from '@components/users/groups/single/UserGroupAssignments/UserGroupsAssignmentsTable/UserGroupsAssignmentsTable';
import SearchInput from '@components/shared/SeachInput/SearchInput';
import Button from '@components/shared/ant/Button';
import { useSelector } from 'react-redux';

const UserGroupAssignments: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { id } = useParams();

  const canManageUsers = usePermission(Permission.CanManageUsers);
  const { isUsersGroupsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  useEffect(() => {
    if (id) {
      dispatch(
        usersActions.groupAssignments.getData({
          userGroupIds: [id],
        })
      );
    }

    return () => {
      dispatch(usersActions.groupAssignments.toggleAddToGroupModal(false));
    };
  }, []);

  return (
    <UserGroupContentWrapper
      page={UserGroupPage.Assignments}
      extra={
        <>
          <SearchInput
            onEnter={(search: string) =>
              dispatch(
                usersActions.groupAssignments.getData({
                  search,
                  pageNumber: 1,
                })
              )
            }
          />
          {!isUsersGroupsReadonly && canManageUsers && (
            <Button
              type="primary"
              onClick={() => {
                dispatch(
                  usersActions.groupAssignments.toggleAddToGroupModal(true)
                );
              }}
              data-e2e={
                e2eTestElements.users.groupsAssignments.openAddToGroupModal
              }
            >
              {formatMessage({
                id: 'AddToGroup',
              })}
            </Button>
          )}
        </>
      }
    >
      <UserGroupsAssignmentsTable userGroupId={id} />
      <AddToGroupModal userGroupId={id} />
      <ConfirmDeleteGroupAssignmentModal />
      <GroupsAssignmentsActionsModal
        removeLabel={formatMessage({ id: 'Delete' })}
      />
    </UserGroupContentWrapper>
  );
};

export default UserGroupAssignments;
