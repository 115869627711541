import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { getTablePageNumberAfterDeletion, notification } from '@utils';
import { generalActions, generalSelectors } from '@store';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { getRoutesFromAuditPage } from '@application/Audits/utils/getRoutesFromAuditPage';

import Modal from '@components/shared/ant/Modal/Modal';

interface IProps {
  page: AuditsPage;
}

const DeleteSchedulePlansModal: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);

  const { meta } = useSelector(auditsSelectors.getAudits);
  const modalState = useSelector(auditsSelectors.getConfirmDeleteModalState);
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const viewAuditModal = useSelector(auditsSelectors.getViewAuditModalState);

  let deleteCandidateIds: string[] = [];
  if (modalState !== null) {
    deleteCandidateIds = modalState.multiple
      ? modalState.data
      : [modalState.data.id];
  }
  const itemCount = deleteCandidateIds.length;

  const closeModal = useCallback(() => {
    dispatch(auditsActions.toggleConfirmDeleteModal(null));
  }, []);

  return (
    <Modal
      title={formatMessage({ id: 'DeleteAudits' }, { itemCount })}
      open={modalState !== null}
      onOk={async () => {
        setProcessing(true);

        const resultAction = await dispatch(
          auditsActions.deleteAudits(deleteCandidateIds)
        );

        if (auditsActions.deleteAudits.fulfilled.match(resultAction)) {
          if (page === AuditsPage.My) {
            dispatch(auditsActions.getAudits(null));
            navigate(getRoutesFromAuditPage(page).list);
          } else {
            dispatch(
              auditsActions.getAudits({
                pageNumber: getTablePageNumberAfterDeletion(meta),
              })
            );
          }

          closeModal();

          if (selectedTableRowKeys.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          if (viewAuditModal.opened) {
            dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
          }
        } else {
          notification.error({
            message: formatMessage(
              { id: 'ErrorWhileDeletingAudits' },
              { itemCount }
            ),
          });
        }

        setProcessing(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={processing}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {formatMessage(
        { id: 'YouAreAboutToRemoveAuditsFromSystem' },
        { itemCount: deleteCandidateIds.length }
      ).replace(
        '%replaceValue%',
        modalState && !modalState.multiple
          ? modalState.data.name
          : deleteCandidateIds.length.toString()
      )}
    </Modal>
  );
};

export default DeleteSchedulePlansModal;
