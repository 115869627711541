import React from 'react';
import { useIntl } from 'react-intl';
import AntSelect from 'antd/es/select';

import { limitLength } from '@utils';
import { e2eTestElements } from '@config';
import { DefaultDueDateRow, MandatoryLabel } from './styled';
import { DefaultIssueTypeDueDatePeriod } from '@domain/IssueTypes/models/IssueType';
import validations from '@utils/validations';

import { Input } from '@components/shared/ant/Input';
import Form from '@components/shared/ant/Form';
import JobTitlesSelect from '@components/shared/EntitySelects/JobTitlesSelect';
import PrioritySelect from '@components/shared/PrioritySelect';
import Select from '@components/shared/ant/Select/Select';

interface IProps {
  saving: boolean;
}

const { Option } = AntSelect;

const defaultDueDateLangIds = {
  [DefaultIssueTypeDueDatePeriod.Day]: 'Days',
  [DefaultIssueTypeDueDatePeriod.Week]: 'Weeks',
  [DefaultIssueTypeDueDatePeriod.Month]: 'Months',
};

const IssueTypeDetailsGeneralFormFields: React.FC<
  React.PropsWithChildren<IProps>
> = ({ saving }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Form.Item
        name="name"
        label={formatMessage({ id: 'Name' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'RequiredField' }),
          },
          limitLength(800),
        ]}
      >
        <Input
          disabled={saving}
          placeholder={formatMessage({ id: 'EnterName' })}
          data-e2e={e2eTestElements.issues.issueTypes.addEditModal.nameInput}
        />
      </Form.Item>
      <Form.Item
        name="autoAssignJobTitles"
        label={formatMessage({ id: 'AutoAssign' })}
      >
        <JobTitlesSelect
          disabled={saving}
          placeholder={formatMessage({ id: 'SelectJobTitles' })}
          mode="multiple"
          labelInValue
          e2eDataAttr={
            e2eTestElements.issues.issueTypes.addEditModal.autoAssignSelect
          }
        />
      </Form.Item>
      <Form.Item
        label={
          <MandatoryLabel>
            {formatMessage({ id: 'DefaultDueDate' })}
          </MandatoryLabel>
        }
      >
        <DefaultDueDateRow>
          <Form.Item
            name="defaultDueDatePeriodValue"
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
              {
                pattern: validations.naturalPositive,
                message: formatMessage({ id: 'FieldMustBePositiveInteger' }),
              },
            ]}
          >
            <Input
              disabled={saving}
              placeholder={'Enter a number'}
              data-e2e={
                e2eTestElements.issues.issueTypes.addEditModal.nameInput
              }
            />
          </Form.Item>
          <Form.Item name="defaultDueDatePeriod" style={{ marginBottom: 0 }}>
            <Select disabled={saving}>
              <Option value={DefaultIssueTypeDueDatePeriod.Day}>
                {formatMessage({
                  id: defaultDueDateLangIds[DefaultIssueTypeDueDatePeriod.Day],
                })}
              </Option>
              <Option value={DefaultIssueTypeDueDatePeriod.Week}>
                {formatMessage({
                  id: defaultDueDateLangIds[DefaultIssueTypeDueDatePeriod.Week],
                })}
              </Option>
              <Option value={DefaultIssueTypeDueDatePeriod.Month}>
                {formatMessage({
                  id: defaultDueDateLangIds[
                    DefaultIssueTypeDueDatePeriod.Month
                  ],
                })}
              </Option>
            </Select>
          </Form.Item>
        </DefaultDueDateRow>
      </Form.Item>
      <Form.Item
        name="defaultPriority"
        label={formatMessage({ id: 'DefaultPriority' })}
      >
        <PrioritySelect />
      </Form.Item>
      <Form.Item
        name="description"
        label={formatMessage({ id: 'Description' })}
        rules={[limitLength(1600)]}
      >
        <Input.TextArea
          disabled={saving}
          rows={3}
          placeholder={formatMessage({ id: 'Description' })}
          data-e2e={e2eTestElements.issues.issueTypes.addEditModal.description}
        />
      </Form.Item>
    </>
  );
};

export default IssueTypeDetailsGeneralFormFields;
