import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { getColumnSortProperties, getInitialFilters } from '@utils';
import { IConcise, IUser } from '@repo/shared/types';
import {
  generalActions,
  rolesActions,
  usersActions,
  usersSelectors,
} from '@store';
import { Modal, Permission, SelfGuidedTourStep } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { e2eTestElements, routes } from '@config';

import Table from '../../../shared/ant/Table/Table';
import EmptyTable from '../../../shared/ant/EmptyTable/EmptyTable';
import UsersActionsMenu from './UsersActionsMenu';
import SampleBadgedName from '@components/shared/SampleBadgedName';
import TourPopover from '@components/account/SelfGuidedTour/TourPopover/TourPopover';
import ConciseListStr from '@components/shared/ConciseListStr/ConciseListStr';

const UsersTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const { loading, error, users, meta } = useSelector(
    usersSelectors.list.getData
  );
  const filters = useSelector(usersSelectors.list.getFilters);
  const canManageUsers = usePermission(Permission.CanManageUsers);

  useEffect(() => {
    dispatch(rolesActions.getRoles());
  }, []);

  useEffect(() => {
    dispatch(
      usersActions.list.getData({
        ...filters,
      })
    );
  }, [dispatch, location]);

  return (
    <TourPopover
      step={SelfGuidedTourStep.AddUser}
      placement="bottom"
      overlayStyle={{ marginTop: -55, height: 334 }}
      // disabledNextBtn={loading}
      scrollToSpotlight
    >
      <Table
        error={error}
        loading={loading}
        meta={meta}
        e2eDataAttr={e2eTestElements.users.table}
        canSelectRow={canManageUsers}
        onSelectRow={() => {
          dispatch(generalActions.hideModal(Modal.AddEdit));
        }}
        onRow={(user) => {
          return {
            onClick: () => {
              dispatch(usersActions.list.updateUserDetails(null));
              navigate(routes.users.userDetails.replace(':id', user.id));
            },
          };
        }}
        dataSource={
          users
            ? users.map((user) => ({
                ...user,
                key: user.id,
                roleName: user.role.name,
                userGroups: user.userGroups || [],
              }))
            : []
        }
        columns={[
          {
            title: formatMessage({ id: 'FullName' }),
            dataIndex: 'name',
            key: 'name',
            ...getColumnSortProperties(
              filters.orderByDirection,
              filters.orderBy === 'name'
            ),
            render: (_, { name, isSample }) => (
              <SampleBadgedName text={name} isSample={isSample} />
            ),
            className: 'cell-text-link',
          },
          {
            title: formatMessage({ id: 'UserGroups' }),
            dataIndex: 'userGroups',
            key: 'userGroups',
            ...getColumnSortProperties(
              filters.orderByDirection,
              filters.orderBy === 'userGroups'
            ),
            render(userGroups: IConcise[]) {
              return (
                <ConciseListStr
                  items={userGroups}
                  emptyPlaceholder={formatMessage({ id: 'Ungrouped' })}
                />
              );
            },
          },
          {
            title: formatMessage({ id: 'Role' }),
            dataIndex: 'roleName',
            key: 'roleName',
            ...getColumnSortProperties(
              filters.orderByDirection,
              filters.orderBy === 'roleName'
            ),
          },
          ...(canManageUsers
            ? [
                {
                  title: formatMessage({ id: 'Action' }),
                  key: 'action',
                  align: 'center' as 'center',
                  width: 70,
                  render: (user: IUser) => <UsersActionsMenu user={user} />,
                },
              ]
            : []),
        ]}
        locale={{
          emptyText: (
            <EmptyTable
              text={
                <>
                  {formatMessage(
                    {
                      id:
                        filters.search !== ''
                          ? 'NoUsersSearchResults'
                          : 'NoUsers',
                    },
                    { searchStr: filters.search }
                  )}
                  {canManageUsers && (
                    <>
                      <br />
                      {formatMessage({ id: 'YouCanAddUserRightNow' })}
                    </>
                  )}
                </>
              }
              button={
                canManageUsers
                  ? {
                      text: `+ ${formatMessage({ id: 'AddNewUser' })}`,
                      type: 'link',
                      onClick: () =>
                        dispatch(
                          generalActions.showModal({ name: Modal.AddEdit })
                        ),
                    }
                  : null
              }
            />
          ),
        }}
        onPageChange={(update) => dispatch(usersActions.list.getData(update))}
        onSort={(orderBy, orderByDirection) =>
          dispatch(usersActions.list.getData({ orderBy, orderByDirection }))
        }
      />
    </TourPopover>
  );
};

export default UsersTable;
