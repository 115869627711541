import { createReducer, Reducer } from '@reduxjs/toolkit';

import { IAccountState } from '@types';
import { accountActions as actions } from './account.actions';
import {
  AuthProvider,
  BillingPeriod,
  LiveChatVisibility,
  Locale,
  PaymentStep,
  SelfGuidedTourStep,
} from '@repo/shared/enums';
import { config, colors, tagColors } from '@repo/shared/config';
import { accountEntityAdapter } from '@store/entityAdapters';
import { setBrowserTabId } from '@utils';

const accountIconColors = [
  colors.blue2,
  colors.orange,
  colors.green,
  colors.yellow,
  colors.red,
  tagColors.magenta,
];

export const accountReducer: Reducer<IAccountState> =
  createReducer<IAccountState>(
    {
      user: null,
      company: null,
      locale: Locale.en,
      showFullScreenLoader: false,
      authUserId: null,
      currentCompanyId: null,
      browserTabId: null,
      authFormLoading: false,
      authProvider: null,
      accounts: {
        loading: false,
        error: null,
        data: accountEntityAdapter.getInitialState(),
      },
      timeZones: {
        loading: false,
        error: null,
        data: [],
      },
      showSetupCompanyModal: false,
      showNewVersionReleasedModal: false,
      payment: {
        step: PaymentStep.FillUp,
        billingPeriod: BillingPeriod.Monthly,
      },
      prices: {
        loading: false,
        data: null,
        error: null,
      },
      subscription: {
        loading: false,
        data: null,
        error: null,
      },
      invoices: {
        loading: false,
        data: null,
        error: null,
      },
      confirmDeleteAccountModal: {
        show: false,
        company: null,
      },
      showWelcomeModal: false,
      showDownloadAppsModal: false,
      sampleDataModal: { visible: false },
      selfGuidedTour: {
        step: null,
        loading: false,
        spotlightBounds: null,
      },
      liveChatVisibility: LiveChatVisibility.minimized,
      showFormsDigitizationModal: false,
      appConfiguration: {
        appLogoUrl: null,
        appFaviconUrl: null,
        termsOfServiceUrl: config.urls.termsOfUse,
        privacyAndTermsUrl: config.urls.privacyPolicy,
        contactUsUrl: config.urls.contact,
        demoUrl: config.urls.interactiveDemo,
        appName: null,
        allowSignUp: true,
        showGettingStarted: true,
        showHelpCenter: true,
        showLiveChat: true,
        defaultLocale: Locale.en,
        allowFeedbackModal: true,
      },
    },
    (builder) =>
      builder
        .addCase(
          actions.getAppData.fulfilled,
          (state, { payload: { locale, company, user, authUserId } }) => {
            state.user = {
              ...user,
            };
            state.company = company;
            state.locale = locale;
            state.authUserId = authUserId;
          }
        )
        .addCase(actions.setAuthFormLoading, (state, { payload }) => {
          state.authFormLoading = payload;
        })
        .addCase(actions.getAccounts.pending, (state) => {
          state.accounts.loading = true;
          state.accounts.error = null;
        })
        .addCase(
          actions.getAccounts.fulfilled,
          (state, { payload: { accounts } }) => {
            state.accounts.loading = false;
            accountEntityAdapter.setAll(
              state.accounts.data,
              accounts.map((account, i) => ({
                ...account,
                color:
                  accountIconColors[
                    i >= accountIconColors.length
                      ? i % accountIconColors.length
                      : i
                  ],
              }))
            );
          }
        )
        .addCase(
          actions.updateAccount.fulfilled,
          (state, { payload: { accountId, update } }) => {
            accountEntityAdapter.updateOne(state.accounts.data, {
              id: accountId,
              changes: update,
            });
          }
        )
        .addCase(actions.getAccounts.rejected, (state, { payload }) => {
          state.accounts.loading = false;
          state.accounts.error = payload || null;
        })
        .addCase(actions.toggleFullScreenLoader, (state, { payload }) => {
          state.showFullScreenLoader = payload;
        })
        .addCase(actions.signIn.fulfilled, (state) => {
          state.authProvider = AuthProvider.Email;
        })
        .addCase(actions.signInWithGoogle.fulfilled, (state) => {
          state.authProvider = AuthProvider.Google;
        })
        .addCase(actions.setCurrentCompanyId, (state, { payload }) => {
          state.currentCompanyId = payload;
        })
        .addCase(actions.signInWithCustomToken.fulfilled, () => {})
        .addCase(actions.signUp.fulfilled, (state) => {
          state.authProvider = AuthProvider.Email;
        })
        .addCase(actions.updateCurrentUserLocally, (state, { payload }) => {
          if (state.user) {
            state.user = {
              ...state.user,
              ...payload,
            };
          }
        })
        .addCase(actions.updateCurrentUser.fulfilled, (state, { payload }) => {
          if (state.user) {
            state.user = {
              ...state.user,
              ...payload,
            };
          }
        })
        .addCase(actions.updateCompany.fulfilled, (state, { payload }) => {
          if (state.company) {
            state.company = {
              ...state.company,
              ...payload,
            };
          }

          if (payload.name && state.company?.id) {
            const account = Object.values(state.accounts.data.entities).find(
              (account) => account?.company.id === state.company?.id
            );

            if (account) {
              accountEntityAdapter.updateOne(state.accounts.data, {
                id: account.id,
                changes: {
                  company: {
                    ...account.company,
                    name: payload.name,
                  },
                },
              });
            }
          }
        })
        .addCase(actions.changeLocale.fulfilled, (state, { payload }) => {
          state.locale = payload;
        })
        .addCase(actions.setPaymentStep, (state, { payload }) => {
          state.payment.step = payload;
        })
        .addCase(actions.setBillingPeriod, (state, { payload }) => {
          state.payment.billingPeriod = payload;
        })
        .addCase(actions.getPrices.pending, (state) => {
          state.prices.error = null;
          state.prices.loading = true;
        })
        .addCase(actions.getPrices.fulfilled, (state, { payload }) => {
          state.prices.loading = false;
          state.prices.data = payload;
        })
        .addCase(actions.getPrices.rejected, (state) => {
          state.prices.loading = false;
          state.prices.error = 'Unknown error';
        })
        .addCase(actions.getSubscription.pending, (state) => {
          state.prices.error = null;
          state.subscription.loading = true;
        })
        .addCase(actions.getSubscription.fulfilled, (state, { payload }) => {
          state.subscription.loading = false;
          state.subscription.data = payload;
        })
        .addCase(actions.getSubscription.rejected, (state) => {
          state.subscription.loading = false;
          state.subscription.error = 'Unknown error';
        })
        .addCase(actions.validateCouponCode.fulfilled, (state, { payload }) => {
          if (state.subscription.data?.automatedSubscriptionData) {
            state.subscription.data.automatedSubscriptionData.coupon = payload;
          } else if (state.subscription.data) {
            state.subscription.data.coupon = payload;
          }
        })
        .addCase(actions.removeCouponLocally, (state) => {
          if (state.subscription.data?.automatedSubscriptionData?.coupon) {
            state.subscription.data.automatedSubscriptionData.coupon = null;
          } else if (state.subscription.data?.coupon) {
            state.subscription.data.coupon = null;
          }
        })
        .addCase(actions.getInvoices.pending, (state) => {
          state.invoices.loading = true;
          state.invoices.error = null;
        })
        .addCase(
          actions.getInvoices.fulfilled,
          (state, { payload: { data } }) => {
            state.invoices.loading = false;
            state.invoices.data = data;
          }
        )
        .addCase(actions.getInvoices.rejected, (state, { payload }) => {
          state.invoices.loading = false;
          state.invoices.error = payload || null;
        })
        .addCase(
          actions.toggleConfirmDeleteAccountModal,
          (state, { payload: { company, show } }) => {
            state.confirmDeleteAccountModal = {
              show,
              company: company || null,
            };
          }
        )
        .addCase(actions.toggleSampleDataModal, (state, { payload }) => {
          state.sampleDataModal = payload;
        })
        .addCase(actions.toggleWelcomeModal, (state, { payload }) => {
          state.showWelcomeModal = payload;
        })
        .addCase(actions.toggleDownloadAppsModal, (state, { payload }) => {
          state.showDownloadAppsModal = payload;
        })
        .addCase(actions.selfGuidedTour.start.fulfilled, (state) => {
          state.selfGuidedTour.step = SelfGuidedTourStep.StartTour;
        })
        .addCase(actions.selfGuidedTour.nextStep.pending, (state) => {
          state.selfGuidedTour.loading = true;
          state.selfGuidedTour.spotlightBounds = null;
        })
        .addCase(actions.selfGuidedTour.nextStep.fulfilled, (state) => {
          state.selfGuidedTour = {
            ...state.selfGuidedTour,
            loading: false,
          };
        })
        .addCase(actions.selfGuidedTour.nextStep.rejected, (state) => {
          state.selfGuidedTour.step = null;
          state.selfGuidedTour.spotlightBounds = null;
          state.selfGuidedTour.loading = false;
        })
        .addCase(
          actions.selfGuidedTour.setSelfGuidedTourStep.fulfilled,
          (state, { payload }) => {
            state.selfGuidedTour.step = payload;
          }
        )
        .addCase(actions.selfGuidedTour.stop.fulfilled, (state) => {
          state.selfGuidedTour.step = null;
          state.selfGuidedTour.spotlightBounds = null;
          state.selfGuidedTour.loading = false;
        })
        .addCase(actions.toggleSetupCompanyModal, (state, { payload }) => {
          state.showSetupCompanyModal = payload;
        })
        .addCase(actions.setDefaultTemplateId, (state, { payload }) => {
          if (state.company) {
            state.company.defaultTemplateId = payload;
          }
        })
        .addCase(
          actions.selfGuidedTour.setSpotlightBounds,
          (state, { payload }) => {
            state.selfGuidedTour.spotlightBounds = payload;
          }
        )
        .addCase(actions.setLiveChatVisibility, (state, { payload }) => {
          state.liveChatVisibility = payload;
        })
        .addCase(actions.toggleFormsDigitizationModal, (state, { payload }) => {
          state.showFormsDigitizationModal = payload;
        })
        .addCase(
          actions.getAppConfiguration.fulfilled,
          (state, { payload }) => {
            if (payload) {
              state.appConfiguration = {
                ...payload,
                appFaviconUrl: payload.appFaviconUrl || '/mqa-favicon.ico',
              };
              state.locale = payload.defaultLocale;
            }
          }
        )
        .addCase(actions.setLocale, (state, { payload }) => {
          state.locale = payload;
        })
        .addCase(actions.setCompany, (state, { payload }) => {
          state.company = payload;
        })
        .addCase(actions.setBrowserTabId, (state, { payload }) => {
          if (state.browserTabId === null) {
            state.browserTabId = payload || setBrowserTabId();
          }
        })
        .addCase(
          actions.toggleNewVersionReleasedModal,
          (state, { payload }) => {
            state.showNewVersionReleasedModal = payload;
          }
        )
  );
