import { useEffect, useState } from 'react';
import { backOff } from 'exponential-backoff';

import { FileStatus } from '@repo/shared/enums';
import { Logger } from '@repo/shared/services';
import { IApiService } from '@repo/shared/types';

export function useFileStatus({
  fileId,
  getMetadataPath = (fileId) => `files/${fileId}/metadata`,
  skipCompanyId,
  apiService,
  skipStatusCheck,
}: {
  fileId: string;
  getMetadataPath?: (fileId: string) => string;
  skipCompanyId?: boolean;
  apiService: IApiService;
  skipStatusCheck?: boolean;
}) {
  const [status, setStatus] = useState<FileStatus | null>(
    skipStatusCheck ? FileStatus.Processed : null
  );

  useEffect(() => {
    let isMounted = true;

    async function getFileStatus(fileId: string) {
      try {
        let isFirstRequest = true;

        const status = await backOff<FileStatus>(
          async () => {
            const { status: newStatus } = await apiService.get<{
              status: FileStatus;
            }>({
              host: apiService.getFileApiHost(),
              url: getMetadataPath(fileId),
              skipCompanyId,
            });

            if (isFirstRequest || newStatus === FileStatus.PreviewReady) {
              setStatus(newStatus);
              isFirstRequest = false;
            }

            if (
              newStatus === FileStatus.WaitingUpload ||
              newStatus === FileStatus.Processing ||
              newStatus === FileStatus.PreviewReady
            ) {
              throw new Error('file-not-processed');
            }

            return newStatus;
          },
          {
            numOfAttempts: 30,
            retry: (e: Error) => {
              return e.message === 'file-not-processed' && isMounted;
            },
          }
        );

        setStatus(status);
      } catch (e: unknown) {
        setStatus(FileStatus.Error);

        if (!(e instanceof Error && e.message === 'file-not-processed')) {
          Logger.captureException(e);
        }
      }
    }

    if (!skipStatusCheck) {
      getFileStatus(fileId);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return status;
}
